<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="other-services">
        <b-button
          id="btn-back"
          variant="link"
          class="po-left"
          v-on:click="closeTab()"
        >
          <b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-22 semi-bold mt-2">
            {{ $t('other_services_choose') }}
          </p>
        </div>
        <hr />

        <ul class="list-unstyled mt-4 mb-0">
          <!--                    <li-->
          <!--                            class="d-flex align-items-center mt-2 px-3 py-2 service"-->
          <!--                            v-on:click="toService(8)"-->
          <!--                    >-->
          <li
            :class="{ disabled: !eshopStatus }"
            class="d-flex align-items-center mt-2 px-3 py-2 service"
            v-on:click="$router.push({ name: 'shop' })"
          >
            <figure
              class="d-flex justify-content-center align-items-center mr-2 mb-0"
            >
              <b-img :src="path + shop" alt="e-shop"></b-img>
            </figure>
            <div class="mr-2">
              <p class="text-22 semi-bold text-white mb-0">
                {{ $t('shop_title') }}
              </p>
            </div>
            <b-img
              class="position-right"
              :src="path + angle_right"
              alt="enter"
            ></b-img>
          </li>
          <!--                    <li-->
          <!--                            :class="{ disabled: !$store.getters.doctor.services.recipe }"-->
          <!--                            class="d-flex align-items-center mt-2 px-3 py-2 service"-->
          <!--                            v-on:click="toService(3)"-->
          <!--                    >-->
          <li
            :class="{ disabled: ($store.getters.doctor.services.recipe.availability != undefined) ? !$store.getters.doctor.services.recipe.availability : !$store.getters.doctor.services.recipe }"
            class="d-flex align-items-center mt-2 px-3 py-2 service"
            v-on:click="$router.push({ name: 'recipe' })"
          >
            <figure
              class="d-flex justify-content-center align-items-center mr-2 mb-0"
            >
              <b-img :src="path + recipe" alt="e-recept"></b-img>
            </figure>
            <div class="mr-2">
              <p class="text-22 semi-bold text-white mb-0">
                {{ $t('recipe_title') }}
              </p>
              <p class="text-price-light mb-0">
                {{
                  $t('service_price', [$store.getters.recipe.infoserviceprice])
                }}
              </p>
            </div>
            <b-img
              class="position-right"
              :src="path + angle_right"
              alt="enter"
            ></b-img>
          </li>
          <!--                    <li-->
          <!--                            :class="{ disabled: !$store.getters.doctor.services.result }"-->
          <!--                            class="d-flex align-items-center mt-2 px-3 py-2 service"-->
          <!--                            v-on:click="toService(4)"-->
          <!--                    >-->
          <li
            :class="{ disabled: !$store.getters.doctor.services.result }"
            class="d-flex align-items-center mt-2 px-3 py-2 service"
            v-on:click="$router.push({ name: 'results' })"
          >
            <figure
              class="d-flex justify-content-center align-items-center mr-2 mb-0"
            >
              <b-img :src="path + results" alt="e-vysledky"></b-img>
            </figure>
            <div class="mr-2">
              <p class="text-22 semi-bold text-white mb-0">
                {{ $t('results_title') }}
              </p>
              <p class="text-price-light mb-0">
                {{
                  $t('service_price', [$store.getters.results.infoserviceprice])
                }}
              </p>
            </div>
            <b-img
              class="position-right"
              :src="path + angle_right"
              alt="enter"
            ></b-img>
          </li>
        </ul>
      </div>
    </b-card>
  </div>
</template>

<script>
import config from '../../../config'
import angle_left_small from '../../assets/old/angle_left_small.svg'
import angle_right from '../../assets/old/angle_right.svg'
// import recipe from "../../assets/old/recipe.svg";
// import results from "../../assets/old/results.svg";
import shop from '../../assets/icons/shopping_cart.svg'
import recipe from '../../assets/icons/recipe.svg'
import results from '../../assets/icons/results.svg'
import axios from 'axios'

export default {
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      recipe: recipe,
      results: results,
      shop: shop,
      eshopStatus: false,
    }
  },
  methods: {
    closeTab() {
      this.$router.push({ name: 'home' })
      // this.$store.dispatch("setCurrentService", 0);
    },
    // toService(index) {
    //     this.$store.dispatch("setCurrentService", index);
    // }
    getEshopStatus() {
      axios
        .get(config.ESHOP_API + '/categories/' + this.$store.getters.name)
        .then(response => {
          // console.warn(response);
          if (response.data.categories.length > 0) {
            this.eshopStatus = true
          } else {
            this.eshopStatus = false
          }
        })
        .catch(() => {
          this.eshopStatus = false
        })
    },

    getPrices() {
      this.$store.dispatch('getRecipe')
      this.$store.dispatch('getResults')
    },
  },
  mounted() {
    this.getEshopStatus()
    this.getPrices()
  }
}
</script>

<style scoped lang="scss">
.service {
  background-color: #22376c;
  border-radius: 0.25rem;

  &.disabled {
    background-color: #bdbdbd;
    pointer-events: none;
  }
}
li {
  figure {
    min-width: 65px;
    height: 60px;
  }
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: rgba(34, 55, 108, 0.9);
  }

  &.enquiry:hover {
    background-color: rgba(255, 71, 71, 0.9);
  }

  & > img {
    height: 25px;
  }
}
.position-right {
  margin: 0 0 0 auto;
}
</style>
