<template>
  <b-card border-variant="secondary" body-bg-variant="secondary">
    <div v-if="$store.getters.currentNextDays == 1">
      <b-button id="btn-back" variant="link" class="po-left" v-on:click="back">
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <div class="text-center">
        <b-img :src="path + calendar" alt="nextdays"></b-img>
        <p class="text-22 semi-bold mt-2 mb-0">
          {{ $t('nextdays_title') }}
        </p>
        <!--        <p>-->
        <!--          <b-img class="mb-1 mr-2" :src="path + green" alt="green"></b-img>-->
        <!--          <span>{{ $t("available_term") }}</span>-->
        <!--        </p>-->
      </div>
      <hr />
      <div v-if="show_service_message == true"><div class="card-body bg-primary">
        <b-img
      class="work float-left mr-3"
      :src="path + close_red"
      alt=""
    ></b-img>
    <span class="d-flex">{{ service_message }}</span>
      </div><br></div>
      <b-form>
        <p v-show="servicesLoading" class="text-center">
          <b-spinner label="Načitávam..."></b-spinner>
        </p>

        <p
          v-show="
            !servicesLoading && options.length === 0 && optionsTime.length === 0
          "
          class="text-16 semi-bold no-services"
        >
        <!-- Lekár aktuálne nemá voľné termíny. Skúste sa objednať zajtra, uvoľnia sa nové časenky. -->
        Lekár už nemá voľné termíny
        </p>
        <p
          v-show="
            !servicesLoading && options.length > 0 && optionsTime.length > 0
          "
          class="text-14 semi-bold mb-2"
        >
          {{ $t('select_service') }}
        </p>
        <b-row class="service-options">
          <b-col cols="6" v-for="item in options" :key="item.atitle">
            <b-button
              variant="link"
              class="border-secondary"
              @click="
                selected = item.atitle
                change()
                /*serviceNextDays(2)*/
              "
            >
              {{ item.atitle }}
            </b-button>
          </b-col>
          <b-col cols="6" v-for="item in optionsTime" :key="item.atitle + '#'">
            <b-button
              variant="primary"
              class="border-secondary time-btn"
              @click="
                selected = item.atitle + '#'
                change()
                /*serviceNextDays(2)*/
              "
            >
              <b-img
                v-if="!item.isvideo"
                class="service-clock mr-2"
                :src="path + clock"
                alt="clock"
              ></b-img>
              <b-img
                v-if="item.isvideo"
                class="service-video mr-2"
                :src="path + camera"
                alt="camera"
              ></b-img>
              {{ item.atitle }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-if="$store.getters.currentNextDays == 2">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceNextDays(1)"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <div class="text-center">
        <b-img :src="path + calendar" alt="nextdays"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('nextdays_title') }}
        </p>
        <p class="text-22 semi-bold">
          {{ selectedOption[0].atitle }}
        </p>
        <p>
          <b-img class="mb-1 mr-2" :src="path + green" alt="green"></b-img>
          <span>{{ $t('available_term') }}</span>
        </p>
      </div>
      <hr />
      <!--      <b-form>-->
      <!--        <p class="text-14 semi-bold mb-2">{{ $t("select_service") }}</p>-->
      <!--        <b-form-select-->
      <!--          v-model="selected"-->
      <!--          :options="options"-->
      <!--          :style="selectStyle"-->
      <!--          @change="change"-->
      <!--        ></b-form-select>-->
      <!--      </b-form>-->
      <datepicker
        ref="datepickerEl"
        class="mt-3 mb-4"
        :inline="true"
        :language="sk"
        format="dd MM yyyy"
        :monday-first="true"
        :disabled-dates="disabledDates"
        :highlighted="highlighted"
        calendar-class="calendar"
        @selected="selectDate"
        :full-month-name="true"
      ></datepicker>
      <b-form>
        <p v-show="!isTimeRequestSelected" class="text-14 semi-bold mb-2">
          {{ $t('select_service_number') }}
        </p>
        <p v-show="isTimeRequestSelected" class="text-14 semi-bold mb-2">
          {{ $t('select_day_time') }}
        </p>
        <b-form-select
          v-model="selectednumber"
          :options="optionsnumber"
          :style="selectStyle"
          @change="changenumber"
        ></b-form-select>
      </b-form>
      <div class="text-center mt-4">
        <b-button
          block
          size="lg"
          class="snd-3"
          :disabled="disabledTerm || disableNextButton"
          v-on:click="serviceNextDays(3)"
          >{{ $t('next')
          }}<b-img
            class="po-right"
            :src="path + angle_right"
            alt="nextdays"
          ></b-img>
        </b-button>
      </div>
    </div>

    <div v-if="$store.getters.currentNextDays == 3">
      <div class="text-center">
        <b-button
          id="btn-back"
          variant="link"
          class="po-left"
          v-on:click="()=> {serviceNextDays(2); number = ''; selectednumber = null; this.optionsnumber = [{ value: null, text: this.$t('select') }]; }"
        >
          <b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="arrow_left"
          ></b-img
          >{{ $t('back') }}
        </b-button>
        <b-button
          id="btn-close"
          variant="link"
          class="po-right"
          v-on:click="closeTab"
        >
          {{ $t('close') }}
          <b-img class="ml-1" :src="path + close" alt="x"></b-img>
        </b-button>
        <b-img :src="path + calendar" alt="nextdays"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('nextdays_title') }}
        </p>
        <p class="text-22 semi-bold">
          {{ $t('your_data') }}
        </p>
      </div>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-center mb-3"
      >
        <p class="text-14 text-primary bold mb-0">
          {{ date | moment }}
        </p>
        <p class="text-14 text-primary bold mb-0">{{ number }} - {{ title }}</p>
      </b-card>
      <b-form>
        <p
          class="text-14 semi-bold mb-2"
          :class="{
            submiterror:
              (!$v.name.required || !$v.name.minLength || !$v.name.maxLength) &&
              error,
          }"
        >
          {{ $t('name_and_surname') }}
          <span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="name"
          type="text"
          v-model.trim.lazy="$v.name.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="!$v.name.required && error">
          {{ $t('must_be_filled') }}
        </p>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="!$v.name.minLength && error">
          {{ $t('put_min') }}&nbsp;{{ $v.name.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="(!$v.name.maxLength && error) || (!sumLengthNameMessageValidator('name'))">
          {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          v-if="isTimeRequestSelected"
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              (!$v.email.required ||
                !$v.email.minLength ||
                !$v.email.maxLength) &&
              error,
          }"
        >
          {{ $t('email') }}
          <span v-if="isVideo" class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          v-if="isTimeRequestSelected"
          id="email"
          type="email"
          v-model.trim.lazy="$v.email.$model"
          :class="{
            submiterror: (this.$v.email.$error || (!this.$v.email.required && this.$v.email.$model.length == 0))
          }"
        ></b-form-input>
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.required && error && isTimeRequestSelected"
        >
          {{ $t('must_be_filled') }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.email && error && isTimeRequestSelected"
        >
          {{ $t('valid_email') }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.minLength && error && isTimeRequestSelected"
        >
          {{ $t('put_min') }}&nbsp;{{ $v.email.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.maxLength && error && isTimeRequestSelected"
        >
          {{ $t('put_max') }}&nbsp;{{ $v.email.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              $v.inr.required &&
              (!$v.inr.minLength || !$v.inr.maxLength) &&
              error,
          }"
        >
          {{ $t('your_in') }}
          <span
            v-if="$store.getters.nextDays.personal_data_required"
            class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
          <span
            v-if="!$store.getters.nextDays.personal_data_required"
            class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;({{ $t('doesnt_have_be_filled') }})</span
          >
        </p>
        <b-form-input
          id="inr"
          type="text"
          v-model.trim.lazy="$v.inr.$model"
          :class="{
            submiterror: ((inrMinLength && $v.inr.required && error) || (inrMaxLength && $v.inr.required && error))
          }"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.inr.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.inr.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          v-if="inrMinLength && $v.inr.required && error"
          class="text-14 text-danger error"
        >
          {{ $t('put_min') }}&nbsp;9&nbsp;{{ $t('numbers') }}
        </p>
        <p
          v-if="inrMaxLength && $v.inr.required && error"
          class="text-14 text-danger error"
        >
          {{ $t('put_max') }}&nbsp;12&nbsp;{{ $t('numbers') }}
        </p>
        <div v-if="enquiryQ.length === 0">
          <p
            class="text-14 semi-bold mt-4 mb-2"
            :class="{
              submiterror:
                (!$v.message.minLength || !$v.message.maxLength) &&
                $v.inr.required &&
                error,
            }"
          >
            {{ $t('message_for_doctor')
            }}<span class="text-14 text-muted normal"
              >&nbsp;&nbsp;&nbsp;&nbsp;({{ $t('doesnt_have_be_filled') }})</span
            >
          </p>
          <b-form-textarea
            id="message"
            type="text"
            rows="2"
            v-model.trim.lazy="$v.message.$model"
          ></b-form-textarea>
          <p
            class="text-14 text-danger error"
            v-if="(!$v.message.maxLength && error) || (!sumLengthNameMessageValidator('message'))"
          >
            {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
              $t('chars')
            }}
          </p>
        </div>

        <div v-if="enquiryQ.length > 0">
          <p class="text-14 semi-bold mt-4 mb-2">
            {{ $t('fill_please') }} {{ enquiryTitle }}:
          </p>
          <div v-for="question in enquiryQ" :key="question.id">
            <div
              class="mt-3"
              v-if="
                question.type !== 'GROUP' && question.type !== 'MULTISELECT'
              "
            >
              <p
                v-if="question.question && question.parent == null"
                class="text-14 semi-bold mb-0"
              >
                {{ question.question }}
              </p>
              <p
                v-if="question.comment && question.parent == null"
                class="text-14 text-gray normal mb-0"
              >
                {{ question.comment }}
              </p>
              <figure v-if="question.image && question.parent == null">
                <img
                  class="question-image"
                  :src="question.image"
                  :alt="qustion.id"
                />
              </figure>
            </div>
            <b-form-input
              v-if="question.type == 'STRING' && question.default == 'NOW'"
              :placeholder="new Date() | moment"
              readonly
            >
            </b-form-input>
            <b-form-input
              v-if="
                question.parent == null &&
                  question.type == 'STRING' &&
                  question.default != 'NOW'
              "
              v-model="enquiryA[question.id]"
              type="text"
            ></b-form-input>
            <b-form-textarea
              v-if="question.parent == null && question.type == 'TEXT'"
              :value="question.default"
              v-model="enquiryA[question.id]"
            ></b-form-textarea>
            <b-form-select
              v-if="question.parent == null && question.type == 'SELECT'"
              :id="question.id"
              v-model="enquiryA[question.id]"
              :options="question.options"
              :style="enquirySelectStyle"
            >
              <template v-slot:first>
                <option disabled>Zvoľte ak sa Vás to týka</option>
              </template>
            </b-form-select>
            <multiselect
              v-if="question.parent == null && question.type == 'MULTISELECT'"
              v-model="enquiryA[question.id]"
              :options="question.options"
              :multiple="true"
              :searchable="false"
              :hide-selected="true"
              placeholder="Zvoľte ak sa Vás to týka"
              selectLabel=""
            ></multiselect>
            <div
              v-if="question.type == 'GROUP'"
              class="wrapper-collapse mt-4"
              :style="enquirySelectStyle"
            >
              <b-link
                v-b-toggle="question.id"
                class="text-14 text-dark semi-bold d-block"
                @click="collapse"
                >Sekcia:
                <span class="text-15">{{ question.question }}</span></b-link
              >
              <b-collapse :id="question.id">
                <div v-for="item in enquiryQ" :key="item.id">
                  <div class="mt-3" v-if="item.parent == question.id">
                    <p
                      v-if="item.question && item.parent == question.id"
                      class="text-14 semi-bold mb-0"
                    >
                      {{ item.question }}
                    </p>
                    <p
                      v-if="item.comment && item.parent == question.id"
                      class="text-14 text-gray normal mb-0"
                    >
                      {{ item.comment }}
                    </p>
                    <figure v-if="item.image && item.parent == question.id">
                      <img
                        class="question-image"
                        :src="item.image"
                        :alt="item.id"
                      />
                    </figure>
                  </div>
                  <multiselect
                    v-if="
                      item.parent == question.id && item.type == 'MULTISELECT'
                    "
                    v-model="enquiryA[item.id]"
                    :options="item.options"
                    :multiple="true"
                    :searchable="false"
                    :hide-selected="true"
                    placeholder="Zvoľte ak sa Vás to týka"
                    selectLabel=""
                  ></multiselect>
                  <b-form-select
                    v-if="item.parent == question.id && item.type == 'SELECT'"
                    :id="item.id"
                    v-model="enquiryA[item.id]"
                    :options="item.options"
                    :style="enquirySelectStyle"
                  >
                    <template v-slot:first>
                      <option disabled>Zvoľte ak sa Vás to týka</option>
                    </template>
                  </b-form-select>
                  <b-form-input
                    v-if="
                      item.parent == question.id &&
                        item.type == 'STRING' &&
                        item.default != 'NOW'
                    "
                    v-model="enquiryA[item.id]"
                    type="text"
                  ></b-form-input>
                  <b-form-textarea
                    v-if="item.parent == question.id && item.type == 'TEXT'"
                    :value="item.default"
                    v-model="enquiryA[item.id]"
                  ></b-form-textarea>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-form>

      <div v-if="enquiryId">
        <b-button block size="lg" class="mt-4 snd-4" v-on:click="serviceNextDays(4)"
          >{{ $t('next')
          }}<b-img
            class="po-right"
            :src="path + angle_right"
            alt="nextdays"
          ></b-img>
        </b-button>
      </div>

      <div v-else>
        <b-media
          right-align
          vertical-align="center"
          class="p-2 p-sm-3 text-white mb-3 mt-5 snd-5-media-calling"
          v-on:click="serviceNextDays(5, true)"
          v-show="!isTimeRequestSelected"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 semi-bold mb-1">
              {{ $t('put_calling') }}
            </p>
            <p class="text-12 mb-1">
              {{ $t('put_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [$store.getters.nextDays.infoserviceprice])
              }}
            </p>
          </div>
        </b-media>
        <b-media
          right-align
          vertical-align="center"
          class="p-2 p-sm-3 text-white snd-5-media-no-calling"
          v-on:click="serviceNextDays(5)"
          v-show="!isTimeRequestSelected"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 semi-bold mb-1">
              {{ $t('put_no_calling') }}
            </p>
            <p class="text-12 mb-1">
              {{ $t('put_no_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [
                  $store.getters.nextDays.infonoserviceprice,
                ])
              }}
            </p>
          </div>
        </b-media>

        <b-button
          block
          size="lg"
          class="mt-4  snd-5-a"
          v-on:click="serviceNextDays(5)"
          v-show="isTimeRequestSelected"
          >{{ $t('send') }}
          <p class="text-price-light mb-0">
            {{
              $t('service_price', [$store.getters.nextDays.timerequestprice])
            }}
          </p>
          <b-img
            class="po-right"
            :src="path + angle_right"
            alt="nextdays"
          ></b-img>
        </b-button>
      </div>
    </div>

    <div v-if="$store.getters.currentNextDays == 4" class="text-center">
      <b-img :src="path + calendar" alt="nextdays"></b-img>
      <p class="text-22 text-muted semi-bold mt-2 mb-0">
        {{ $t('nextdays_title') }}
      </p>
      <p class="text-22 text-muted semi-bold mb-0">
        {{ $t('your_data') }}
      </p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left snd-3"
        v-on:click="serviceNextDays(3)"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="arrow_left"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <hr />
      <div class="terms">
        <div v-html="terms"></div>
        <p class="date">{{ new Date() | moment }}</p>
      </div>
      <div class="text-center mt-2">
        <b-form-checkbox v-model="consentChecked">{{
          $t('agree')
        }}</b-form-checkbox>
      </div>

      <div v-if="enquiryId">
        <b-button
          block
          size="lg"
          class="mt-4 snd-5-b"
          :disabled="disabledTerms"
          v-on:click="serviceNextDays(5)"
          v-show="isTimeRequestSelected"
          >{{ $t('send')
          }}<b-img
            class="po-right"
            :src="path + angle_right"
            alt="nextdays"
          ></b-img>
        </b-button>

        <b-media
          right-align
          vertical-align="center"
          :class="
            'p-2 p-sm-3 snd-5-media-calling-enquiry-id text-white mb-3 mt-5' +
              (disabledTerms ? ' disabled' : '')
          "
          v-on:click="serviceNextDays(5, true)"
          v-show="!isTimeRequestSelected"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 semi-bold mb-1">
              {{ $t('put_calling') }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('put_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [$store.getters.nextDays.infoserviceprice])
              }}
            </p>
          </div>
        </b-media>
        <b-media
          right-align
          vertical-align="center"
          :class="'p-2 snd-5-media-no-calling-enquiry-id p-sm-3 text-white' + (disabledTerms ? ' disabled' : '')"
          v-on:click="serviceNextDays(5)"
          v-show="!isTimeRequestSelected"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 semi-bold mb-1">
              {{ $t('put_no_calling') }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('put_no_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [
                  $store.getters.nextDays.infonoserviceprice,
                ])
              }}
            </p>
          </div>
        </b-media>
      </div>
    </div>

    <div v-if="$store.getters.currentNextDays == 5" class="text-center">
      <b-img :src="path + calendar" alt="nextdays"></b-img>
      <p class="text-22 text-muted semi-bold mt-2 mb-0">
        {{ $t('nextdays_title') }}
      </p>
      <p class="text-22 text-muted semi-bold mb-0">
        {{ $t('your_data') }}
      </p>
      <p
        v-if="$store.getters.service"
        v-show="!isTimeRequestSelected"
        class="text-22 semi-bold mb-0"
      >
        {{ $t('put_calling') }}
      </p>
      <p
        v-if="!$store.getters.service"
        v-show="!isTimeRequestSelected"
        class="text-22 semi-bold mb-0"
      >
        {{ $t('put_no_calling') }}
      </p>
      <p v-show="isTimeRequestSelected" class="text-22 semi-bold mb-0">
        {{ $t('time_request') }}
      </p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceNextDays(3)"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="arrow_left"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="mb-3 info"
      >
        <p class="text-14 text-primary bold mb-0">
          {{ date | moment }}
        </p>
        <p class="text-14 text-primary bold mb-0">{{ number }} - {{ title }}</p>
        <p class="text-14 text-primary bold mb-0">{{ name }}</p>
        <p class="text-14 text-primary bold mb-0">{{ message }}</p>
      </b-card>
      <p v-show="!isTimeRequestSelected" class="text-14 semi-bold">
        <span v-if="showSMS">{{ $t('send_sms_nextdays') }}</span>
      </p>
      <p v-show="isTimeRequestSelected" class="text-14 semi-bold">
        {{ $t('send_sms_time_request') }}
      </p>
      <div v-if="!prereservedModal">
      <Sms v-if="showSMS"></Sms>
        <div v-else>
          <Preloader v-if="errorSMStext.length == 0" />
         <p v-else>
            <OrderKeyNull :err="errorSMStext" />
        </p>
        </div>
      </div>
      <p v-show="!isTimeRequestSelected && showSMS" class="text-12 semi-bold">
        {{
          $store.getters.service
            ? $t(
                'actual_state_calling',
                $store.getters.nextDays.callahead
                  ? [$store.getters.nextDays.callahead]
                  : ['dvaja']
              )
            : $t('actual_state_no_calling')
        }}
      </p>

      <p v-show="isTimeRequestSelected && !isVideo && showSMS" class="text-12 semi-bold">
        {{
          $t('actual_state_time_request', [
            $store.getters.nextDays.timerequestprice,
          ])
        }}
      </p>

      <p v-show="isTimeRequestSelected && isVideo && showSMS" class="text-12 semi-bold">
        {{
          $t('actual_state_video_request', [
            $store.getters.nextDays.timerequestprice,
          ])
        }}
      </p>

      <p v-if="showSMS" class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>

    <div v-if="$store.getters.currentNextDays == 6" class="text-center">
      <b-img :src="path + calendar" alt="reserve"></b-img>
      <b-button variant="link" class="po-left" v-on:click="closeTab">
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <p class="text-22 text-muted semi-bold mt-2 mb-0">
        {{ $t('nextdays_title') }}
      </p>
      <p class="text-22 text-muted semi-bold mb-0">
        {{ $t('your_data') }}
      </p>
      <p
        v-if="$store.getters.service && !isTimeRequestSelected"
        class="text-22 semi-bold mb-0"
      >
        {{ $t('put_calling') }}
      </p>
      <p
        v-if="!$store.getters.service && !isTimeRequestSelected"
        class="text-22 semi-bold mb-0"
      >
        {{ $t('put_no_calling') }}
      </p>
      <p v-if="isTimeRequestSelected" class="text-22 semi-bold mb-0">
        {{ $t('time_request') }}
      </p>
      <hr />
      <b-card
        v-if="!isTimeRequestSelected"
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
        <p class="text-22 mb-0">
          {{ $t('order') }}
          <span
            v-if="$store.getters.orderStatus.order_status == 'confirmed'"
            class="bold"
            >{{ $t('confirmed') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'paid'"
            class="bold"
            >{{ $t('paid') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'payment_failed'"
            class="bold"
            >{{ $t('payment_failed') }}</span
          >
        </p>
        <p v-if="$store.getters.service" class="text-14 mb-0">
          {{ $t('book_card_with') }}
        </p>
        <p v-if="!$store.getters.service" class="text-14 mb-0">
          {{ $t('book_card_without') }}
        </p>
        <p class="text-14 mb-0">
          {{ $t('price') }}&nbsp;{{ $store.getters.orderStatus.order_price }}
        </p>
      </b-card>
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
        <p class="text-18 semi-bold mb-0">
          {{
            isTimeRequestSelected ? $t('time_ticket') : $t('ticket')
          }}&nbsp;<span class="bold">{{
            $store.getters.orderStatus.app_onday
          }}</span>
        </p>
        <p v-if="!isTimeRequestSelected" class="text-18 semi-bold mb-0">
          {{ $t('number') }}&nbsp;<span class="bold">{{
            $store.getters.orderStatus.app_nr
          }}</span>
        </p>
        <p v-if="!isTimeRequestSelected" class="text-18 semi-bold mb-0">
          {{ $t('price') }}&nbsp;
          <span v-if="$store.getters.orderStatus.app_status == 'N'" class="bold"
            >{{ $t('valid') }},&nbsp;</span
          >
          <span
            v-if="$store.getters.orderStatus.order_type == 's'"
            class="bold"
            >{{ $t('with_calling') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_type == 'r'"
            class="bold"
            >{{ $t('without_calling') }}</span
          >
        </p>
        <p v-if="isTimeRequestSelected" class="text-18 semi-bold mb-0">
          <span
            v-if="$store.getters.orderStatus.order_type == 'y'"
            class="bold"
            >{{ $t('time_request_valid') }}</span
          >
        </p>
      </b-card>
      <p v-if="isTimeRequestSelected" class="text-12 semi-bold">
        {{ $t('time_request_thank_you') }}
      </p>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img>
      </b-button>
    </div>
    <div v-if="$store.getters.currentNextDays == 7" class="text-center">
      <WaitOrder :title="$t('nextdays_title')" :subtitle="''" :image="calendar" />
    </div>
    <div v-if="$store.getters.currentNextDays == 8" class="text-center">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="$t('nextdays_title')" :subtitle="''" :image="calendar" />
    </div>
    <div v-if="$store.getters.currentNextDays == 9" class="text-center">
      <b-img :src="path + calendar" alt="reserve"></b-img>
      <b-button variant="link" class="po-left" v-on:click="closeTab">
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <p class="text-22 text-muted semi-bold mt-2 mb-0">
        {{ $t('nextdays_title') }}
      </p>
      <p class="text-22 text-muted semi-bold mb-0">
        {{ $t('your_data') }}
      </p>
      <p v-if="isTimeRequestSelected" class="text-22 semi-bold mb-0">
        {{ $t('time_request') }}
      </p>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
      <p class="semi-bold mb-0">
        {{ $t('time_request_thank_you_manual') }}
      </p>
      </b-card>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img>
      </b-button>
    </div>
    <div v-for="(item, index) in disabledDates.dates" :key="index">
      {{ item.date }}
    </div>

    <b-modal v-model="modalError.show" id="xmodal" ref="modal" size="md" hide-header ok-title="Pokračovať" @ok="modalError.ok"
    no-close-on-esc no-close-on-backdrop centered ok-only scrollable ok-variant="secondary" :static="true">
    <div class="mb-2 text-center" style="color:#9b0a0a;">
      <svg class="ov-icon" aria-hidden="true" width="46.08" height="46.08" viewBox="-75.52 -43.52 599.04 599.04" fill="currentColor" style="font-size: 2.88em;"><path d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z"></path></svg>
      </div>
    <div class="text-center">{{ $t(modalError.text) }}</div>
    </b-modal>


  </b-card>
</template>

<script>
import axios from 'axios'
import config from '../../../../config'
import close from '../../../assets/old/close.svg'
import current from '../../../assets/old/current.svg'
import angle_down from '../../../assets/old/angle_down.svg'
import angle_right from '../../../assets/old/angle_right.svg'
import angle_right_small from '../../../assets/old/angle_right_small.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import angle_right_blue from '../../../assets/old/angle_right_blue.svg'
import arrow_left from '../../../assets/old/arrow_left.svg'
import arrow_right from '../../../assets/old/arrow_right.svg'
import info from '../../../assets/old/info.svg'
import reserve from '../../../assets/old/reserve2.svg'
// import calendar from "../../../assets/old/calendar2.svg";
import gray from '../../../assets/old/gray.svg'
import green from '../../../assets/old/green.svg'
import red from '../../../assets/old/red.svg'
import clock from '../../../assets/old/clock.svg'
import camera from '../../../assets/old/camera.svg'

import calendar from '../../../assets/icons/calendar2.svg'
import WaitOrder from '../../partials/WaitOrder.vue'
import FailedOrder from '../../partials/FailedOrder.vue'
import Datepicker from 'vuejs-datepicker'
import { sk } from 'vuejs-datepicker/dist/locale'
import Sms from '../Sms'
let moment = require('moment')
import Multiselect from 'vue-multiselect'
import Preloader from '../../Preloader.vue'
import OrderKeyNull from '../../partials/OrderKeyNull.vue'

import Vue from 'vue'
import { ref } from 'vue'
import {
  required,
  requiredUnless,
  requiredIf,
  email,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import VueNativeSock from 'vue-native-websocket'
import close_red from '../../../assets/old/close_red.svg'
import SumLengthNameMessageValidator from '../../../modules/SumLengthNameMessageValidator';

Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
})

export default {
  setup(){
  const datepickerEl = ref(null);

  return { datepickerEl }
  },
  components: {
    Datepicker,
    Sms,
    Multiselect,
    WaitOrder,
    FailedOrder,
    Preloader,
    OrderKeyNull,
  },
  data() {
    return {
      sk: sk,
      path: config.URL,
      close: close,
      current: current,
      angle_right: angle_right,
      angle_right_blue: angle_right_blue,
      angle_right_small: angle_right_small,
      angle_left_small: angle_left_small,
      arrow_left: arrow_left,
      arrow_right: arrow_right,
      angle_down: angle_down,
      info: info,
      reserve: reserve,
      calendar: calendar,
      gray: gray,
      green: green,
      red: red,
      clock: clock,
      camera: camera,
      selected: null,
      selectedOption: null,
      selectednumber: null,
      servicesLoading: false,
      options: [],
      optionsTime: [],
      serviceOptions: [],
      serviceTimeOptions: [],
      optionsnumber: [{ value: null, text: this.$t('select') }],
      date: new Date(),
      number: '',
      id: '',
      title: '',
      disabledDates: {
        to: new Date(
          new Date(Date.now() + 3600 * 1000 * 24).setHours(0, 0, 0, 0)
        ),
      },
      highlighted: {
        includeDisabled: true,
        dates: [],
        customPredictor: function(date) {
          if (new Date(date) <= new Date()) {
            return true
          }
        },
      },
      highlightedDates: null,
      days: this.$store.getters.nextDays.days,
      services: {},
      currentStyle: {
        background: 'url("' + config.URL + current + '") no-repeat center 25px',
      },
      selectStyle: {
        background:
          'url("' +
          config.URL +
          angle_down +
          '") no-repeat right 0.75rem center/18px 10px',
      },
      enquirySelectStyle: {
        background:
          '#fff url("' +
          config.URL +
          angle_down +
          '") no-repeat right 0.75rem top 14px/18px 10px',
      },
      name: '',
      inr: '',
      email: '',
      isVideo: false,
      message: '',
      isTimeRequestSelected: false,
      timeRequests: [],
      enquiries: [],
      enquiryId: '',
      enquiryQ: [],
      enquiryA: {},
      enquiryTitle: '',
      enquiryName: '',
      terms: '',
      consentChecked: false,
      error: false,
      show_service_message: false,
      service_message: '',
      close_red: close_red,
      showSMS: false,
      errorSMStext: '',
      disableNextButton: true,
      modalError: {
        show: false,
        text: '',
        ok: async() => {
          this.$store.dispatch('setCurrentNextDays', 0)
          this.number = '';
          this.optionsnumber = [{ value: null, text: this.$t('select') }];
          /* eslint-disable */ 
          try {
            this.$refs.datepickerEl.clearDate()
          } catch (error) {
            
          }
          /* eslint-enable */ 
          this.selectednumber = null
          this.disableNextButton = true
          this.serviceNextDays(1)
          this.serviceNextDays(2)
        },
        cancel: () => console.log('modal close')
      }
    }
  },
  computed: {
    prereservedModal(){
      return this.$store.getters.prereservedModal;
    },
    getRequired() {
      return this.$store.getters.nextDays.personal_data_required
    },
    nameMinLength() {
      return this.name.length > 2 ? false : true
    },
    nameMaxLength() {
      return this.name.length + this.message.length <= 512 ? false : true
    },
    emailMinLength() {
      return this.email.length > 2 ? false : true
    },
    emailMaxLength() {
      return this.email.length <= 100 ? false : true
    },
    inrMinLength() {
      return this.inr.replace(/\D/g, '').length > 8 ? false : true
    },
    inrMaxLength() {
      return this.inr.replace(/\D/g, '').length <= 12 ? false : true
    },
    disabledTerm() {
      return this.selected != null &&
        this.date > new Date() &&
        this.selectednumber != null
        ? false
        : true
    },
    disabledTerms() {
      return this.consentChecked ? false : true
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    email: {
      required: requiredIf('isVideo'),
      email,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    inr: {
      required: requiredUnless('getRequired'),
      minLength: minLength(9),
      maxLength: maxLength(512),
    },
    message: {
      maxLength: 512,
    },
  },
  mounted() {
    this.serviceNextDays(1)
    this.getNextDays()
   // this.enquiryA = { q0: moment(new Date()).format('DD.MM.YYYY, H:mm:ss') }

    this.name = (this.$store.getters.customer.cache_name) ? this.$store.getters.customer.cache_name : '';
    this.inr = (this.$store.getters.customer.pnr) ? this.$store.getters.customer.pnr : '';
    this.message = (this.$store.getters.customer.cache_message) ? this.$store.getters.customer.cache_message : '';
    this.email = (this.$store.getters.customer.email) ? this.$store.getters.customer.email : '';
    this.enquiryA = (this.$store.getters.customer.cache_enquiryA) ? this.$store.getters.customer.cache_enquiryA : { q0: moment(new Date()).format('DD.MM.YYYY, H:mm:ss') }

  },
  updated() {
    this.$store.dispatch('checkBack')
  },
  methods: {
    sumLengthNameMessageValidator(type) {
      const value = SumLengthNameMessageValidator.check(this.name, this.message);
      
      if(type && type == 'name'){
        return value.allowed_name;
      }

      if(type && type == 'message'){
        return value.allowed_message;
      }

      return value.allowed;
    },
    getNextDays() {
      this.servicesLoading = true

      axios
        .get(config.API + '/nextdays/' + this.$store.getters.name)
        .then(response => {
          // console.warn(response);
          this.$store.dispatch('setNextDays', response.data)
          this.days = response.data.days

          this.days.forEach(day => {
            day.numbers.map(numbers => {
              let service = numbers.atitle
              if (this.services[service] === undefined) {
                this.services[service] = {}
                this.options.push(numbers)
              }
              let date = day.date
              if (this.services[service][date] === undefined) {
                this.services[service][date] = {}
              }
              let aid = numbers.aid
              if (this.services[service][date][aid] === undefined) {
                this.services[service][date][aid] = []
              }
              this.services[service][date][aid].push(numbers.nr)

              this.serviceOptions[service] = numbers

              if (!this.enquiries[aid] && numbers.enquiryid !== null) {
                this.enquiries[aid] = numbers.enquiryid
              }
            })

            if (day.times) {
              if (day.times.length > 0) {
                day.times.map(times => {
                  let service = times.atitle + '#'
                  if (this.services[service] === undefined) {
                    this.services[service] = {}
                    this.optionsTime.push(times)
                  }
                  let date = day.date
                  if (this.services[service][date] === undefined) {
                    this.services[service][date] = {}
                  }
                  let aid = times.aid
                  if (this.services[service][date][aid] === undefined) {
                    this.services[service][date][aid] = []
                  }
                  this.services[service][date][aid].push([
                    times.ampm,
                    times.avail_count,
                  ])

                  this.timeRequests.push(service)

                  if (!this.enquiries[aid] && times.enquiryid !== null) {
                    this.enquiries[aid] = times.enquiryid
                  }
                })
              }
            }
          })

          // console.warn(this.serviceOptions, this.timeRequests);
          let disabledDates = this.days.filter(day => day.available == false)
          this.disabledDates.dates = []
          disabledDates.map(date => {
            this.disabledDates.dates.push(new Date(date.date))
          })
          // console.warn(this.disabledDates)
        })
        .finally(() => {
          this.servicesLoading = false
        })
    },
    change() {
      if (this.timeRequests.includes(this.selected)) {
        this.isTimeRequestSelected = true

        // console.warn(this.selected);

        this.selectedOption = this.optionsTime.filter(
          val => val.atitle + '#' == this.selected
        )
        // console.warn(this.selectedOption[0].atitle);

        this.isVideo = this.selectedOption[0].isvideo
      } else {
        this.isTimeRequestSelected = false
        this.isVideo = false
        this.selectedOption = this.options.filter(
          val => val.atitle == this.selected
        )
      }

      this.highlighted.dates = []
      this.highlightedDates = Object.entries(this.services).filter(
        val => val[0] == this.selected
      )
      // console.warn(Object.entries(this.highlightedDates[0][1]));
      Object.entries(this.highlightedDates[0][1]).forEach(([key]) => {
        this.highlighted.dates.push(new Date(key))
      })
      // console.warn(this.highlighted.dates);

      this.deleteServices()
      this.serviceNextDays(2)
    },
    selectDate(val) {
      let service = Object.entries(this.highlightedDates[0][1]).filter(
        value =>
          moment(value[0]).format('MM/DD/YYYY') ==
          moment(val).format('MM/DD/YYYY')
      )
      this.deleteServices()
      if (service.length) {
        this.date = val
        Object.entries(service[0][1]).forEach(([key]) => {
          this.id = key
          this.title = this.selectedOption[0].atitle
        })

        if (this.isTimeRequestSelected) {
          Object.entries(service[0][1]).forEach(([key, value]) => {
            // console.warn(key, value);

            let timeName = value[0][0]
            let timeNameCapital =
              value[0][0].charAt(0).toUpperCase() + value[0][0].slice(1)
            let timeNumber = value[0][1]

            if (timeNumber > 0) {
              this.optionsnumber.push({
                value: timeName,
                text:
                  timeNameCapital +
                  ' ' +
                  this.$t('still') +
                  ' ' +
                  timeNumber +
                  ' ' +
                  this.$t('free'),
                x: key,
              })
            } else {
              this.optionsnumber.push({
                value: timeName,
                text: timeNameCapital + ' ' + this.$t('reserved'),
                x: key,
                disabled: true,
              })
            }
          })
        } else {
          Object.entries(service[0][1][this.id]).forEach(([key, value]) => {
            this.optionsnumber.push({
              value: value,
              text: value,
              x: key,
            })
          })
        }

        // console.warn(this.id);
      } else {
        this.date = new Date()
      }
    },
    changenumber() {
      this.disableNextButton = true;
      this.number = this.selectednumber
      if (this.isTimeRequestSelected) {
        this.$store.dispatch('setTimeRequest', true)
        this.id = this.optionsnumber.filter(
          val => this.selectednumber == val['value']
        )[0]['x']
      } else {
        this.$store.dispatch('setTimeRequest', false)
      }
     
      /* eslint-disable */ 
      if (this.id) {
        axios
          .post(config.API + '/prereserve/' + this.$store.getters.name, {
            onday: moment(new Date(this.date)).format('YYYY-MM-DD'),
            appnumber: (this.number || null), // cislo buducej casenky !!! osetrit ak nieje, tak neposielat
            activity_id: +this.id // cislo aktivity  !!!
          },
            {
              validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
              }
            })
          .then(response => {
            if (response.status == 400 && this.id) {
              this.disableNextButton = true
              this.modalError.text = response.data.detail
              this.modalError.show = true
            } else {
              if (this.id) {
                this.disableNextButton = false;
                this.$store.dispatch('setPrereservationId', response.data);
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.disableNextButton = true;
          });
      }

     /* eslint-enable */

      this.enquiryId = this.enquiries[this.id]
      if (this.enquiryId) {
        this.message = ''
        //this.enquiryA = []
        axios
          .get(config.API + '/enquiryid/' + this.enquiryId)
          .then(response => {
            let enquiry =
              response.data.enquiries[Object.keys(response.data.enquiries)[0]]
            this.enquiryQ = enquiry.questions
            this.enquiryTitle = enquiry.title
            this.enquiryName = Object.keys(response.data.enquiries)[0]

            // this.nameEnquiry = response[0][0];
            // this.title = response[0][1].title;
            // this.btntitle = response[0][1].btntitle;
            // this.texts = response[0][1].text;
            // this.questions = response[0][1].questions;

            axios
              .get(config.API + '/enquiryconsent/' + this.enquiryName)
              .then(response => {
                this.terms = response.data
              })
          })
      } else {
        this.enquiryQ = []
        //this.enquiryA = []
      }
    
    },
    collapse() {
      event.target.parentElement.classList.toggle('collapsed')
    },
    deleteServices() {
      while (this.optionsnumber.length) {
        this.optionsnumber.pop()
      }
      this.optionsnumber.push({
        value: null,
        text: this.$t('select'),
      })
      this.selectednumber = null
    },
    // closeTab() {
    //   // this.$store.dispatch("setCurrentService", 6);
    //   this.$store.dispatch("setCurrentNextDays", 0);
    //   this.$router.push({ name: 'number_order' });
    // },
    back() {
      // this.$store.dispatch("setCurrentService", 6);
      this.$store.dispatch('setCurrentNextDays', 0)
      this.$router.push({ name: 'number_order' })
    },
    closeTab() {
      // this.$store.dispatch("setCurrentService", 6);
      this.$store.dispatch('setCurrentNextDays', 0)
      this.$router.push({ name: 'home' })
    },
    serviceNextDays(index, service = false) {
      //console.log(index)
      if(!this.sumLengthNameMessageValidator()){ return; }
      if (index == 4) {
        this.$v.$touch()
        if (
          (this.$v.$invalid &&
           // !this.$store.getters.nextDays.personal_data_required &&
            this.$v.name.$error) ||
          (this.inrMinLength && this.$v.inr.required) ||
          (this.inrMinLength && this.inrMaxLength && this.$v.inr.required) ||
          //(this.$v.email.$error && this.isVideo)
          (this.isTimeRequestSelected && (this.$v.email.$error || (!this.$v.email.required && this.$v.email.$model.length == 0)))
        ) {
          this.error = true
          let element = document.getElementsByClassName('submiterror')
          if(element.length){
          this.$nextTick(() => {
            element[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
        }
          return false
        } else {
          this.error = false
          this.consentChecked = false
          this.$store.dispatch('setCurrentNextDays', index)
        }
      } else if (index == 5) {
        //if(!this.sumLengthNameMessageValidator()){ return; }
        let formData = {}
        // console.warn(this.enquiryId);
        // console.warn(this.message);
        if (this.enquiryId) {
          this.error = false
          this.$store.dispatch('setServiceNextDays', service)
          let date = this.date

          let answers = []
          Object.entries(this.enquiryA).map(val => {
            answers.push({
              id: val[0],
              answer: val[1],
            })
          })
          let answerA, answerB
          answers.sort((a, b) => {
            answerA =
              a.id == 'q0'
                ? -1
                : this.enquiryQ.filter(val => val.id == a.id)[0].order
            answerB =
              b.id == 'q0'
                ? -1
                : this.enquiryQ.filter(val => val.id == b.id)[0].order
            if (answerA < answerB) {
              return -1
            }
            if (answerA > answerB) {
              return 1
            }
          })
          const enquiry_name = {}
          enquiry_name[this.enquiryName] = answers

          // console.warn(enquiry_name);
          if (this.isTimeRequestSelected) {
            // console.warn("time request", this.id);
            if (this.isVideo) {
              formData = {
                type: 'timerequest',
                name: this.name + (this.message ? ', ' + this.message : ''),
                email: this.email,
                date:
                  date.getFullYear() +
                  '-' +
                  (date.getMonth() + 1) +
                  '-' +
                  date.getDate(),
                aid: this.id,
                pnr: this.inr,
                consent: true,
                answers: enquiry_name,
              }
              this.$store.dispatch('placeOrder', formData)
              formData.cache_name = this.name;
              formData.cache_message = this.message;
              formData.cache_enquiryA = this.enquiryA;
              this.$store.dispatch('setCustomer', formData)
            } else {
              formData = {
                type: 'timerequest',
                name: this.name + (this.message ? ', ' + this.message : ''),
                email: this.email,
                date:
                  date.getFullYear() +
                  '-' +
                  (date.getMonth() + 1) +
                  '-' +
                  date.getDate(),
                aid: this.id,
                pnr: this.inr,
                consent: true,
                answers: enquiry_name,
              }
              this.$store.dispatch('placeOrder', formData)
              formData.cache_name = this.name;
              formData.cache_message = this.message;
              formData.cache_enquiryA = this.enquiryA;
              this.$store.dispatch('setCustomer', formData)
            }
          } else {
            // console.warn("not time request");
            formData = {
              type: 'nextdays',
              infoservice: this.$store.getters.service,
              name: this.name + (this.message ? ', ' + this.message : ''),
              date:
                date.getFullYear() +
                '-' +
                (date.getMonth() + 1) +
                '-' +
                date.getDate(),
              nr: this.number,
              aid: this.id,
              pnr: this.inr,
              consent: true,
              answers: enquiry_name,
            }
            this.$store.dispatch('placeOrder', formData)
            formData.cache_name = this.name;
            formData.cache_message = this.message;
            formData.cache_enquiryA = this.enquiryA;
            this.$store.dispatch('setCustomer', formData)
          }
          this.$store.dispatch('setCurrentNextDays', index)
          
          setTimeout(() => {
            if(this.$store.state.placeorder.order_key){
              this.showSMS = true
            this.$connect(
              config.SOCKET + this.$store.getters.placeOrder.order_key,
              { format: 'json' }
            )
            this.$socket.onmessage = data => {
              if (JSON.parse(data.data).event == 'order_confirmed') {
                this.$store.dispatch('setCurrentNextDays', 7)
              } else if (JSON.parse(data.data).event == 'order_ready') {
                this.$store.dispatch('getOrderStatus')
                this.$store.dispatch('setCurrentNextDays', 6)
                this.$disconnect()
              } else if (JSON.parse(data.data).event == 'order_paid') {
                this.$store.dispatch('setCurrentNextDays', 7)
              } else if (
                JSON.parse(data.data).event == 'order_payment_failed'
              ) {
                this.$store.dispatch('setCurrentNextDays', 8)
                this.$disconnect()
              } else if (
                  JSON.parse(data.data).event == 'order_waiting_for_approval'
              ) {
                  this.$store.dispatch('setCurrentNextDays', 9)
                  this.$disconnect()
              } else if (
                JSON.parse(data.data).event == 'order_cancelled_due_to_reservation_shortage'
              ) {
                this.$disconnect()
                this.modalError.text = this.$t('order_cancelled_due_to_reservation_shortage')
                this.modalError.show = true
              } else {
                // console.log("error", JSON.parse(data.data).event);
              }
            }
          } else {
              this.errorSMStext = (this.$store.state.placeorder.err && this.$store.state.placeorder.err != '') ? this.$store.state.placeorder.err : 'ERROR_SMS_KEY';
            }
          }, 5000)
        
        } else {
          this.$v.$touch()
          if (
            (this.$v.$invalid &&
            //  this.$store.getters.nextDays.personal_data_required &&
              this.$v.name.$error) ||
            (this.inrMinLength && this.$v.inr.required) ||
            (this.inrMinLength && this.inrMaxLength && this.$v.inr.required) ||
            (this.isTimeRequestSelected && (this.$v.email.$error || (!this.$v.email.required && this.$v.email.$model.length == 0)))
          ) {
            //console.warn('error true')
            this.error = true
            let element = document.getElementsByClassName('submiterror')
            if(element.length){
            this.$nextTick(() => {
              element[0].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            })
          }
            return false
          } else {
            // console.log('started');
            this.error = false
            this.$store.dispatch('setServiceNextDays', service)
            let date = this.date
            if (this.isTimeRequestSelected) {
              // console.warn("time request", this.id);
              if (this.isVideo) {
                formData = {
                  type: 'timerequest',
                  name: this.name + (this.message ? ', ' + this.message : ''),
                  email: this.email,
                  date:
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1) +
                    '-' +
                    date.getDate(),
                  aid: this.id,
                  pnr: this.inr,
                  cache_name: this.name,
                  cache_message: this.message
                }
                this.$store.dispatch('placeOrder', formData)
                formData.cache_name = this.name;
                formData.cache_message = this.message;
                formData.cache_enquiryA = this.enquiryA;
                this.$store.dispatch('setCustomer', formData)
              } else {
                formData = {
                  type: 'timerequest',
                  name: this.name + (this.message ? ', ' + this.message : ''),
                  date:
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1) +
                    '-' +
                    date.getDate(),
                  aid: this.id,
                  pnr: this.inr,
                }
                this.$store.dispatch('placeOrder', formData)
                formData.cache_name = this.name;
                formData.cache_message = this.message;
                formData.cache_enquiryA = this.enquiryA;
                this.$store.dispatch('setCustomer', formData)
              }
            } else {
              // console.warn("not time request");
              formData = {
                type: 'nextdays',
                infoservice: this.$store.getters.service,
                name: this.name + (this.message ? ', ' + this.message : ''),
                date:
                  date.getFullYear() +
                  '-' +
                  (date.getMonth() + 1) +
                  '-' +
                  date.getDate(),
                nr: this.number,
                aid: this.id,
                pnr: this.inr,
              }
              this.$store.dispatch('placeOrder', formData)
              formData.cache_name = this.name;
              formData.cache_message = this.message;
              formData.cache_enquiryA = this.enquiryA;
              this.$store.dispatch('setCustomer', formData)
            }
            this.$store.dispatch('setCurrentNextDays', index)
            setTimeout(() => {
              if(this.$store.state.placeorder.order_key){
                this.showSMS = true
              this.$connect(
                config.SOCKET + this.$store.getters.placeOrder.order_key,
                { format: 'json' }
              )
              this.$socket.onmessage = data => {
                if (JSON.parse(data.data).event == 'order_confirmed') {
                  this.$store.dispatch('setCurrentNextDays', 7)
                } else if (JSON.parse(data.data).event == 'order_ready') {
                  this.$store.dispatch('getOrderStatus')
                  this.$store.dispatch('setCurrentNextDays', 6)
                  this.$disconnect()
                } else if (JSON.parse(data.data).event == 'order_paid') {
                  this.$store.dispatch('setCurrentNextDays', 7)
                } else if (
                  JSON.parse(data.data).event == 'order_payment_failed'
                ) {
                  this.$store.dispatch('setCurrentNextDays', 8)
                  this.$disconnect()
                } else if (
                  JSON.parse(data.data).event == 'order_waiting_for_approval'
                ) {
                  this.$store.dispatch('setCurrentNextDays', 9)
                  this.$disconnect()
                } else if (
                  JSON.parse(data.data).event == 'order_cancelled_due_to_reservation_shortage'
                ) {
                  this.$disconnect()
                  this.modalError.text = this.$t('order_cancelled_due_to_reservation_shortage')
                  this.modalError.show = true
                } else {
                  // console.log("error", JSON.parse(data.data).event);
                }
              }
            } else {
              this.errorSMStext = (this.$store.state.placeorder.err && this.$store.state.placeorder.err != '') ? this.$store.state.placeorder.err : 'ERROR_SMS_KEY';
            }
          }, 5000)
          
        }
        }
      
      } else {
        this.$store.dispatch('setCurrentNextDays', index)
      }
    },
  checkRequireInputs() {
    /*
    console.log({
        'type': 'name',
        'name': this.name.length,
        'name_err': this.$v.name.$error,
        'inr': this.inr.length,
        'inr_err': this.$v.inr.$error,
        'invalid': this.$v.$invalid,
        'name_errors': this.$v.name.$errors,
        'inr_errors': this.$v.inr.$errors,
        'all_inr': this.$v.inr,
        'all_name': this.$v.name,
        'email': (this.isTimeRequestSelected && (this.$v.email.$error || (!this.$v.email.required && this.$v.email.$model.length == 0)))
      })
    */
  }
  },
  filters: {
    moment: date => {
      return moment(date).format('DD.MM.YYYY')
    },
  },
  watch: {
    name: function() {
      this.checkRequireInputs();
    },
    inr: function() {
      this.checkRequireInputs();
    },
    email: function() {
      this.checkRequireInputs();
    },
    prereservedModal(newValue) {
    if (newValue) {
      this.modalError.text = newValue;
      this.modalError.show = true;
    } else {
      this.modalError.text = '';
      this.modalError.show = false;
    }
  }
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail {
  .no-services {
    color: red;
    text-align: center;
    margin-bottom: 0;
  }

  .service-options {
    .col-6 {
      margin-bottom: 15px;

      .btn {
        width: 100%;
        height: 100%;
        padding: 35px 10px !important;
        font-size: 18px !important;
        text-decoration: none !important;

        &.time-btn {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .service-clock {
            height: 23px;
            margin: 0 0 5px !important;
          }

          .service-video {
            height: 30px;
            margin: 0 !important;
          }
        }

        &:hover {
          background: #22376c !important;
          border-color: #22376c !important;
          color: white !important;
        }
      }

      &:nth-child(odd) {
        padding-right: 5px !important;
      }

      &:nth-child(even) {
        padding-left: 5px !important;
      }
    }
  }

  .terms {
    height: calc(100vh - 500px);
    overflow: scroll;

    .date {
      margin-top: -1em !important;
    }
  }

  .wrapper-collapse {
    display: block;
    width: 100%;
    padding: 0.475rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    a:hover {
      text-decoration: none !important;
    }

    &.collapsed {
      background-color: #eef7fc !important;
    }
  }
}
</style>
<style
  src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
