<template>
  <b-card
    border-variant="primary"
    body-bg-variant="primary"
    body-text-variant="dark"
  >
    <b-img
      class="clock float-left mr-3"
      :src="path + clock"
      alt="enter"
    ></b-img>
    <h3 class="text-18 semi-bold work-hours-title">{{ $t('work_hours') }}</h3>
    <ContentLoader v-if="$store.getters.lazy" height="50">
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </ContentLoader>
    <p
      class="work-hours text-16 mb-0"
      style="white-space: pre-wrap; word-wrap: break-word;"
    v-html="$store.getters.doctor.workhours"
      >
    </p>
  </b-card>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import config from '../../config'
import clock from '../assets/old/clock.svg'
export default {
  data() {
    return {
      path: config.URL,
      clock: clock,
    }
  },
  components: {
    ContentLoader,
  },
}
</script>

<style scoped lang="scss">
.clock {
  margin-top: 4px;
}
.work-hours {
  font-family: 'Courier New', Courier, monospace;
}
</style>
