<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop">
        <b-button
          id="btn-back"
          variant="link"
          class="po-left"
          v-on:click="$router.push({ name: 'other_services' })"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center title-margin">
          <!--					<b-img :src="path + shop" alt="e-shop"></b-img>-->
          <p class="text-18 semi-bold mt-2">
            {{ $t('shop_title') }}
          </p>
        </div>

        <div class="cart-link" @click="$router.push({ name: 'shop_cart' })">
          <b-img class="ml-2" :src="path + shopping_cart" alt="cart"></b-img>
          <span
            v-if="$store.getters.shopCart[$store.getters.name]"
            class="count"
            >{{ $store.getters.shopCart[$store.getters.name].length }}</span
          >
        </div>

        <hr />

        <div class="shop-home">
          <b-card
            v-if="!$store.getters.shopCartPrefill"
            class="cart-prefill"
            border-variant="primary"
            no-body
          >
            <b-card-body body-bg-variant="primary">
              <div class="body-wrapper">
                <img
                  :src="path + question_circle"
                  class="question-icon"
                  alt="info"
                />
                <span class="cart-prefill-title">{{
                  $t('shop_cart_prefill')
                }}</span>
                <b-form @submit.prevent="cartPrefill" class="cart-prefill-form">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('shop_cart_prefill_input')"
                      type="text"
                      :state="cartIdError === '' ? null : false"
                      v-model.trim.lazy="$v.cartId.$model"
                      aria-describedby="cart_id_invalid"
                    >
                    </b-form-input>

                    <b-input-group-append>
                      <b-button type="submit" variant="secondary">
                        {{ $t('send') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback
                    v-if="cartIdError !== ''"
                    id="cart_id_invalid"
                    :style="cartIdError !== '' ? 'display: flex;' : ''"
                  >
                    {{ cartIdError }}
                  </b-form-invalid-feedback>
                </b-form>
              </div>
            </b-card-body>
          </b-card>

          <b-form @submit.prevent="" class="search-form">
            <b-form-input
              id="search"
              type="text"
              :placeholder="$t('shop_search')"
              v-model.trim.lazy="$v.search.$model"
            >
            </b-form-input>
            <b-img
              class="mr-2"
              :src="path + loupe_small"
              alt="loupe_small"
            ></b-img>
          </b-form>

          <SearchList v-if="search" :query="search" />

          <div v-if="!search && loading" class="text-center my-4">
            <b-spinner
              variant="secondary"
              :label="$t('shop_loading')"
            ></b-spinner>
          </div>

          <CategoryList v-if="!search" :data="allCategories" class="mt-3" />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../../config'
import { maxLength, minLength } from 'vuelidate/lib/validators'
import angle_left_small from '../../../../assets/old/angle_left_small.svg'
import angle_right from '../../../../assets/old/angle_right.svg'
import loupe_small from '../../../../assets/old/loupe_small.svg'
import shopping_cart from '../../../../assets/icons/shopping_cart2.svg'
import question_circle from '../../../../assets/icons/question_circle.svg'

import CategoryList from './list/CategoryList'
import SearchList from './list/SearchList'

export default {
  components: {
    CategoryList,
    SearchList,
  },
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      loupe_small: loupe_small,
      shopping_cart: shopping_cart,
      question_circle: question_circle,
      meta: {
        title: 'eČasenka.sk',
        description: '',
      },
      search: '',
      loading: false,
      ready: true,
      mainCategoryId: null,
      allCategories: [],

      cartId: '',
      cartIdError: '',
    }
  },
  validations: {
    search: {
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    cartId: {
      minLength: minLength(1),
      maxLength: maxLength(512),
    },
  },
  metaInfo() {
    return {
      title:
        this.$t('shop_title') +
        ' | ' +
        this.$store.getters.doctor.name +
        ' | eČasenka.sk',
      description: this.meta.description,
    }
  },
  created() {
    this.getAllCategories()
  },
  mounted() {
    this.$emit('setSubtitle', '')

    // this.meta['title'] = this.$t('shop_title') + ' | ' + this.$store.getters.doctor.name + ' | eČasenka.sk'
    // this.$store.getters.doctor ? this.meta['title'] = this.$t('shop_title') + ' | ' + this.$store.getters.doctor.name + ' | eČasenka.sk' : '';
  },
  watch: {
    '$store.getters.doctor': {
      handler: function(newValue) {
        if (newValue.name) {
          this.meta['title'] =
            this.$t('shop_title') + ' | ' + newValue.name + ' | eČasenka.sk'
        }
      },
    },
  },
  methods: {
    getAllCategories() {
      this.loading = true

      axios
        .get(config.ESHOP_API + '/categories/' + this.$store.getters.name)
        .then(response => {
          // console.warn(response);
          this.loading = false

          this.allCategories = response.data.categories
        })
        .catch(() => {
          this.loading = false
        })
    },

    cartPrefill() {
      if (!this.cartId) {
        return
      }

      this.loading = true

      axios
        .get(
          config.ESHOP_API +
            '/cart/' +
            this.$store.getters.name +
            '/' +
            this.cartId
        )
        .then(
          response => {
            // console.warn(response);
            this.loading = false

            let code = response.data.code
            let products = response.data.data
            if (code !== 404 && products.length > 0) {
              this.cartIdError = ''
              this.$store.dispatch('setShopCartPrefill', true)

              products.forEach(item => {
                this.$store.dispatch('addToShopCart', {
                  product: item,
                  doctor: this.$store.getters.name,
                })
              })

              this.$router.push({ name: 'shop_cart' })
            } else {
              this.cartIdError = response.data.message
            }
          },
          () => {
            // console.warn(err);
            this.loading = false
            this.cartIdError = this.$t('shop_order_invalid')
          }
        )
    },
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail {
  .shop-home::v-deep {
    .search-form {
      position: relative;
      margin-bottom: 15px;

      input {
        padding-right: 40px !important;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
      }
    }

    .cart-prefill {
      margin-bottom: 15px;

      .card-body {
        padding: 10px;

        .body-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          margin-top: -10px;

          > * {
            margin-top: 10px;
          }
        }

        .question-icon {
          height: 20px;
          width: 20px;
          object-fit: contain;
          margin-right: 7px;
        }

        .cart-prefill-title {
          margin-right: 10px;
          font-size: 14px;
        }

        .cart-prefill-form {
          display: inline-flex;
          flex-direction: column;

          input {
            width: auto;
            height: auto;
            padding: 5px 10px;
            font-size: 14px;
          }

          button {
            font-size: 14px;
          }

          .invalid-feedback {
            width: auto;
            font-size: 11px;
            margin-top: 1px;
          }

          @media (max-width: 992px) {
            width: 100%;

            input {
              width: auto;
            }
          }

          @media (max-width: 1200px) {
            input {
              width: 100px;
            }
          }
          @media (min-width: 1200px) {
            input {
              min-width: 190px;
            }
          }
        }
      }
    }
  }
}
</style>
