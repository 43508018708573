import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../../config'
import createPersistedState from 'vuex-persistedstate'
import { CheckApiUrl } from '../modules/CheckApiUrlModule'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['shopCart', 'shopCartPrefill'],
      storage: window.localStorage,
    }),
  ],
  state: {
    lazy: true,
    doctor: {},
    currentservice: 0,
    currenttoday: 0,
    currenttodaynumber: 0,
    currenttodaycalling: 0,
    currenttodaynocalling: 0,
    currentnextdays: 0,
    currentrecipe: 0,
    currentresults: 0,
    currentenquiry: 0,
    currentshop: 0,
    customer: {},
    today: {},
    nextdays: {},
    recipe: {},
    torecipe: {},
    results: {},
    toresults: {},
    enquiry: {},
    placeorder: {},
    type: '',
    service: null,
    error: '',
    orderstatus: {},
    name: '',
    maxheight: false,
    isTimeRequest: false,
    shopMenu: 0,
    shopCategory: 0,
    shopCategoryParent: 0,
    shopCart: {},
    shopCartPrefill: false,
    shopOrder: null,
    showQrCodeRecipe: true,
    showQrCodeRecipeMessage: '',
    prereservationId: null,
    prereservedModal: null,
    todayPrereservationId: null,
  },
  mutations: {
    setDoctor(state, data) {
      state.doctor = data
    },
    setLazy(state, data) {
      state.lazy = data
    },
    setCurrentService(state, data) {
      state.currentservice = data
    },
    setCurrentToday(state, data) {
      state.currenttoday = data
    },
    setCurrentTodayNumber(state, data) {
      state.currenttodaynumber = data
    },
    setCurrentTodayCalling(state, data) {
      state.currenttodaycalling = data
    },
    setCurrentTodayNoCalling(state, data) {
      state.currenttodaynocalling = data
    },
    setCurrentNextDays(state, data) {
      state.currentnextdays = data
    },
    setCurrentRecipe(state, data) {
      state.currentrecipe = data
    },
    setCustomer(state, data) {
      delete data.aid;
      delete data.consent;
      delete data.date;
      delete data.infoservice;
      delete data.nr;
      delete data.prereservation_id;
      delete data.type;
      delete data.prereservation_id;
      state.customer = data;
    },  
    setCurrentResults(state, data) {
      state.currentresults = data
    },
    setCurrentEnquiry(state, data) {
      state.currentenquiry = data
    },
    setCurrentShop(state, data) {
      state.currentshop = data
    },
    setToday(state, data) {
      state.today = data
    },
    setNextDays(state, data) {
      state.nextdays = data
    },
    setRecipe(state, data) {
      state.recipe = data
    },
    setToRecipe(state, data) {
      state.torecipe = data
    },
    setResults(state, data) {
      state.results = data
    },
    setToResults(state, data) {
      state.toresults = data
    },
    setEnquiry(state, data) {
      state.enquiry = data
    },
    setPlaceOrder(state, data) {
      state.placeorder = data
    },
    setType(state, data) {
      state.type = data
    },
    setService(state, data) {
      state.service = data
    },
    setError(state, data) {
      state.error = data
    },
    setOrderStatus(state, data) {
      state.orderstatus = data
    },
    setName(state, data) {
      state.name = data
    },
    setMaxHeight(state, data) {
      state.maxheight = data
    },
    setTimeRequest(state, data) {
      state.isTimeRequest = data
    },
    setShopMenu(state, data) {
      state.shopMenu = data
    },
    setShopCategory(state, data) {
      state.shopCategory = data
    },
    setShopCategoryParent(state, data) {
      state.shopCategoryParent = data
    },
    setShopCart(state, data) {
      state.shopCart = data
    },
    setShopCartPrefill(state, data) {
      state.shopCartPrefill = data
    },
    setShopOrder(state, data) {
      state.shopOrder = data
    },
    setShowQrCodeRecipe(state, data) {
      state.showQrCodeRecipe = data;
    },
    setShowQrCodeRecipeMessage(state, data) {
      state.showQrCodeRecipeMessage = data;
    },
    setPrereservationId(state, data){
      state.prereservationId = data;
    },
    setPrereservedModal(state, data){
      state.prereservedModal = data;
    },
    setTodayPrereservationId(state, data){
      state.todayPrereservationId = data;
    },
  },
  getters: {
    doctor: state => {
      /* Sanitize workhours for new solusion */
      if (CheckApiUrl.is_new_api_doctor()) {
        const slovakDayNames = {
          MONDAY: 'Pondelok',
          TUESDAY: 'Utorok',
          WEDNESDAY: 'Streda',
          THURSDAY: 'Štvrtok',
          FRIDAY: 'Piatok',
        }

        let workhours = state.doctor.workhours
        let workhours_string = ''
        if (state.doctor.workhours != undefined) {
          workhours.forEach(item => {
            if (item != undefined) {
              workhours_string +=
                '<div class="row"><div class="col">' +
                slovakDayNames[item.day] +
                '</div>'
              item.timeRanges.forEach(time => {
                if (time != undefined) {
                  workhours_string +=
                    '<div class="col">' +
                    time.timeFrom +
                    ' - ' +
                    time.timeTo +
                    '</div>'
                }
              })
              workhours_string += '</div>'
            }
          })
        }
        state.doctor.workhours = workhours_string
      }
      
      return state.doctor
    },
    lazy: state => {
      return state.lazy
    },
    customer: state => {
      return state.customer
    },
    currentService: state => {
      return state.currentservice
    },
    currentToday: state => {
      return state.currenttoday
    },
    currentTodayNumber: state => {
      return state.currenttodaynumber
    },
    currentTodayCalling: state => {
      return state.currenttodaycalling
    },
    currentTodayNoCalling: state => {
      return state.currenttodaynocalling
    },
    currentNextDays: state => {
      return state.currentnextdays
    },
    currentRecipe: state => {
      return state.currentrecipe
    },
    currentResults: state => {
      return state.currentresults
    },
    currentEnquiry: state => {
      return state.currentenquiry
    },
    currentShop: state => {
      return state.currentshop
    },
    today: state => {
    /*  if (!CheckApiUrl.is_new_api_doctor()) {
        if(state.today.entryallowed == true){
        if (state.today.entrynote != undefined) {
          state.today.entrynote =
            'Vydávanie časeniek na dnes je nastavené od ' +
            state.today.start +
            ' do ' +
            state.today.end +
            '. Môžete sa zaradiť do poradia.'
        } else {
          state.today.entrynote = ''
        }
      } else{
        state.today.entrynote = 'Vydávanie časeniek na dnes je už zastavené. Objednajte sa na vyšetrenie na iný deň, alebo sa skúste zaradiť do poradia zajtra.'
      }
    }
    */
      return state.today
    },
    nextDays: state => {
      return state.nextdays
    },
    recipe: state => {
      return state.recipe
    },
    torecipe: state => {
      return state.torecipe
    },
    results: state => {
      return state.results
    },
    toresults: state => {
      return state.toresults
    },
    enquiry: state => {
      return state.enquiry
    },
    placeOrder: state => {
      return state.placeorder
    },
    type: state => {
      return state.type
    },
    service: state => {
      return state.service
    },
    error: state => {
      return state.error
    },
    orderStatus: state => {
      return state.orderstatus
    },
    name: state => {
      return state.name
    },
    maxHeight: state => {
      return state.maxheight
    },
    isTimeRequest: state => {
      return state.isTimeRequest
    },
    shopMenu: state => {
      return state.shopMenu
    },
    shopCategory: state => {
      return state.shopCategory
    },
    shopCategoryParent: state => {
      return state.shopCategoryParent
    },
    shopCart: state => {
      return state.shopCart
    },
    shopCartPrefill: state => {
      return state.shopCartPrefill
    },
    shopOrder: state => {
      return state.shopOrder
    },
    showQrCodeRecipe: state => {
      state.showQrCodeRecipe
    },
    showQrCodeRecipeMessage: state => {
      state.showQrCodeRecipeMessage
    },
    prereservationId: state =>{
      return state.prereservationId
    },
    prereservedModal: state =>{
      return state.prereservedModal
    },
    todayPrereservationId: state =>{
      return state.todayPrereservationId
    },
  },
  actions: {
    setPrereservationId: ({ commit }, data) => {
      commit('setPrereservationId', data);
    },
    setTodayPrereservationId: async ({ commit }, data) => {
      await commit('setTodayPrereservationId', null)
      await commit('setPrereservedModal', null)

      await axios
        .post(config.API + '/prereserve/' + data.name, {
          onday: new Date().toISOString().substring(0, 10),
          appnumber: (data.number || null),
          //appnumber: null,
        },
        {
          validateStatus: function (status) {
            return status < 500;
          }
        })
        .then(response => {
          if (response.status === 400) {
              commit('setTodayPrereservationId', null)
              commit('setPrereservedModal', response.data.detail);      
          } else {
            commit('setTodayPrereservationId', response.data)
              commit('setPrereservedModal', null);
          }
        })
        .catch(error => {
          if(error.response.status == 400){
            commit('setPrereservedModal', error.response.data.detail);
          } else {
            commit('setPrereservedModal', null);
          }
        });

    },
    setCurrentService: ({ commit }, data) => {
      commit('setCurrentService', data)
    },
    setCurrentToday: ({ commit }, data) => {
      commit('setCurrentToday', data)
    },
    setCurrentTodayNumber: ({ commit }, data) => {
      commit('setCurrentTodayNumber', data)
    },
    setCurrentTodayCalling: ({ commit }, data) => {
      commit('setCurrentTodayCalling', data)
    },
    setCurrentTodayNoCalling: ({ commit }, data) => {
      commit('setCurrentTodayNoCalling', data)
    },
    setCurrentNextDays: ({ commit }, data) => {
      commit('setCurrentNextDays', data)
    },
    setCurrentRecipe: ({ commit }, data) => {
      commit('setCurrentRecipe', data)
    },
    setCurrentResults: ({ commit }, data) => {
      commit('setCurrentResults', data)
    },
    setCurrentEnquiry: ({ commit }, data) => {
      commit('setCurrentEnquiry', data)
    },
    setCurrentShop: ({ commit }, data) => {
      commit('setCurrentShop', data)
    },
    setServiceNextDays: ({ commit }, data) => {
      commit('setService', data)
    },
    setDoctor: ({ commit }, data) => {
      commit('setDoctor', data)
    },
    setCustomer: ({ commit }, data) => {
      commit('setCustomer', data)
    },
    setToday: ({ commit }, data) => {
      commit('setToday', data)
    },
    setNextDays: ({ commit }, data) => {
      commit('setNextDays', data)
    },
    setTimeRequest: ({ commit }, data) => {
      commit('setTimeRequest', data)
    },
    setShopMenu: ({ commit }, data) => {
      commit('setShopMenu', data)
    },
    setShopCategory: ({ commit }, data) => {
      commit('setShopCategory', data)
    },
    setShopCategoryParent: ({ commit }, data) => {
      commit('setShopCategoryParent', data)
    },
    setShopCart: ({ commit }, data) => {
      commit('setShopCart', data)
    },
    setPrereservedModal: ({ commit }, data) => {
      commit('setPrereservedModal', data)
    },
    addToShopCart: ({ commit, state }, data) => {
      // console.warn(commit, state, data);

      let duplicate = false

      if (!state.shopCart[data.doctor]) {
        state.shopCart[data.doctor] = []
      }

      if (state.shopCart[data.doctor].length > 0) {
        state.shopCart[data.doctor].forEach((item, index) => {
          // console.warn(item, data);

          if (item.id === data.product.id) {
            duplicate = true

            state.shopCart[data.doctor][index].count = item.count + 1
            commit('setShopCart', state.shopCart)
          } else if (
            !duplicate &&
            index + 1 === state.shopCart[data.doctor].length
          ) {
            data.product['count'] = 1

            state.shopCart[data.doctor].push(data.product)
            commit('setShopCart', state.shopCart)
          }
        })
      } else {
        data.product['count'] = 1

        state.shopCart[data.doctor].push(data.product)
        commit('setShopCart', state.shopCart)
      }
    },
    setShopCartPrefill: ({ commit }, data) => {
      commit('setShopCartPrefill', data)
    },
    setShopOrder: ({ commit }, data) => {
      commit('setShopOrder', data)
    },
    getRecipe: ({ commit, state }) => {
      let url = CheckApiUrl.check('/recipe/' + state.name)
      axios.get(url).then(response => {
        if(CheckApiUrl.is_new_api_doctor()){
        let rdata = {}
        //rdata.infoserviceprice = (response.data.services.recipe.price || 0)
        rdata.infoserviceprice =(parseFloat(response.data.services.recipe.price).toFixed(2).toString().replace('.', ',') || "0,00") + ' €'
        rdata.enabled = (response.data.services.recipe.availability || false) 
        response.data = rdata
        }
        commit('setRecipe', response.data)
      })
    },
    getResults: ({ commit, state }) => {
      let url = CheckApiUrl.check('/result/' + state.name)
      axios.get(url).then(response => {
        commit('setResults', response.data)
      })
    },
    placeOrder: ({ commit, state }, data) => {
      // console.warn(data);
      let type = data.type
      const service = data.infoservice
      const torecipe = {
        name: data.name,
        inr: data.pnr,
        medicine: data.medicine,
      }
      const toresults = {
        name: data.name,
        inr: data.pnr,
      }

      let url = CheckApiUrl.check('/placeorder/' + state.name, 'placeorder')
      let config = {}

      if(CheckApiUrl.is_new_api_doctor()){
      const encodedCredentials = btoa(`${process.env.VUE_APP_PLACEORDER_NEW_API_USER}:${process.env.VUE_APP_PLACEORDER_NEW_API_PASSWORD}`);
      const basicAuthHeader = `Basic ${encodedCredentials}`;
      config = { 
        headers: {
        Authorization: basicAuthHeader
      }
      }
      }

      // next days order set payload prereservation_id
      if(state.prereservationId){
      data.prereservation_id = state.prereservationId;
      }

      // today order set payload prereservation_id
      if(state.todayPrereservationId){
        data.prereservation_id = state.todayPrereservationId;
      }

      // Reset setPlaceOrder Object before post request
      commit('setPlaceOrder', { "sms_code": "...", "order_id": null, "order_key": null, "err": "" })

      axios
        .post(url, CheckApiUrl.sanitise_post_request(data, 'placeorder'), config)
        .then(response => {

          if(response.data.type == 'MEDICAL_PRESCRIPTION_REQUEST'){
            response.data.type = "recipe"
            type = "recipe"
            response.data.err = ""
            response.data.order_id = response.data.accessToken
            response.data.sms_code = response.data.sms.code
            response.data.sms_number = response.data.sms.number.toString()
            response.data.sms_keyword = response.data.sms.keyword
            response.data.order_key = response.data.orderKey
          }

          /* TESTING RESPONSE */
          /* 
          response.data.order_id = null
          response.data.sms_code = null
          response.data.err = ""
          response.data.order_key = null
          */

          //console.log(response)
          if(response.status && response.status == 200){
            commit('setCustomer', {});
          }

          commit('setPlaceOrder', response.data)
          commit('setType', type)
          commit('setService', service)

          if (type == 'recipe') {
            commit('setToRecipe', torecipe)
          }
          if (type == 'result') {
            commit('setToResults', toresults)
          }

          if(response.status == 400){
            commit('setPrereservedModal', response.data.detail);
          } else {
            commit('setPrereservedModal', null);
          }
          // if (type == "enquiry") {
          //   let logger = Vue.prototype.$raven;
          //   logger.captureBreadcrumb({category: "enquiry", data: [data, response], level: 'warning'});
          //   logger.captureException('Enquiry send');
          // }
        }).catch(error => {
          if(error.response.status == 400){
            commit('setPrereservedModal', error.response.data.detail);
          } else {
            commit('setPrereservedModal', null);
          }

          if (error.response.status == 409) {
            commit('setToRecipe', torecipe)
            commit('setShowQrCodeRecipe', false)
            commit(
              'setShowQrCodeRecipeMessage',
              '<div style="width:auto; margin:auto;" class="alert alert-danger d-inline-flex align-items-center text-center" role="alert">' +
              '<svg style="width:24px; height:24px; margin-right:10px;" class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/></svg><div>' +
                error.response.data.messages[0].message +
              '</div></div>'
            )
          }
        })
    },
    postToday({ commit, state }) {
      axios
        .post(
          config.API + '/today/' + state.name,
          JSON.stringify({
            order_id: state.placeorder.order_id,
            order_key: state.placeorder.order_key,
          })
        )
        .then(response => {
          commit('setToday', response.data)
        })
    },
    getOrderStatus: ({ commit, state }) => {
      axios
        .post(
          config.API + '/orderstatus/' + state.name,
          JSON.stringify({
            order_id: state.placeorder.order_id,
            order_key: state.placeorder.order_key,
          })
        )
        .then(response => {
          commit('setOrderStatus', response.data)
        })
    },
    checkBack: () => {
      let back = document.getElementById('btn-back')
      if (typeof back != 'undefined' && back != null) {
        window.history.pushState(null, '', window.location.href)
        window.onpopstate = function() {
          back.click()
          window.history.pushState(null, '', window.location.href)
        }
      } else {
        window.onpopstate = function() {
          window.location.href = config.URLHOME
        }
      }
    },
  },
})
