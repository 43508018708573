<template>
  <div class="product-list">
    <div v-if="data.length > 0">
      <b-card
        v-for="product in data"
        :key="product.id"
        class="product-list-item overflow-hidden"
      >
        <!--                <p class="sku text-14">-->
        <!--                    #{{ product.sku }}-->
        <!--                </p>-->
        <b-row>
          <b-col sm="12">
            <p class="product-title text-18 semi-bold mb-1">
              {{ product.title }}
              <!--							<small class="d-block text-12 text-muted">#{{ product.sku }}</small>-->
            </p>

            <div
              v-html="getDesc(product.description)"
              class="text-14 mb-2"
            ></div>

            <p class="price">
              <!--                            <span class="old text-14 mr-2">{{ product.prices[0].price_with_tax_before_discount }} €</span>-->
              <!--                            <span class="new text-18 semi-bold">{{ product.prices[0].price_with_tax_after_discount }} €</span>-->
              <span
                v-if="
                  product.prices[0].price_with_tax_before_discount !==
                    product.prices[0].price_with_tax_after_discount
                "
                class="old text-14 mr-2"
                >{{ product.prices[0].price_with_tax_before_discount }} €</span
              >

              <span
                v-if="
                  product.prices[0].price_with_tax_before_discount !==
                    product.prices[0].price_with_tax_after_discount
                "
                class="new text-18 semi-bold"
                >{{ product.prices[0].price_with_tax_after_discount }} €</span
              >

              <span v-else class="text-18 semi-bold"
                >{{ product.prices[0].price_with_tax_after_discount }} €</span
              >
            </p>
          </b-col>
        </b-row>

        <b-row class="bottom-buttons" no-gutters>
          <b-col sm="3">
            <b-button @click="toDetail(product.alias)" variant="secondary">
              <!--							<b-img-->
              <!--								class="mr-2"-->
              <!--								:src="path + list"-->
              <!--								alt="list"-->
              <!--							></b-img>-->
              {{ $t('shop_btn_detail') }}
            </b-button>
          </b-col>

          <b-col sm="9">
            <b-button
              @click="toCheckout(product)"
              class="order"
              variant="success"
            >
              {{ $t('shop_btn_order') }}
              <b-img class="ml-2" :src="path + cart_white" alt="cart"></b-img>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <!--        <div v-if="!data && !search">-->
    <!--            <p class="text-16 text-center">{{ $t('shop_category_empty') }}</p>-->
    <!--        </div>-->
  </div>
</template>

<script>
import config from '../../../../../../config'

import list from '../../../../../assets/old/list.svg'
import cart_white from '../../../../../assets/icons/shopping_cart_white.svg'

export default {
  data() {
    return {
      path: config.URL,
      list: list,
      cart_white: cart_white,
    }
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  methods: {
    getDesc(value) {
      if (value) {
        return value.replace(/(<([^>]+)>)/gi, '').slice(0, 60)
      }
    },
    toDetail(slug) {
      // this.$store.dispatch('setShopProduct', id);
      // this.$store.dispatch('setCurrentShop', 2);

      this.$router.push({
        name: 'shop_product',
        params: { product_slug: slug },
      })
    },
    toCheckout(product) {
      this.$store.dispatch('addToShopCart', {
        product: product,
        doctor: this.$store.getters.name,
      })
      // this.$store.dispatch('setCurrentShop', 3);
      this.$router.push({ name: 'shop_cart' })
    },
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail .product-list {
  .product-list-item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .card-body {
      position: relative;

      //.sku {
      //    position: absolute;
      //    top: 3px;
      //    right: 3px;
      //}

      .product-title {
        margin-right: 120px;
      }

      .price {
        position: absolute;
        top: 0;
        right: 15px;
        .old {
          text-decoration: line-through;
          color: red;
        }
      }

      .bottom-buttons {
        //position: absolute;
        //bottom: 0;
        //left: 0;
        //width: 100%;
        margin-top: 10px;

        > div:nth-child(2) {
          padding-left: 20px;

          @media (max-width: 576px) {
            padding-top: 10px;
            padding-left: 0;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          width: 100%;

          &.order {
            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
