var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list"},[(_vm.data.length > 0)?_c('div',_vm._l((_vm.data),function(product){return _c('b-card',{key:product.id,staticClass:"product-list-item overflow-hidden"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('p',{staticClass:"product-title text-18 semi-bold mb-1"},[_vm._v(" "+_vm._s(product.title)+" ")]),_c('div',{staticClass:"text-14 mb-2",domProps:{"innerHTML":_vm._s(_vm.getDesc(product.description))}}),_c('p',{staticClass:"price"},[(
                product.prices[0].price_with_tax_before_discount !==
                  product.prices[0].price_with_tax_after_discount
              )?_c('span',{staticClass:"old text-14 mr-2"},[_vm._v(_vm._s(product.prices[0].price_with_tax_before_discount)+" €")]):_vm._e(),(
                product.prices[0].price_with_tax_before_discount !==
                  product.prices[0].price_with_tax_after_discount
              )?_c('span',{staticClass:"new text-18 semi-bold"},[_vm._v(_vm._s(product.prices[0].price_with_tax_after_discount)+" €")]):_c('span',{staticClass:"text-18 semi-bold"},[_vm._v(_vm._s(product.prices[0].price_with_tax_after_discount)+" €")])])])],1),_c('b-row',{staticClass:"bottom-buttons",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"3"}},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.toDetail(product.alias)}}},[_vm._v(" "+_vm._s(_vm.$t('shop_btn_detail'))+" ")])],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-button',{staticClass:"order",attrs:{"variant":"success"},on:{"click":function($event){return _vm.toCheckout(product)}}},[_vm._v(" "+_vm._s(_vm.$t('shop_btn_order'))+" "),_c('b-img',{staticClass:"ml-2",attrs:{"src":_vm.path + _vm.cart_white,"alt":"cart"}})],1)],1)],1)],1)}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }