<template>
  <div>
    <!--    <InfoAboutDoctor />-->

    <!--    <Notice v-if="$store.getters.currentService == 0" />-->
    <Notice v-if="$route.name === 'home'" />
    <!--  	<Info />-->
    <router-view></router-view>
    <!--    <ListServices v-if="$store.getters.currentService == 0" />-->

    <!--    <ServiceToday v-if="$store.getters.currentService == 1" />-->
    <!--    <ServiceNextDays v-if="$store.getters.currentService == 2" />-->

    <!--    <ServiceRecipe v-if="$store.getters.currentService == 3" />-->
    <!--    <ServiceResults v-if="$store.getters.currentService == 4" />-->
    <!--    <ServiceEnquiry v-if="$store.getters.currentService == 5" />-->

    <!--    <ListNumberOrder v-if="$store.getters.currentService == 6" />-->
    <!--    <ListOtherServices v-if="$store.getters.currentService == 7" />-->

    <!--    <ServiceShop v-if="$store.getters.currentService == 8" />-->
  </div>
</template>

<script>
import axios from 'axios'
import Notice from './Notice'
import { CheckApiUrl } from '../modules/CheckApiUrlModule';
// import Info from "./Info";

// import InfoAboutDoctor from "./InfoAboutDoctor";
// import ListServices from "./services/ListServices";
// import ListNumberOrder from "./services/ListNumberOrder";
// import ListOtherServices from "./services/ListOtherServices";
// import ServiceEnquiry from "./services/ServiceEnquiry";
// import ServiceToday from "./services/number_order/ServiceToday";
// import ServiceNextDays from "./services/number_order/ServiceNextDays";
// import ServiceRecipe from "./services/other_services/ServiceRecipe";
// import ServiceResults from "./services/other_services/ServiceResults";
// import ServiceShop from "./services/other_services/ServiceShop";

export default {
  components: {
    Notice,
    // Info,
    // InfoAboutDoctor,
    // ListServices,
    // ListNumberOrder,
    // ListOtherServices,
    // ServiceEnquiry,
    // ServiceToday,
    // ServiceNextDays,
    // ServiceRecipe,
    // ServiceResults,
    // ServiceShop
  },
  updated() {
    this.$store.dispatch('checkBack')
  },
  mounted() {
    this.getDoctor()
  },
  methods: {
    getDoctor() {

      let url = CheckApiUrl.check('/index/' + this.$store.getters.name)

      axios
        .get(url)
        .then(response => {
          this.$store.dispatch('setDoctor', response.data)
          this.$store.commit('setLazy', false)
        })
    },
  },
}
</script>
