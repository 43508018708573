var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.getters.placeOrder.sms_code !== '...')?_c('b-card',{staticClass:"text-22 text-primary",attrs:{"border-variant":"primary","body-bg-variant":"primary"}},[_c('p',{staticClass:"semi-bold mb-0"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$store.getters.placeOrder.sms_keyword))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('space'))+" ")]),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$store.getters.placeOrder.sms_code))])]),_c('p',{staticClass:"semi-bold mb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t('to_number'))+" ")]),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$store.getters.placeOrder.sms_number))])]),_c('p',{staticClass:"d-lg-block d-none text-14 semi-bold mt-3"},[_vm._v(" "+_vm._s(_vm.$t('scan_qr'))+" ")]),_c('span',{staticClass:"d-lg-block d-none"},[_c('qrcode',{attrs:{"value":'SMSTO:' +
            _vm.$store.getters.placeOrder.sms_number +
            ':' +
            _vm.$store.getters.placeOrder.sms_keyword +
            ' ' +
            _vm.$store.getters.placeOrder.sms_code,"options":{ width: 220, color: { light: '#eef7fc' } }}})],1)]):_c('Preloader'),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTimeSelected),expression:"!isTimeSelected"}],staticClass:"text-12 semi-bold mt-3"},[_vm._v(" "+_vm._s(_vm.$t('price_sms', [_vm.price]))+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTimeSelected),expression:"isTimeSelected"}],staticClass:"text-12 semi-bold mt-3"},[_vm._v(" "+_vm._s(_vm.$t('time_price_sms', [_vm.time_price]))+" ")]),(_vm.$store.getters.placeOrder.sms_code !== '...')?_c('b-link',{staticClass:"d-inline-block d-lg-none btn btn-secondary btn-block btn-lg mb-2",attrs:{"href":'sms:' +
        _vm.$store.getters.placeOrder.sms_number +
        _vm.type +
        'body=' +
        _vm.$store.getters.placeOrder.sms_keyword +
        ' ' +
        _vm.$store.getters.placeOrder.sms_code}},[_vm._v(_vm._s(_vm.$t('send_message'))),_c('b-img',{staticClass:"po-right",attrs:{"src":_vm.path + _vm.angle_right,"alt":"order_recipe"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }