<template>
  <li>
    <div v-if="show_spinner == true">
      <Preloader />
    </div>
    <div
    v-if="$store.getters.doctor.services.enquiry && show_button == true"
    class="d-flex align-items-center mt-2 px-3 py-2 service enquiry"
    v-on:click="ButtonClick"
  >
    <figure class="d-flex justify-content-center align-items-center mr-2 mb-0">
      <b-img :src="path + enquiry" alt="dotazniky"></b-img>
    </figure>
    <div class="mr-2">
      <p class="text-22 semi-bold text-white mb-0">
        {{ button_values.btntitle }}
      </p>
      <p class="text-price-light mb-0">
        {{ $t('service_price', [button.infoserviceprice]) }}
      </p>
    </div>
    <b-img class="position-right" :src="path + angle_right" alt="enter"></b-img>
    </div>
  </li>
</template>
<script>
import config from '../../config'
import axios from 'axios'
import angle_right from '../assets/old/angle_right.svg'
import enquiry from '../assets/icons/enquiry.svg'
import Preloader from './Preloader.vue'
import { CheckApiUrl } from '../modules/CheckApiUrlModule'

export default {
  data() {
    return {
      path: config.URL,
      angle_right: angle_right,
      enquiry: enquiry,
      show_button: false,
      show_spinner: true,
      button: {},
      button_values: {},
      show_description: false,
    }
  },
  components: {
    Preloader,
  },
  props: {
    enqurityname: {
      type: String,
      default: 'DR-MSG',
    },
    enquiries: {
      type: Object,
      default: ()=>{ return {}; },
    },
  },
  methods: {
    getDrmsg() {
      if(CheckApiUrl.is_new_api_doctor() == true){ 
        this.show_button = false
        this.show_spinner = false
        return; }
      axios
        .get(
          config.API +
            '/enquiry_v2/' +
            this.$store.getters.name +
            '/detail/' +
            this.enqurityname
        )
        .then(response => {
          axios.get(response.config.url).then(resp => {
            
            this.button = resp.data
            if(this.button.enquiries[this.enqurityname]){
            this.button_values = this.button.enquiries[this.enqurityname]
            this.show_button = true
            }

            this.show_spinner = false
          })
        })
        .catch(() => {
          this.show_button = false
        })
    },
    ButtonClick() {
      this.$store.dispatch('setCurrentEnquiry', 1)
      this.$router.push({
        name: 'dotazniky_detail',
        params: { enquirydetail: this.enqurityname },
      })
    },
  },
  mounted() {
    if(this.enquiries['DR-MSG'] == undefined){
    this.getDrmsg()
  }
  },
}
</script>

<style scoped lang="scss">
.service {
  background-color: #22376c;
  border-radius: 0.25rem;

  &.disabled {
    background-color: #bdbdbd;
    pointer-events: none;
  }
}

.number-order {
  background-color: #00b925;
}

li {
  figure {
    min-width: 65px;
    height: 60px;
  }

  .service:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: rgba(34, 55, 108, 0.9);
  }

  &.number-order:hover {
    background-color: rgba(0, 185, 37, 0.9);
  }

  & > img {
    height: 25px;
  }
}

.position-right {
  margin: 0 0 0 auto;
}
</style>
