<template>
  <b-card border-variant="secondary" body-bg-variant="secondary">
    <div v-if="$store.getters.currentResults == 0">
      <div class="text-center">
        <b-img :src="path + results" alt="today"></b-img>
        <p class="text-22 semi-bold mt-2">
          {{ $t('results_title') }}
        </p>
      </div>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <hr />
      <b-form>
        <p
          class="text-14 semi-bold mb-2"
          :class="{
            submiterror:
              (!$v.name.required || !$v.name.minLength || !$v.name.maxLength) &&
              error,
          }"
        >
          {{ $t('name_and_surname')
          }}<span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="name"
          type="text"
          v-model.trim.lazy="$v.name.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.name.required && error">
          {{ $t('must_be_filled') }}
        </p>
        <p class="text-14 text-danger error" v-if="!$v.name.minLength && error">
          {{ $t('put_min') }}&nbsp;{{ $v.name.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p class="text-14 text-danger error" v-if="!$v.name.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              (!$v.inr.required || !$v.inr.minLength || !$v.inr.maxLength) &&
              error,
          }"
        >
          {{ $t('your_in') }}
          <span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="inr"
          type="text"
          v-model.trim.lazy="$v.inr.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.inr.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.inr.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p v-if="inrMinLength && error" class="text-14 text-danger error">
          {{ $t('put_min') }}&nbsp;9&nbsp;{{ $t('numbers') }}
        </p>
        <p v-if="inrMaxLength && error" class="text-14 text-danger error">
          {{ $t('put_max') }}&nbsp;12&nbsp;{{ $t('numbers') }}
        </p>

        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror: ((!$v.email.minLength || !$v.email.maxLength || !$v.email.email) && error),
          }"
        >
          {{ $t('email') }}
          <!--					<span class="text-14 text-muted normal"-->
          <!--				>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("must_be_filled") }}</span-->
          <!--				>-->
        </p>
        <b-form-input
          id="email"
          type="email"
          v-model.trim.lazy="$v.email.$model"
        ></b-form-input>
        <!--				<p class="text-14 text-danger error" v-if="!$v.email.required && error">-->
        <!--					{{ $t("must_be_filled") }}-->
        <!--				</p>-->
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.minLength && error"
        >
          {{ $t('put_min') }}&nbsp;{{ $v.email.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.email.maxLength && error"
        >
          {{ $t('put_max') }}&nbsp;{{ $v.email.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="$v.email.minLength && !$v.email.email && error"
        >
        {{ $t('valid_email') }}
        </p>

        <div class="text-14 semi-bold text-center mt-5">
          <b-button block size="lg" v-on:click="serviceResults(1)"
            >{{ $t('next') }}
            <b-img
              class="po-right"
              :src="path + angle_right"
              alt="order_recipe"
            ></b-img
          ></b-button>
        </div>
      </b-form>
    </div>
    <div v-if="$store.getters.currentResults == 1" class="text-center">
      <b-img :src="path + results" alt="today"></b-img>
      <p class="text-22 semi-bold mt-2">{{ $t('results_via_sms') }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceResults(0)"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="mb-3 info"
      >
        <p class="text-primary text-14 semi-bold mb-0">
          {{ $t('send_results_request') }}
        </p>
        <p class="text-primary text-14 semi-bold mb-0">
          <span class="bold"
            >{{ $store.getters.toresults.name }},
            {{ $store.getters.toresults.inr }}</span
          >
        </p>
      </b-card>
      <p class="text-14 semi-bold">
        {{ $t('results_sms') }}
      </p>
      <Sms></Sms>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_results') }}
      </p>
      <p class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>
    <div v-if="$store.getters.currentResults == 2" class="text-center">
      <b-img :src="path + results" alt="today"></b-img>
      <p class="text-22 semi-bold mt-2">{{ $t('results_via_sms') }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
        <p class="text-22 mb-0">
          {{ $t('order') }}
          <span
            v-if="$store.getters.orderStatus.order_status == 'confirmed'"
            class="bold"
            >{{ $t('confirmed') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'paid'"
            class="bold"
            >{{ $t('paid') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'payment_failed'"
            class="bold"
            >{{ $t('payment_failed') }}</span
          >
        </p>
        <p class="text-14 mb-0">
          {{ $t('request_results') }}
        </p>
        <p class="text-14 mb-0">
          {{ $t('price') }}&nbsp;{{ $store.getters.orderStatus.order_price }}
        </p>
      </b-card>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_results_sms') }}
      </p>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img
      ></b-button>
    </div>
    <div v-if="$store.getters.currentResults == 3">
      <WaitOrder :title="$t('results_via_sms')" :subtitle="''" :image="results" />
    </div>
    <div v-if="$store.getters.currentResults == 4">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="$t('results_via_sms')" :subtitle="''" :image="results" /></div>
  </b-card>
</template>

<script>
import config from '../../../../config'
import close from '../../../assets/old/close.svg'
import angle_right from '../../../assets/old/angle_right.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import info from '../../../assets/old/info.svg'
// import results from "../../../assets/old/results2.svg";
import results from '../../../assets/icons/results2.svg'
import Sms from '../Sms'
import { sk } from 'vuejs-datepicker/dist/locale'
let moment = require('moment')
import Vue from 'vue'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import VueNativeSock from 'vue-native-websocket'

import WaitOrder from '../../partials/WaitOrder.vue'
import FailedOrder from '../../partials/FailedOrder.vue'

Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
  format: 'json',
})
export default {
  components: {
    Sms,
    WaitOrder,
    FailedOrder
  },
  data() {
    return {
      sk: sk,
      path: config.URL,
      close: close,
      angle_right: angle_right,
      angle_left_small: angle_left_small,
      info: info,
      results: results,
      name: '',
      inr: '',
      email: '',
      error: false,
    }
  },
  mounted() {
    this.$store.dispatch('getResults')
  },
  updated() {
    this.$store.dispatch('checkBack')
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    inr: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(512),
    },
    email: {
      email,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
  },
  computed: {
    disabled() {
      return this.name.length > 2 &&
        this.name.length <= 512 &&
        this.inr.replace(/\D/g, '').length > 8 &&
        this.inr.replace(/\D/g, '').length <= 12
        ? false
        : true
    },
    nameMinLength() {
      return this.name.length > 2 ? false : true
    },
    nameMaxLength() {
      return this.name.length <= 512 ? false : true
    },
    inrMinLength() {
      return this.inr.replace(/\D/g, '').length > 8 ? false : true
    },
    inrMaxLength() {
      return this.inr.replace(/\D/g, '').length <= 12 ? false : true
    },
    emailMinLength() {
      return this.email.length > 2 ? false : true
    },
    emailMaxLength() {
      return this.email.length <= 512 ? false : true
    },
  },
  methods: {
    closeTab() {
      this.$router.push({ name: 'other_services' })
      // this.$store.dispatch("setCurrentService", 7);
      this.$store.dispatch('setCurrentResults', 0)
    },
    serviceResults(index) {
      if (index == 1) {
        this.$v.$touch()
        if (this.$v.$invalid || this.inrMinLength || this.inrMaxLength) {
          this.error = true
          let element = document.getElementsByClassName('submiterror')
          this.$nextTick(() => {
            element[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
          return false
        } else {
          this.error = false
          this.$store.dispatch('placeOrder', {
            type: 'result',
            name: this.name,
            pnr: this.inr,
            email: this.email,
          })
          this.$store.dispatch('setCurrentResults', index)
          setTimeout(() => {
            this.$connect(
              config.SOCKET + this.$store.getters.placeOrder.order_key
            )
            this.$options.sockets.onmessage = data => {
              if (JSON.parse(data.data).event == 'order_confirmed') {
                //this.$store.dispatch('getOrderStatus')
                this.$store.dispatch('setCurrentResults', 3)
              } else if (JSON.parse(data.data).event == 'order_paid') {
                this.$store.dispatch('setCurrentResults', 3)
              } else if (JSON.parse(data.data).event == 'order_ready') {
                this.$store.dispatch('getOrderStatus')
                this.$store.dispatch('setCurrentResults', 2)
                this.$disconnect()
              } else if (JSON.parse(data.data).event == 'order_payment_failed') {
                  this.$store.dispatch('setCurrentResults', 4)
                  this.$disconnect()
              } else {
                // console.log('error', JSON.parse(data.data).event)
              }
            }
          }, 5000)
        }
      } else {
        this.$store.dispatch('setCurrentResults', index)
      }
    },
    selectDate(val) {
      this.date = moment(val).format('DD.MM.YYYY')
    },
  },
}
</script>

<style scoped lang="scss"></style>
