<template>
  <div class="subcategory-list">
    <b-row>
      <b-col
        v-for="subcategory in data"
        :key="subcategory.id"
        @click="toSubCategory(subcategory.slug)"
        sm="4"
        class="item-body"
      >
        <b-card class="subcategory-list-item overflow-hidden" no-body>
          <span class="text-14">{{ subcategory.name }}</span>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  methods: {
    toSubCategory(slug) {
      // console.warn(id);
      this.$store.dispatch('setShopCategory', slug)

      this.$router.push({
        name: 'shop_category',
        params: { category_slug: slug },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.subcategory-list {
  .item-body {
    //padding-right: 5px !important;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .subcategory-list-item {
      border: 2px solid #c5e1ef !important;
      color: #22376c;
      font-weight: 600;
      padding: 10px;
      text-align: center;
      cursor: pointer;
    }

    //&:nth-child() {
    //	padding-left: 10px !important;
    //}
  }
}
</style>
