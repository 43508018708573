<template>
  <div>
    <InfoWorkHours />
  </div>
</template>

<script>
import InfoWorkHours from './InfoWorkHours'
export default {
  components: {
    InfoWorkHours,
  },
}
</script>

<style scoped lang="scss"></style>
