<template>
  <div class="today-number">
    <div v-if="$store.getters.currentTodayNumber == 0" class="text-center">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceToday(0)"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <div class="text-center">
        <b-img :src="path + today" alt="today"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('today_title') }}
        </p>
      </div>
      <p class="text-22 semi-bold">
        {{ $t('what_number') }}
      </p>
      <hr />
      <p class="text-14 semi-bold">
        {{ $t('today_number_send_sms') }}
      </p>
      <Sms></Sms>
      <p class="text-12 semi-bold">
        {{ $t('actual_state_today_number') }}
      </p>
      <p class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>
    <div v-if="$store.getters.currentTodayNumber == 1">
      <div class="text-center">
        <b-img :src="path + today" alt="today"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('today_title') }}
        </p>
        <p class="text-22 semi-bold">
          {{ $t('what_number') }}
        </p>
      </div>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <hr />
      <b-card border-variant="primary" body-bg-variant="primary" class="mb-3">
        <p
          v-if="$store.getters.today.entrynote != null"
          class="text-14 text-primary semi-bold mb-0"
        >
          {{ $store.getters.today.entrynote }}
        </p>
        <div class="d-flex justify-content-between mt-2">
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{
                $store.getters.today.currentnr !== null
                  ? $store.getters.today.currentnr
                  : '?'
              }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('equipped_number') }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{ $store.getters.today.nextfreenr }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('issue_number') }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{ date | moment }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('updated') }}
            </p>
          </div>
        </div>
      </b-card>
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-center mb-3"
      >
        <p class="text-14 text-primary mb-0">{{ time }}</p>
        <b-button v-on:click="refreshState" variant="link" class="p-0"
          ><b-img class="mr-2" :src="path + refresh" alt="refresh"></b-img
          ><span class="text-14">{{ $t('refresh') }}</span></b-button
        >
      </b-card>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img
      ></b-button>
    </div>
    <div v-if="$store.getters.currentTodayNumber == 2">
      <WaitOrder :title="$t('today_title')" :subtitle="$t('what_number')" :image="today" />
    </div>
    <div v-if="$store.getters.currentTodayNumber == 3">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="$t('today_title')" :subtitle="$t('what_number')" :image="today" />
    </div>
  </div>
</template>

<script>
import config from '../../../../config'
import close from '../../../assets/old/close.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import angle_right from '../../../assets/old/angle_right.svg'
// import today from "../../../assets/old/today2.svg";
import info from '../../../assets/old/info.svg'
import oval from '../../../assets/old/oval.svg'
import refresh from '../../../assets/old/refresh.svg'
import WaitOrder from '../../partials/WaitOrder.vue'
import FailedOrder from '../../partials/FailedOrder.vue'
import today from '../../../assets/icons/doors2.svg'

import Sms from '../Sms'
let moment = require('moment')
import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
})
export default {
  components: {
    Sms,
    WaitOrder,
    FailedOrder
  },
  data() {
    return {
      path: config.URL,
      close: close,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      info: info,
      oval: oval,
      today: today,
      refresh: refresh,
      numberStyle: {
        background: 'url("' + config.URL + oval + '") no-repeat center',
      },
      date: new Date(),
      secs: config.TIME,
    }
  },
  methods: {
    closeTab() {
      this.$router.push({ name: 'home' })
      // this.$store.dispatch("setCurrentService", 0);
      this.$store.dispatch('setCurrentToday', 0)
      this.$store.dispatch('setCurrentTodayNumber', 0)
    },
    serviceToday(index) {
      this.$store.dispatch('setCurrentToday', index)
    },
    serviceTodayNumber(index) {
      this.$store.dispatch('setCurrentTodayNumber', index)
    },
    refreshState() {
      this.$store.dispatch('postToday')
      this.date = new Date()
    },
  },
  computed: {
    time() {
      return moment.utc(this.secs * 1000).format('mm:ss')
    },
  },
  filters: {
    moment: date => {
      return moment(date).format('HH:mm:ss')
    },
  },
  watch: {
    '$store.getters.currentTodayNumber': function() {
      if (this.$store.getters.currentTodayNumber == 1) {
        let that = this
        setInterval(function() {
          that.secs = --that.secs
          if (that.secs == 0) {
            that.closeTab()
          }
        }, 1000)
        setInterval(function() {
          that.refreshState()
        }, config.REFRESH)
      }
    },
  },
  mounted() {
    let self = this
    setTimeout(() => {
      this.$connect(config.SOCKET + this.$store.getters.placeOrder.order_key, {
        format: 'json',
      })
      this.$socket.onmessage = data => {
        if (JSON.parse(data.data).event == 'order_confirmed') {
          this.$store.dispatch('getOrderStatus')
          self.refreshState()
          this.$store.dispatch('setCurrentTodayNumber', 2)
        } else if (JSON.parse(data.data).event == 'order_paid') {
          this.$store.dispatch('getOrderStatus')
          this.$store.dispatch('setCurrentTodayNumber', 1)
          this.$store.dispatch('postToday')
          this.$disconnect()
        // eslint-disable-next-line prettier/prettier
        } else if ( JSON.parse(data.data).event == 'order_payment_failed' ) {
          this.$store.dispatch('setCurrentTodayNumber', 3)
          this.$disconnect()
        } else {
          // console.log('error', JSON.parse(data.data).event)
        }
      }
    }, 5000)
  },
}
</script>

<style scoped lang="scss">
// .btn-link {
// 	padding: 0 !important;
// }
</style>
