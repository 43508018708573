import { render, staticRenderFns } from "./ServiceResults.vue?vue&type=template&id=f24877f2&scoped=true&"
import script from "./ServiceResults.vue?vue&type=script&lang=js&"
export * from "./ServiceResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f24877f2",
  null
  
)

export default component.exports