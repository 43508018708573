<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop">
        <b-button id="btn-back" variant="link" class="po-left" v-on:click="back"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back_to_cart') }}</b-button
        >
        <div class="text-center title-margin">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-14 semi-bold text-muted mb-0">
            {{ $t('shop_title') }}
          </p>
          <p class="text-18 semi-bold">
            {{ $t('shop_order') }}
          </p>
        </div>

        <div class="cart-link" @click="$router.push({ name: 'shop_cart' })">
          <b-img class="ml-2" :src="path + shopping_cart" alt="cart"></b-img>
          <span
            v-if="$store.getters.shopCart[$store.getters.name]"
            class="count"
            >{{ $store.getters.shopCart[$store.getters.name].length }}</span
          >
        </div>

        <hr />

        <div class="shop-checkout">
          <div class="text-center">
            <p class="text-22 semi-bold mt-2">
              {{ $t('shop_your_information') }}
            </p>
          </div>

          <b-form @submit.prevent="submitForm" class="checkout-form">
            <!-- Name -->
            <p
              class="text-14 semi-bold mb-2"
              :class="{
                submiterror:
                  (!$v.name.required ||
                    !$v.name.minLength ||
                    !$v.name.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_name') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="name"
              type="text"
              v-model.trim.lazy="$v.name.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.name.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.name.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.name.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.name.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.name.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- Personal number -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.inr.required ||
                    !$v.inr.minLength ||
                    !$v.inr.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_inr') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="inr"
              type="text"
              v-model.trim.lazy="$v.inr.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.inr.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.inr.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.inr.$params.minLength.min
              }}&nbsp;{{ $t('numbers') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.inr.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.inr.$params.maxLength.max
              }}&nbsp;{{ $t('numbers') }}
            </p>

            <!-- Phone -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.phone.required ||
                    !$v.phone.minLength ||
                    !$v.phone.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_phone') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <!--						<b-form-input-->
            <!--							id="phone"-->
            <!--							type="text"-->
            <!--							v-model.trim.lazy="$v.phone.$model"-->
            <!--						>-->
            <!--						</b-form-input>-->
            <vue-tel-input
              id="phone_wrapper"
              v-model.trim.lazy="$v.phone.$model"
              mode="international"
              defaultCountry="sk"
              :autoDefaultCountry="false"
              :preferredCountries="['sk', 'cz', 'hu', 'pl', 'ua']"
              :inputOptions="{
                placeholder: '',
                type: 'tel',
                id: 'phone',
              }"
            ></vue-tel-input>

            <p
              class="text-14 text-danger error"
              v-if="!$v.phone.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.phone.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.phone.$params.minLength.min
              }}&nbsp;{{ $t('numbers') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.phone.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.phone.$params.maxLength.max
              }}&nbsp;{{ $t('numbers') }}
            </p>

            <!-- Email -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.email.required ||
                    !$v.email.email ||
                    !$v.email.minLength ||
                    !$v.email.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_email') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="email"
              type="email"
              v-model.trim.lazy="$v.email.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.email.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.email.email && error"
            >
              {{ $t('valid_email') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.email.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.email.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.email.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.email.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- Street -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.street.required ||
                    !$v.street.minLength ||
                    !$v.street.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_street') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="street"
              type="text"
              v-model.trim.lazy="$v.street.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.street.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.street.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.street.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.street.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.street.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- City -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.city.required ||
                    !$v.city.minLength ||
                    !$v.city.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_city') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="city"
              type="text"
              v-model.trim.lazy="$v.city.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.city.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.city.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.city.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.city.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.city.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- Zip -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.zip.required ||
                    !$v.zip.minLength ||
                    !$v.zip.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_zip') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              id="zip"
              type="text"
              v-model.trim.lazy="$v.zip.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.zip.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.zip.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.zip.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.zip.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.zip.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- State -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.state.required ||
                    !$v.state.minLength ||
                    !$v.state.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_state') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-select
              id="state"
              v-model.trim.lazy="$v.state.$model"
              :options="stateList"
            >
            </b-form-select>
            <p
              class="text-14 text-danger error"
              v-if="!$v.state.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.state.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.state.$params.minLength.min
              }}&nbsp;{{ $t('char') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.state.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.state.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- Payment methods -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror: !$v.selectedMethod.required && error,
              }"
            >
              {{ $t('shop_checkout_payment_method') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <div class="payment-methods">
              <div
                :class="
                  'method' + (selectedMethod === method.id ? ' active' : '')
                "
                v-for="method in paymentMethods"
                :key="method.id"
                @click="selectMethod(method.id)"
              >
                {{ method.name }}
              </div>
            </div>
            <p
              class="text-14 text-danger error"
              v-if="!$v.selectedMethod.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>

            <!-- Company checkbox -->
            <p class="text-14 semi-bold mt-4 mb-2">
              <b-form-checkbox
                id="isCompany"
                class="checkbox"
                v-model="isCompany"
              >
                {{ $t('shop_checkout_company_checkbox') }}
              </b-form-checkbox>
            </p>

            <!-- IČO -->
            <p
              v-if="isCompany"
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.ico.required ||
                    !$v.ico.minLength ||
                    !$v.ico.maxLength) &&
                  error,
              }"
            >
              {{ $t('shop_checkout_ico') }}
              <span class="text-14 text-muted normal"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
              >
            </p>
            <b-form-input
              v-if="isCompany"
              id="ico"
              type="text"
              v-model.trim.lazy="$v.ico.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.ico.required && error"
            >
              {{ $t('must_be_filled') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.ico.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.ico.$params.minLength.min
              }}&nbsp;{{ $t('chars') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.ico.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.ico.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>
            <!-- DIČ -->
            <p
              v-if="isCompany"
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror: (!$v.dic.minLength || !$v.dic.maxLength) && error,
              }"
            >
              {{ $t('shop_checkout_dic') }}
            </p>
            <b-form-input
              v-if="isCompany"
              id="dic"
              type="text"
              v-model.trim.lazy="$v.dic.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.dic.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.dic.$params.minLength.min
              }}&nbsp;{{ $t('chars') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.dic.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.dic.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- IČ DPH -->
            <p
              v-if="isCompany"
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror:
                  (!$v.ic_dph.minLength || !$v.ic_dph.maxLength) && error,
              }"
            >
              {{ $t('shop_checkout_ic_dph') }}
            </p>
            <b-form-input
              v-if="isCompany"
              id="ic_dph"
              type="text"
              v-model.trim.lazy="$v.ic_dph.$model"
            >
            </b-form-input>
            <p
              class="text-14 text-danger error"
              v-if="!$v.ic_dph.minLength && error"
            >
              {{ $t('put_min') }}&nbsp;{{
                $v.ic_dph.$params.minLength.min
              }}&nbsp;{{ $t('chars') }}
            </p>
            <p
              class="text-14 text-danger error"
              v-if="!$v.ic_dph.maxLength && error"
            >
              {{ $t('put_max') }}&nbsp;{{
                $v.ic_dph.$params.maxLength.max
              }}&nbsp;{{ $t('chars') }}
            </p>

            <!-- Terms -->
            <p
              class="text-14 semi-bold mt-4 mb-2"
              :class="{
                submiterror: !$v.terms.sameAs && error,
              }"
            >
              <b-form-checkbox id="terms" class="checkbox" v-model="terms">
                {{ $t('shop_checkout_agree') }}
                <a href="" @click.prevent="openPostsModal">
                  {{ $t('shop_checkout_vop') }}
                </a>
                <span class="text-14 text-muted normal"
                  >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
                >
              </b-form-checkbox>
              <span
                class="text-14 text-danger error"
                v-if="!$v.terms.sameAs && error"
              >
                {{ $t('must_be_filled') }}
              </span>
            </p>

            <!-- Loading spinner -->
            <div v-if="submitLoading" class="text-center my-4">
              <b-spinner
                variant="secondary"
                :label="$t('shop_loading')"
              ></b-spinner>
            </div>

            <!-- Button -->
            <b-button
              @click="submitForm"
              class="order mt-4"
              variant="success"
              :disabled="submitLoading"
            >
              {{ $t('shop_btn_checkout') }}
              <b-img class="ml-2" :src="path + cart_white" alt="cart"></b-img>
            </b-button>
          </b-form>
        </div>
      </div>
    </b-card>

    <b-modal
      id="posts_modal"
      ref="posts_modal"
      hide-header
      ok-title="Zavrieť"
      ok-only
      :static="true"
      scrollable
    >
      <!-- <p class="text-18 bold text-primary">{{ $t('modal_header') }}</p> -->
      <!-- <p class="text-14 semi-bold">{{ $t('modal_body') }}</p> -->

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="post in shop_posts"
          :key="post.id"
        >
          <a
            :class="'nav-link' + (post.active ? ' active' : '')"
            :id="'post-tab-' + post.id"
            data-toggle="tab"
            href=""
            role="tab"
            :aria-controls="'post-' + post.id"
            aria-selected="true"
            @click.prevent="setActivePost(post)"
            >{{ post.title }}</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          :class="'tab-pane fade' + (post.active ? 'show active' : '')"
          :id="'post-' + post.id"
          role="tabpanel"
          :aria-labelledby="'post-tab' + post.id"
          v-for="post in shop_posts"
          :key="post.id"
        >
          <h2 class="post-title">{{ post.title }}</h2>
          <div v-html="post.text"></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../../config'
import {
  required,
  requiredUnless,
  sameAs,
  email,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators'

import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import angle_left_small from '../../../../assets/old/angle_left_small.svg'
import cart_white from '../../../../assets/icons/shopping_cart_white.svg'
import shopping_cart from '../../../../assets/icons/shopping_cart2.svg'

export default {
  components: {
    VueTelInput,
  },
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      cart_white: cart_white,
      shopping_cart: shopping_cart,
      meta: {
        title: 'eČasenka.sk',
        description: '',
      },
      name: '',
      inr: '',
      phone: '',
      email: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      stateList: [],
      terms: false,
      error: false,

      submitLoading: false,

      isCompany: false,
      ico: '',
      dic: '',
      ic_dph: '',

      paymentMethods: [],
      selectedMethod: '',

      shop_posts: '',
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    inr: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(12),
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(20),
    },
    email: {
      required,
      email,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    street: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    city: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    zip: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(6),
    },
    state: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    selectedMethod: {
      required,
    },
    terms: {
      sameAs: sameAs(() => true),
    },

    ico: {
      required: requiredUnless('notCompanyChecked'),
      minLength: minLength(8),
      maxLength: maxLength(8),
    },
    dic: {
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    ic_dph: {
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
  },
  computed: {
    notCompanyChecked() {
      return !this.isCompany
    },
  },
  metaInfo() {
    return {
      title: this.meta.title,
      description: this.meta.description,
    }
  },
  mounted() {
    if (
      !this.$store.getters.shopCart[this.$store.getters.name] ||
      this.$store.getters.shopCart[this.$store.getters.name].length === 0
    ) {
      this.$router.replace({ name: 'shop_cart' })
    }

    // this.$emit('setSubtitle', this.$t('shop_order'));
    this.$store.getters.doctor
      ? (this.meta['title'] =
          'Objednávka | ' + this.$store.getters.doctor.name + ' | eČasenka.sk')
      : ''
    // this.meta['title'] = 'Objednávka | ' + this.$store.getters.doctor.name + ' | eČasenka.sk';

    this.getPaymentMethods()
    this.getPosts()

    this.stateList = [{ text: 'Slovensko', value: 'Slovakia' }]
    this.state = this.stateList[0].value
  },
  watch: {
    '$store.getters.doctor': {
      handler: function(newValue) {
        if (newValue) {
          this.meta['title'] =
            'Objednávka | ' + newValue.name + ' | eČasenka.sk'
        }
      },
    },
  },
  methods: {
    submitForm() {
      // console.warn('submit');

      this.$v.$touch()
      if (this.$v.$invalid) {
        // console.error('error');
        // console.error(this.$v.name.$error, this.$v.inr.$error, this.$v.phone.$error, this.$v.email.$error, this.$v.terms.$error);
        // console.error(this.$v);
        this.error = true
        let element = document.getElementsByClassName('submiterror')
        this.$nextTick(() => {
          element[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        })
        return false
      } else {
        this.error = false
        // console.warn('passed');
        this.createOrder()
      }
    },
    createOrder() {
      this.submitLoading = true

      let allProducts = this.$store.getters.shopCart[this.$store.getters.name]
      let products = []

      allProducts.map(item => {
        products.push({
          id: item.id,
          quantity: item.count,
        })
      })

      let orderData = {
        products: products,
        payment_method_id: this.selectedMethod,
        buyer: {
          name: this.name,
          identification_number: this.inr,
          phone: this.phone,
          email: this.email,
          street: this.street,
          city: this.city,
          zip: this.zip.replace(/ /g, ''),
          state: this.state,
          privacy_policy: true,
        },
      }

      if (this.isCompany) {
        orderData['buyer']['bussiness_id'] = this.ico
        orderData['buyer']['tax_id'] = this.dic
        orderData['buyer']['vat_reg_no'] = this.ic_dph
      }

      // console.warn(orderData);
      // return;

      axios
        .post(config.ESHOP_API + '/order/create', orderData)

        .then(response => {
          // console.warn(response);
          this.submitLoading = false

          this.$store.dispatch('setShopOrder', response)

          this.$store.dispatch('setShopCart', [])
          this.$store.dispatch('setShopCartPrefill', false)
          // this.$store.dispatch('setCurrentShop', 4);
          this.getPaymentUrl(response.data.id)
        })
        .catch(() => {
          // console.warn(err);
        })

      // let productId = this.$store.getters.shopProduct.toString();
      //
      // axios.post(config.ESHOP_API + '/order/create', {
      // 	"product_id": productId,
      // 	"name": this.name,
      // 	"identification_number": this.inr,
      // 	"phone": this.phone,
      // 	"email": this.email,
      // 	"privacy_policy": true
      // })
    },
    getPaymentUrl(order_id) {
      axios
        .get(config.ESHOP_API + '/order/' + order_id)
        .then(response => {
          // console.warn(response);

          let payment = response.data.data.payments.data.orderTransactions[0]
          let paymentUrl = payment.url
          // let order_id = response.data.data.id;
          // console.warn(paymentUrl);

          if (payment.type === 'text') {
            // this.$router.push({ name: 'shop_payment', query: {id: order_id} });
            this.$store.dispatch('setShopOrder', '')

            this.$router.push({
              name: 'shop_thank_you',
              query: {
                by_square: payment.by_square,
                iban: payment.iban,
                variable_symbol: payment.variable_symbol,
                constant_symbol: payment.constant_symbol,
                price: payment.value,
              },
            })
          } else {
            // this.$router.push({ name: 'shop_payment', query: {paymentUrl: paymentUrl, id: order_id} });
            this.$store.dispatch('setShopOrder', '')

            window.location.href = paymentUrl
          }
        })
        .catch(() => {})
    },
    getPaymentMethods() {
      axios
        .get(config.PAYMENTS_API + '/orderTransactionMethod', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(
          response => {
            // console.warn(response);
            this.paymentMethods = response.data.data
          },
          () => {
            // console.error(err);
          }
        )
    },
    selectMethod(id) {
      this.$set(this, 'selectedMethod', id)
    },
    getPosts() {
      axios.get(config.ESHOP_API + '/post/' + this.$store.getters.name).then(
        response => {
          // console.warn(response);

          let posts = response.data.data

          posts.map(item => {
            item['active'] = false
          })

          posts[0]['active'] = true

          this.shop_posts = posts

          // let shop_posts = '';

          // if (posts.length > 0) {
          // 	shop_posts += '<div class="accordion" role="tablist">'
          //
          // 	posts.forEach((post, index) => {
          // 		shop_posts += `<h2 v-b-toggle.accordion-${index} class="post-title">${post.title}</h2>`;
          // 		shop_posts +=
          // 			`<b-collapse id="accordion-${index}" visible accordion="my-accordion" role="tabpanel"> ${post.text}</b-collapse>`
          // 	});
          //
          // 	shop_posts += '</div>'
          // }
        },
        () => {
          // console.error(err);
        }
      )
    },
    setActivePost(post) {
      this.shop_posts.map(item => {
        if (post.id === item.id) {
          item['active'] = true
        } else {
          item['active'] = false
        }
      })
    },
    openPostsModal() {
      this.$refs.posts_modal.show()
    },
    back() {
      this.$router.push({ name: 'shop_cart' })
      // if (this.$store.getters.shopCategory !== 0) {
      // 	this.$router.push({ name: 'shop_category', params: {category_id: this.$store.getters.shopCategory}});
      // } else {
      // 	this.$router.push({ name: 'shop' })
      // }
    },
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail .shop-checkout {
  #phone_wrapper::v-deep {
    border: 1px solid #ced4da;
    user-select: none;

    .vti__dropdown {
      background-color: #f3f3f3;
    }

    .vti__input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .checkbox {
    line-height: 1.5;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    width: 100%;
    //border-radius: 0;
    font-size: 18px;
    padding: 10px;

    &.order {
      color: white;

      img {
        width: 25px;
      }
    }
  }

  .payment-methods {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0 0 0;

    .method {
      padding: 12px 10px;
      cursor: pointer;
      border: 1px solid #c5e1ef;
      margin: 0 10px 10px 0;
      font-size: 14px;
      width: calc((100% / 3) - 10px);
      text-align: center;

      &.active {
        background: #eef7fc;
      }
    }
  }
}

#posts_modal {
  ul.nav-tabs {
    display: flex;
    flex-direction: column;

    li.nav-item {
      a.nav-link {
        position: relative;
        font-size: 14px;
        border: none !important;
        color: #22376c;
        background: #eef7fc;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          height: 10px;
          width: 10px;
          border: solid #22376c;
          border-width: 2px 2px 0 0;
          transform: translate(0, -50%) rotate(45deg);
        }

        &.active {
          background: #d2ecfb;
        }
      }
    }
  }

  .post-title {
    text-align: center;
    margin: 15px 0;
    font-size: 32px;
  }
}
</style>
