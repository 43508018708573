var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"border-variant":"secondary","body-bg-variant":"secondary"}},[_c('div',{staticClass:"shop"},[_c('b-button',{staticClass:"po-left",attrs:{"id":"btn-back","variant":"link"},on:{"click":_vm.back}},[_c('b-img',{staticClass:"mr-2",attrs:{"src":_vm.path + _vm.angle_left_small,"alt":"angle_left_small"}}),_vm._v(_vm._s(_vm.$t('back')))],1),_c('div',{staticClass:"text-center title-margin"},[_c('p',{staticClass:"text-14 semi-bold text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.$t('shop_title'))+" ")]),_c('p',{staticClass:"text-18 semi-bold"},[_vm._v(" "+_vm._s(_vm.$t('shop_detail'))+" ")])]),_c('div',{staticClass:"cart-link",on:{"click":function($event){return _vm.$router.push({ name: 'shop_cart' })}}},[_c('b-img',{staticClass:"ml-2",attrs:{"src":_vm.path + _vm.shopping_cart,"alt":"cart"}}),(_vm.$store.getters.shopCart[_vm.$store.getters.name])?_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.$store.getters.shopCart[_vm.$store.getters.name].length))]):_vm._e()],1),_c('hr'),_c('div',{staticClass:"product-detail"},[(_vm.loading)?_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Načitáva sa ..."}})],1):_vm._e(),(!_vm.loading)?_c('p',{staticClass:"text-22 text-center"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!_vm.loading)?_c('p',{staticClass:"price"},[(
              _vm.prices.price_with_tax_before_discount !==
                _vm.prices.price_with_tax_after_discount
            )?_c('span',{staticClass:"old text-14 mr-2"},[_vm._v(_vm._s(_vm.prices.price_with_tax_before_discount)+" €")]):_vm._e(),(
              _vm.prices.price_with_tax_before_discount !==
                _vm.prices.price_with_tax_after_discount
            )?_c('span',{staticClass:"new text-18 semi-bold"},[_vm._v(_vm._s(_vm.prices.price_with_tax_after_discount)+" €")]):_c('span',{staticClass:"text-18 semi-bold"},[_vm._v(_vm._s(_vm.prices.price_with_tax_after_discount)+" €")])]):_vm._e(),(!_vm.loading)?_c('b-button',{staticClass:"order",attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.toCheckout(_vm.data)}}},[_vm._v(" "+_vm._s(_vm.$t('shop_btn_order'))+" "),_c('b-img',{staticClass:"ml-2",attrs:{"src":_vm.path + _vm.cart_white,"alt":"cart"}})],1):_vm._e(),(!_vm.loading)?_c('p',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }