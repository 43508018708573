<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop">
        <b-button id="btn-back" variant="link" class="po-left" v-on:click="back"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center title-margin">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-14 semi-bold text-muted mb-0">
            {{ $t('shop_title') }}
          </p>
          <p class="text-18 semi-bold">
            {{ $t('shop_cart') }}
          </p>
        </div>
        <hr />

        <div
          v-if="
            !$store.getters.shopCart[$store.getters.name] ||
              $store.getters.shopCart[$store.getters.name].length === 0
          "
          class="empty-cart"
        >
          <p class="text-center">{{ $t('shop_cart_empty') }}</p>
        </div>

        <div class="shop-cart">
          <div
            class="cart-items"
            v-if="
              $store.getters.shopCart[$store.getters.name] &&
                $store.getters.shopCart[$store.getters.name].length > 0
            "
          >
            <div
              class="cart-item"
              v-for="item in $store.getters.shopCart[$store.getters.name]"
              :key="item.id"
            >
              <b-row no-gutters>
                <div class="cart-item-title">
                  <a
                    class="title"
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'shop_product',
                        params: { product_slug: item.alias },
                      })
                    "
                    >{{ item.title }}</a
                  >
                  <div class="description" v-html="item.description"></div>
                </div>

                <div class="cart-item-right">
                  <div class="cart-item-count">
                    <b-form-spinbutton
                      v-model="item.count"
                      min="1"
                      max="50"
                      locale="sk"
                      size="sm"
                      :formatter-fn="formatCount"
                      @change="changeCount(item)"
                      vertical
                    >
                    </b-form-spinbutton>
                  </div>

                  <div class="cart-item-price">
                    <span
                      v-if="
                        item.prices[0].price_with_tax_before_discount !==
                          item.prices[0].price_with_tax_after_discount
                      "
                      class="old text-14"
                      >{{
                        item.prices[0].price_with_tax_before_discount
                      }}
                      €</span
                    >

                    <span
                      v-if="
                        item.prices[0].price_with_tax_before_discount !==
                          item.prices[0].price_with_tax_after_discount
                      "
                      class="new text-18 semi-bold"
                      >{{
                        item.prices[0].price_with_tax_after_discount
                      }}
                      €</span
                    >

                    <span v-else class="text-18 semi-bold"
                      >{{
                        item.prices[0].price_with_tax_after_discount
                      }}
                      €</span
                    >
                  </div>

                  <div class="cart-item-delete" @click="removeItem(item)">
                    <b-img :src="path + close" alt="odobrat"></b-img>
                  </div>
                </div>
              </b-row>
            </div>
          </div>

          <div
            class="cart-total"
            v-if="
              $store.getters.shopCart[$store.getters.name] &&
                $store.getters.shopCart[$store.getters.name].length > 0
            "
          >
            <p class="semi-bold">
              {{ $t('shop_cart_total') }} {{ total | formatTotal }} €
            </p>

            <div class="price-disclaimer">
              <span>{{ $t('shop_prices_with_tax') }}</span>
            </div>
          </div>

          <b-button
            @click="back()"
            class="btn-continue"
            variant="primary"
            block
          >
            <b-img
              class="mr-2"
              :src="path + angle_left_small"
              alt="angle_left_small"
            ></b-img>
            {{ $t('shop_cart_btn_continue') }}
          </b-button>

          <b-button
            v-if="
              $store.getters.shopCart[$store.getters.name] &&
                $store.getters.shopCart[$store.getters.name].length > 0
            "
            @click="toCheckout()"
            class="btn-checkout"
            variant="success"
            block
          >
            {{ $t('shop_cart_btn_order') }}
            <b-img class="ml-2" :src="path + cart_white" alt="cart"></b-img>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import angle_left_small from '../../../../assets/old/angle_left_small.svg'
import cart_white from '../../../../assets/icons/shopping_cart_white.svg'

import close from '../../../../assets/icons/close_gray.svg'

import config from '../../../../../config'

export default {
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      cart_white: cart_white,
      close: close,

      meta: {
        title: 'eČasenka.sk',
        description: '',
      },

      total: 0,
    }
  },
  metaInfo() {
    return {
      title: this.meta.title,
      description: this.meta.description,
    }
  },
  // beforeCreate() {
  // 	// console.warn('teraz', this.$store.getters.shopCart)
  // 	if (!this.$store.getters.shopCart[this.$store.getters.name]) {
  // 		let newCart = this.$store.getters.shopCart;
  // 		newCart[this.$store.getters.name] = [];
  //
  // 		this.$store.commit('setShopCart', newCart);
  // 	}
  // },
  mounted() {
    this.$store.getters.doctor
      ? (this.meta['title'] =
          'Košík | ' + this.$store.getters.doctor.name + ' | eČasenka.sk')
      : ''

    this.getTotal()
  },
  watch: {
    '$store.getters.doctor': {
      handler: function(newValue) {
        if (newValue) {
          this.meta['title'] = 'Košík | ' + newValue.name + ' | eČasenka.sk'
        }
      },
    },
  },
  methods: {
    back() {
      if (this.$store.getters.shopCategory) {
        this.$router.push({
          name: 'shop_category',
          params: { category_slug: this.$store.getters.shopCategory },
        })
      } else {
        this.$router.push({ name: 'shop' })
      }
    },
    toCheckout() {
      this.$router.push({ name: 'shop_checkout' })
    },
    formatCount(value) {
      return value + ' ks'
    },
    getTotal() {
      let totals = 0

      if (
        this.$store.getters.shopCart[this.$store.getters.name] &&
        this.$store.getters.shopCart[this.$store.getters.name].length > 0
      ) {
        this.$store.getters.shopCart[this.$store.getters.name].forEach(item => {
          totals += item.prices[0].price_with_tax_after_discount * item.count
        })
      }

      this.$set(this, 'total', totals)

      // console.warn(totals);
    },

    changeCount(product) {
      // console.warn(product.count);

      let newData = this.$store.getters.shopCart[this.$store.getters.name].map(
        item => {
          if (product.id === item.id) {
            item.count = product.count
          }
          return item
        }
      )

      // console.warn('updated', newData);
      let newCart = this.$store.getters.shopCart
      newCart[this.$store.getters.name] = newData

      this.$store.dispatch('setShopCart', newCart)
      this.getTotal()
    },
    removeItem(product) {
      let newData = this.$store.getters.shopCart[
        this.$store.getters.name
      ].filter(item => item !== product)

      // console.warn(newData);
      let newCart = this.$store.getters.shopCart
      if (newData.length === 0) {
        newCart[this.$store.getters.name] = undefined

        this.$store.dispatch('setShopCartPrefill', false)
      } else {
        newCart[this.$store.getters.name] = newData
      }

      this.$store.dispatch('setShopCart', newCart)
      this.getTotal()
    },
  },
  filters: {
    formatTotal: function(val) {
      return Math.round((val + Number.EPSILON) * 100) / 100
    },
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail {
  .shop::v-deep {
    .shop-cart {
      .cart-item {
        border: 1px solid #c5e1ef;
        padding: 15px;

        &:not(:last-child) {
          border-bottom: none;
        }

        &-title {
          flex-basis: 65%;

          .title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            color: black;
          }

          .description {
            font-size: 12px;
            margin-bottom: 0;
            color: #a0a0a0;
          }
        }

        &-right {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-basis: 35%;
        }

        &-count {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        &-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          //margin: 0 20px;

          .old {
            text-decoration: line-through;
            color: #bdbdbd;
          }

          .new {
            color: red;
          }
        }

        &-delete {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 10px;
          }
        }

        @media (max-width: 1200px) {
          &-title {
            flex-basis: 100%;
            margin-bottom: 15px;
          }

          &-right {
            flex-basis: 100%;
          }

          &-count {
            .b-form-spinbutton.flex-column {
              flex-direction: row !important;
            }
          }
        }
      }

      .cart-total {
        margin: 30px 0;

        p {
          font-size: 20px;
          text-align: right;
          margin-right: 20px;
          margin-bottom: 0;
        }

        .price-disclaimer {
          text-align: right;
          font-size: 12px;
          padding-right: 20px;
        }
      }

      .btn-continue {
        padding: 12px 10px;
        border: 1px solid #c5e1ef;
        font-size: 16px;
      }

      .btn-checkout {
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: white !important;
        width: 100%;

        font-size: 18px;
        padding: 10px;

        img {
          width: 25px;
        }
      }
    }
  }
}
</style>
