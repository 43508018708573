const url = window.location.hostname

let var_urlhome = 'https://' + url
let var_api = 'https://api.ecasenka.sk/frontapi'
let var_socket = 'wss://api.ecasenka.sk/realtime/realtime/'

if (url == "localhost" || url == "www.ecasenka-test.sk" || url == "ecasenka-test.sk"){
  console.warn('You are working with app kiosk in a test environment!')
  var_urlhome = 'https://' + url
  var_api = 'https://api.ecasenka-test.sk/frontapi'
  var_socket = 'wss://api.ecasenka-test.sk/realtime/realtime/'
}

if (url == "www.ecasenka-dev.sk" || url == "ecasenka-dev.sk"){
  console.warn('You are working with app kiosk in a test environment!')
  var_urlhome = 'https://' + url
  var_api = 'https://api.ecasenka-dev.sk/frontapi'
  var_socket = 'wss://api.ecasenka-dev.sk/realtime/realtime/'
}

if (url == 'localhost'){
  var_api = 'https://api.ecasenka-test.sk/frontapi'
  var_urlhome = 'https://ecasenka-test.sk'
  var_socket =
    'ws://localhost:3000/'
}

const config = {
  URL: 'https://ecasenka.wame.sk',
  URLHOME: var_urlhome,
  API: var_api,
  ESHOP_API: 'https://eshop.ecasenka.sk/api',
  PAYMENTS_API: 'https://platby.ecasenka.sk/api',
  GOOGLE: 'https://maps.google.com/?q=',
  LANGUAGE: 'sk',
  SOCKET: var_socket,
  SOCKET_NEW: 'wss://ecasenka-uat.goodrequest.dev/socket/',
  TIME: 1800,
  REFRESH: 60000,
  ROUTE_PREFIX: '/ordinacia',
  DEPRECATED_ENQUERIES: ['DR-MSG'],
  ALLOW_OLD_API: true,
  ALLOW_NEW_API: true,
  API_NEW: 'https://ecasenka-uat.goodrequest.dev/api/v1',
}

export default config
