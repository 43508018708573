<template>
  <div>
    <ContentLoader v-if="$store.getters.lazy" height="50">
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </ContentLoader>
    <ul v-if="!$store.getters.lazy" class="list-unstyled mt-4">
      <!-- Get number button -->
      <!--      <li-->
      <!--              :class="{-->
      <!--          disabled: !$store.getters.doctor.services.today && !$store.getters.doctor.services.nextdays-->
      <!--        }"-->
      <!--              class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--              v-on:click="toService(6)"-->
      <!--      >-->
      <li
        :class="{
          disabled:
            !$store.getters.doctor.services.today &&
            !$store.getters.doctor.services.nextdays,
        }"
        class="d-flex align-items-center mt-2 px-3 py-2 service number-order"
        v-on:click="$router.push({ name: 'number_order' })"
      >
        <figure
          class="d-flex justify-content-center align-items-center mr-2 mb-0"
        >
          <b-img :src="path + number_order" alt="vstup"></b-img>
        </figure>
        <div class="mr-2">
          <p class="text-22 semi-bold text-white mb-0">
            {{ $t('number_order_title') }}
          </p>
        </div>
        <b-img
          class="position-right"
          :src="path + angle_right"
          alt="enter"
        ></b-img>
      </li>

      <!-- Other services button -->
      <!--      <li-->
      <!--              class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--              v-on:click="toService(7)"-->
      <!--      >-->
      <li
        class="d-flex align-items-center mt-2 px-3 py-2 service"
        v-on:click="$router.push({ name: 'other_services' })"
      >
        <figure
          class="d-flex justify-content-center align-items-center mr-2 mb-0"
        >
          <b-img :src="path + other_services" alt="e-sluzby"></b-img>
        </figure>
        <div class="mr-2">
          <p class="text-22 semi-bold text-white mb-0">
            {{ $t('other_services_title') }}
          </p>
        </div>
        <b-img
          class="position-right"
          :src="path + angle_right"
          alt="enter"
        ></b-img>
      </li>

      <!-- Enquiry button -->
      <!--      <li-->
      <!--        v-if="$store.getters.doctor.services.enquiry"-->
      <!--        class="d-flex align-items-center mt-3 px-3 py-2 service enquiry"-->
      <!--        v-on:click="toService(5)"-->
      <!--      >-->
      <li
        v-if="$store.getters.doctor.services.enquiry"
      >
      <div v-if="show_loader">
      <Preloader />
    </div>
      <div class="d-flex align-items-center mt-2 px-3 py-2 service enquiry"
      v-on:click="$router.push({ name: 'enquiry' })" v-if="show_enquiries">
        <figure
          class="d-flex justify-content-center align-items-center mr-2 mb-0"
        >
          <b-img :src="path + enquiry" alt="dotazniky"></b-img>
        </figure>
        <div class="mr-2">
          <p class="text-22 semi-bold text-white mb-0">
            {{ $t('enquiry_title') }}
            <br v-if="show_description" />
            <span v-if="show_description" style="font-weight: 400;">{{
              $t('enquiry_title_2')
            }}</span>
          </p>
        </div>
        <b-img
          class="position-right"
          :src="path + angle_right"
          alt="enter"
        ></b-img>
      </div>
      </li>
      <EnquiryV2DoctorDetailButton :enquiries="enquiries" />
      <!--      <li-->
      <!--        :class="{-->
      <!--          disabled: !$store.getters.doctor.services.today-->
      <!--        }"-->
      <!--        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--        v-on:click="toService(1)"-->
      <!--      >-->
      <!--        <figure-->
      <!--          class="d-flex justify-content-center align-items-center mr-2 mb-0"-->
      <!--        >-->
      <!--          <b-img :src="path + today" alt="enter"></b-img>-->
      <!--        </figure>-->
      <!--        <div class="mr-2">-->
      <!--          <p class="text-22 semi-bold text-white mb-0">-->
      <!--            {{ $t("today_title") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <b-img-->
      <!--          class="position-right"-->
      <!--          :src="path + angle_right"-->
      <!--          alt="enter"-->
      <!--        ></b-img>-->
      <!--      </li>-->
      <!--      <li-->
      <!--        :class="{ disabled: !$store.getters.doctor.services.nextdays }"-->
      <!--        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--        v-on:click="toService(2)"-->
      <!--      >-->
      <!--        <figure-->
      <!--          class="d-flex justify-content-center align-items-center mr-2 mb-0"-->
      <!--        >-->
      <!--          <b-img :src="path + calendar" alt="enter"></b-img>-->
      <!--        </figure>-->
      <!--        <div class="mr-2">-->
      <!--          <p class="text-22 semi-bold text-white mb-0">-->
      <!--            {{ $t("nextdays_title") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <b-img-->
      <!--          class="position-right"-->
      <!--          :src="path + angle_right"-->
      <!--          alt="enter"-->
      <!--        ></b-img>-->
      <!--      </li>-->
      <!--      <li-->
      <!--        :class="{ disabled: !$store.getters.doctor.services.recipe }"-->
      <!--        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--        v-on:click="toService(3)"-->
      <!--      >-->
      <!--        <figure-->
      <!--          class="d-flex justify-content-center align-items-center mr-2 mb-0"-->
      <!--        >-->
      <!--          <b-img :src="path + recipe" alt="enter"></b-img>-->
      <!--        </figure>-->
      <!--        <div class="mr-2">-->
      <!--          <p class="text-22 semi-bold text-white mb-0">-->
      <!--            {{ $t("recipe_title") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <b-img-->
      <!--          class="position-right"-->
      <!--          :src="path + angle_right"-->
      <!--          alt="enter"-->
      <!--        ></b-img>-->
      <!--      </li>-->
      <!--      <li-->
      <!--        :class="{ disabled: !$store.getters.doctor.services.result }"-->
      <!--        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
      <!--        v-on:click="toService(4)"-->
      <!--      >-->
      <!--        <figure-->
      <!--          class="d-flex justify-content-center align-items-center mr-2 mb-0"-->
      <!--        >-->
      <!--          <b-img :src="path + results" alt="enter"></b-img>-->
      <!--        </figure>-->
      <!--        <div class="mr-2">-->
      <!--          <p class="text-22 semi-bold text-white mb-0">-->
      <!--            {{ $t("results_title") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <b-img-->
      <!--          class="position-right"-->
      <!--          :src="path + angle_right"-->
      <!--          alt="enter"-->
      <!--        ></b-img>-->
      <!--      </li>-->
    </ul>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import config from '../../../config'
import axios from 'axios'
import EnquiryV2DoctorDetailButton from '../EnquiryV2DoctorDetailButton.vue'
import { CheckApiUrl } from '../../modules/CheckApiUrlModule';
// import today from "../../assets/old/today.svg";
// import reserve from "../../assets/old/reserve.svg";
// import calendar from "../../assets/old/calendar.svg";
// import recipe from "../../assets/old/recipe.svg";
// import results from "../../assets/old/results.svg";
// import enquiry from "../../assets/old/enquiry_white.svg";
import angle_right from '../../assets/old/angle_right.svg'

import number_order from '../../assets/icons/number_order.svg'
import other_services from '../../assets/icons/other_services.svg'
import enquiry from '../../assets/icons/enquiry.svg'
import Preloader from '../Preloader.vue'

export default {
  data() {
    return {
      path: config.URL,
      // today: today,
      // reserve: reserve,
      // calendar: calendar,
      // recipe: recipe,
      // results: results,
      enquiry: enquiry,
      angle_right: angle_right,
      number_order: number_order,
      other_services: other_services,
      show_description: false,
      count: 0,
      show_enquiries: false,
      show_loader: true,
      enquiries: {},
    }
  },
  components: {
    ContentLoader,
    EnquiryV2DoctorDetailButton,
    Preloader
  },
  methods: {
    toService(index) {
      this.$store.dispatch('setCurrentService', index)
    },
    checkEnquiries() {
      /* Check if button to show all allowed enquries will show on doctor detail */
      
      let url = CheckApiUrl.check('/enquiry_v2/' + this.$store.getters.name)

      axios
        .get(url)
        .then(response => {
          /* Remove parameter from enquries object array */
          if (response.data.enquiries != undefined) {
            this.enquiries = response.data.enquiries
            
            config.DEPRECATED_ENQUERIES.forEach(value => {
              delete response.data.enquiries[value]
            })
            //console.log(response.data.enquiries)
            if (response.data.enquiries) {
              this.count = Object.keys(response.data.enquiries).length
              if (this.count > 0) {
                this.show_enquiries = true;
              }
            }
          }
          this.show_loader = false;
        })
    },
  },
  mounted() {
      this.checkEnquiries();
    },
}
</script>

<style scoped lang="scss">
.service {
  background-color: #22376c;
  border-radius: 0.25rem;

  &.disabled {
    background-color: #bdbdbd;
    pointer-events: none;
  }
}

//.enquiry {
//  background-color: #ff4747;
//}

.number-order {
  background-color: #00b925;
}

li {
  figure {
    min-width: 65px;
    height: 60px;
  }

  .service:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: rgba(34, 55, 108, 0.9);
  }

  &.number-order:hover {
    background-color: rgba(0, 185, 37, 0.9);
  }

  //&.enquiry:hover {
  //  background-color: rgba(255, 71, 71, 0.9);
  //}

  & > img {
    height: 25px;
  }
}

.position-right {
  margin: 0 0 0 auto;
}
</style>
