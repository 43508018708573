<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="number-order">
        <b-button
          id="btn-back"
          variant="link"
          class="po-left"
          v-on:click="closeTab()"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center">
          <!--                    <b-img :src="path + number_order" alt="vstup"></b-img>-->
          <p class="text-22 semi-bold mt-2">
            {{ $t('number_order_title') }}
          </p>
        </div>
        <hr />

        <ul class="list-unstyled mt-4 mb-0">
          <!--                    <li-->
          <!--                        :class="{-->
          <!--                          disabled: !$store.getters.doctor.services.today-->
          <!--                        }"-->
          <!--                        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
          <!--                        v-on:click="toService(1)"-->
          <!--                    >-->
          <li
            :class="{
              disabled: !$store.getters.doctor.services.today,
            }"
            class="d-flex align-items-center mt-2 px-3 py-2 service"
            v-on:click="$router.push({ name: 'number_today' })"
          >
            <figure
              class="d-flex justify-content-center align-items-center mr-2 mb-0"
            >
              <b-img :src="path + today" alt="dnes"></b-img>
            </figure>
            <div class="mr-2">
              <p class="text-22 semi-bold text-white mb-0">
                {{ $t('today_title') }}
              </p>
            </div>
            <b-img
              class="position-right"
              :src="path + angle_right"
              alt="enter"
            ></b-img>
          </li>
          <!--                    <li-->
          <!--                        :class="{ disabled: !$store.getters.doctor.services.nextdays }"-->
          <!--                        class="d-flex align-items-center mt-2 px-3 py-2 service"-->
          <!--                        v-on:click="toService(2)"-->
          <!--                    >-->
          <li
            :class="{ disabled: !$store.getters.doctor.services.nextdays }"
            class="d-flex align-items-center mt-2 px-3 py-2 service"
            v-on:click="$router.push({ name: 'number_next' })"
          >
            <figure
              class="d-flex justify-content-center align-items-center mr-2 mb-0"
            >
              <b-img :src="path + calendar" alt="iny-den"></b-img>
            </figure>
            <div class="mr-2">
              <p class="text-22 semi-bold text-white mb-0">
                {{ $t('nextdays_title') }}
              </p>
              <p class="text-12 text-white mb-0" style="opacity: .8;">
                {{ $t('nextdays_subtitle') }}
              </p>
            </div>
            <b-img
              class="position-right"
              :src="path + angle_right"
              alt="enter"
            ></b-img>
          </li>
        </ul>
      </div>
    </b-card>
  </div>
</template>

<script>
import config from '../../../config'
import angle_left_small from '../../assets/old/angle_left_small.svg'
import angle_right from '../../assets/old/angle_right.svg'
// import calendar from "../../assets/old/calendar.svg";
// import calendar2 from "../../assets/old/calendar2.svg";
// import today from "../../assets/old/today.svg";

import number_order from '../../assets/icons/number_order2.svg'
import today from '../../assets/icons/doors.svg'
import calendar from '../../assets/icons/calendar.svg'

export default {
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      calendar: calendar,
      // calendar2: calendar2,
      today: today,
      number_order: number_order,
    }
  },
  methods: {
    closeTab() {
      // this.$store.dispatch("setCurrentService", 0);
      this.$router.push({ name: 'home' })
    },
    toService(index) {
      this.$store.dispatch('setCurrentService', index)
    },
  },
}
</script>

<style scoped lang="scss">
.service {
  background-color: #22376c;
  border-radius: 0.25rem;

  &.disabled {
    background-color: #bdbdbd;
    pointer-events: none;
  }
}
li {
  figure {
    min-width: 65px;
    height: 60px;
  }
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
    background-color: rgba(34, 55, 108, 0.9);
  }

  &.enquiry:hover {
    background-color: rgba(255, 71, 71, 0.9);
  }

  & > img {
    height: 25px;
  }
}
.position-right {
  margin: 0 0 0 auto;
}
</style>
