<template>
    <div>
      <div class="text-center">
        <b-img :src="path + info_image" alt=""></b-img>
      </div>
      <hr />
      <b-card border-variant="danger" body-bg-variant="danger" class="mb-3">
        <p style="color: white !important;" class="text-16 text-primary semi-bold mb-0 text-center">
          {{ $t(error_text) }}
        </p>
      </b-card>
    </div>
  </template>
  
  <script>
  import config from '../../../config'
  import info from '../../assets/old/info.svg'
  
  export default {
    data() {
      return {
        path: config.URL,
        info_image: info
      }
    },
    props: ['err'],
    computed: {
      error_text() {
        return this.err !== 'ERROR_SMS_KEY' ? this.err : 'order_key_null_error_text';
      }
    }
  }
  </script>
  
  <style></style>
  