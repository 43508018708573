<template>
  <b-card border-variant="secondary" body-bg-variant="secondary">
    <div v-if="$store.getters.currentRecipe == 0">
      <div class="text-center">
        <b-img :src="path + recipe" alt="recipe"></b-img>
        <p class="text-22 semi-bold mt-2">
          {{ $t('request_recipe') }}
        </p>
      </div>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <hr />
      <b-form>
        <p
          class="text-14 semi-bold mb-2"
          :class="{
            submiterror:
              (!$v.name.required || !$v.name.minLength || !$v.name.maxLength) &&
              error,
          }"
        >
          {{ $t('name_and_surname')
          }}<span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="message"
          type="text"
          v-model.trim.lazy="$v.name.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.name.required && error">
          {{ $t('must_be_filled') }}
        </p>
        <p class="text-14 text-danger error" v-if="!$v.name.minLength && error">
          {{ $t('put_min') }}&nbsp;{{ $v.name.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p class="text-14 text-danger error" v-if="!$v.name.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              (!$v.inr.required || !$v.inr.minLength || !$v.inr.maxLength) &&
              error,
          }"
        >
          {{ $t('your_in') }}
          <span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="inr"
          type="text"
          v-model.trim.lazy="$v.inr.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.inr.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.inr.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p v-if="inrMinLength && error" class="text-14 text-danger error">
          {{ $t('put_min') }}&nbsp;9&nbsp;{{ $t('numbers') }}
        </p>
        <p v-if="inrMaxLength && error" class="text-14 text-danger error">
          {{ $t('put_max') }}&nbsp;12&nbsp;{{ $t('numbers') }}
        </p>
        <p
          class="mt-4 mb-2"
          :class="{
            submiterror:
              (!$v.medicine.required ||
                !$v.medicine.minLength ||
                !$v.medicine.maxLength) &&
              error,
          }"
        >
          <span class="text-14 semi-bold mb-2"
            >{{ $t('drug_name') }}<span class="text-muted">&nbsp;*</span></span
          >
          &nbsp;&nbsp;<span class="text-14">{{ $t('drug_name_notice') }}</span>
        </p>
        <b-form-textarea
          id="medicine"
          type="text"
          rows="4"
          v-model.trim.lazy="$v.medicine.$model"
        ></b-form-textarea>
        <p
          class="text-14 text-danger error"
          v-if="!$v.medicine.required && error"
        >
          {{ $t('must_be_filled') }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.medicine.minLength && error"
        >
          {{ $t('put_min') }}&nbsp;{{
            $v.medicine.$params.minLength.min
          }}&nbsp;{{ $t('char') }}
        </p>
        <p
          class="text-14 text-danger error"
          v-if="!$v.medicine.maxLength && error"
        >
          {{ $t('put_max') }}&nbsp;{{
            $v.medicine.$params.maxLength.max
          }}&nbsp;{{ $t('chars') }}
        </p>
        <div class="text-14 semi-bold text-center mt-4">
          <p class="mb-1">{{ $t('attention') }}</p>
          <!--          <p class="mb-2">-->
          <!--            {{ $t("attention_content") }}-->
          <!--          </p>-->
          <p class="mb-4">
            {{ $t('attention_content_2') }}
          </p>
          <b-button block size="lg" v-on:click="serviceRecipe(1)"
            >{{ $t('next') }}
            <b-img
              class="po-right"
              :src="path + angle_right"
              alt="order_recipe"
            ></b-img>
          </b-button>
        </div>
      </b-form>
    </div>
    <div v-if="$store.getters.currentRecipe == 1" class="text-center">
      <b-img :src="path + recipe" alt="today"></b-img>
      <p class="text-22 semi-bold mt-2">{{ $t('ordering_recipes') }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceRecipe(0)"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="mb-3 info"
      >
        <p class="text-primary text-14 semi-bold mb-0">
          {{ $t('send_recipe_request') }}
        </p>
        <p class="text-primary text-14 semi-bold mb-0">
          <span class="bold">{{ $store.getters.torecipe.name }},&nbsp;</span
          >{{ $t('born') }}
          <span class="bold">{{ $store.getters.torecipe.inr }}</span>
        </p>
        <p class="text-primary text-14 bold mb-0">
          {{ $store.getters.torecipe.medicine }}
        </p>
      </b-card>
      <p class="text-14 semi-bold">
        {{ $t('recipe_sms') }}
      </p>
      <Sms v-if="showQrCodeRecipe"></Sms>
      <span v-if="!showQrCodeRecipe" v-html="showQrCodeRecipeMessage"></span>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_recipe') }}
      </p>
      <p class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>
    <div v-if="$store.getters.currentRecipe == 2" class="text-center">
      <b-img :src="path + recipe" alt="today"></b-img>
      <p class="text-22 semi-bold mt-2">{{ $t('ordering_recipes') }}</p>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
      >
        <b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}
      </b-button>
      <hr />
      <b-card
        border-variant="primary"
        body-bg-variant="primary"
        class="text-primary semi-bold mb-3"
      >
        <p class="text-22 mb-0">
          {{ $t('order') }}
          <span
            v-if="$store.getters.orderStatus.order_status == 'confirmed'"
            class="bold"
            >{{ $t('confirmed') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'paid'"
            class="bold"
            >{{ $t('paid') }}</span
          >
          <span
            v-if="$store.getters.orderStatus.order_status == 'payment_failed'"
            class="bold"
            >{{ $t('payment_failed') }}</span
          >
        </p>
        <p class="text-14 mb-0">
          {{ $t('request_recipe') }}
        </p>
        <p class="text-14 mb-0">
          {{ $t('price') }}&nbsp;{{ $store.getters.orderStatus.order_price }}
        </p>
      </b-card>
      <p class="text-12 semi-bold mt-3">
        {{ $t('actual_state_results_sms') }}
      </p>
      <b-button block size="lg" v-on:click="closeTab()"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img>
      </b-button>
    </div>
    <div v-if="$store.getters.currentRecipe == 3">
      <WaitOrder :title="$t('ordering_recipes')" :subtitle="''" :image="recipe" />
    </div>
    <div v-if="$store.getters.currentRecipe == 4">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="$t('ordering_recipes')" :subtitle="''" :image="recipe" /></div>
  </b-card>
</template>

<script>
import config from '../../../../config'
import close from '../../../assets/old/close.svg'
import angle_right from '../../../assets/old/angle_right.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import info from '../../../assets/old/info.svg'
// import recipe from "../../../assets/old/recipe2.svg";
import recipe from '../../../assets/icons/recipe2.svg'
import Sms from '../Sms'
import { sk } from 'vuejs-datepicker/dist/locale'
let moment = require('moment')
import Vue from 'vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import VueNativeSock from 'vue-native-websocket'
import { CheckApiUrl } from '../../../modules/CheckApiUrlModule'

import WaitOrder from '../../partials/WaitOrder.vue'
import FailedOrder from '../../partials/FailedOrder.vue'

Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
  format: 'json',
})
export default {
  components: {
    Sms,
    WaitOrder,
    FailedOrder
  },
  data() {
    return {
      sk: sk,
      path: config.URL,
      close: close,
      angle_right: angle_right,
      angle_left_small: angle_left_small,
      info: info,
      recipe: recipe,
      name: '',
      inr: '',
      medicine: '',
      error: false,
    }
  },
  mounted() {
    this.$store.dispatch('getRecipe')
  },
  updated() {
    this.$store.dispatch('checkBack')
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    inr: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(512),
    },
    medicine: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
  },
  computed: {
    disabled() {
      return this.name.length > 2 &&
        this.name.length <= 512 &&
        this.medicine.length > 2 &&
        this.medicine.length <= 512 &&
        this.inr.replace(/\D/g, '').length > 8 &&
        this.inr.replace(/\D/g, '').length <= 12
        ? false
        : true
    },
    nameMinLength() {
      return this.name.length > 2 ? false : true
    },
    nameMaxLength() {
      return this.name.length <= 512 ? false : true
    },
    inrMinLength() {
      return this.inr.replace(/\D/g, '').length > 8 ? false : true
    },
    inrMaxLength() {
      return this.inr.replace(/\D/g, '').length <= 12 ? false : true
    },
    medicineMinLength() {
      return this.medicine.length > 2 ? false : true
    },
    medicineMaxLength() {
      return this.medicine.length <= 512 ? false : true
    },
    showQrCodeRecipe() {
      return this.$store.state.showQrCodeRecipe
    },
    showQrCodeRecipeMessage() {
      return this.$store.state.showQrCodeRecipeMessage
    }
  },
  methods: {
    closeTab() {
      this.$router.push({ name: 'other_services' })
      // this.$store.dispatch("setCurrentService", 7);
      this.$store.dispatch('setCurrentRecipe', 0)
    },
    serviceRecipe(index) {
      if (index == 1) {
        this.$v.$touch()
        if (this.$v.$invalid || this.inrMinLength || this.inrMaxLength) {
          this.error = true
          let element = document.getElementsByClassName('submiterror')
          this.$nextTick(() => {
            element[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
          return false
        } else {
          this.error = false
          this.$store.dispatch('placeOrder', {
            type: 'recipe',
            name: this.name,
            pnr: this.inr,
            medicine: this.medicine,
          })
          this.$store.dispatch('setCurrentRecipe', index)
          setTimeout(() => {
            this.$connect(
              CheckApiUrl.check_socket(this.$store.getters.placeOrder.order_key)
            )
            this.$options.sockets.onmessage = data => {
              if (CheckApiUrl.is_new_api_doctor() == true) {

                let order = {}
                order.app_nr = null
                order.app_onday = null
                order.app_status = null
                order.err = ""
                order.name = ""
                order.order_id = 0
                order.order_type = "u"

                if(JSON.parse(data.data).order.smsState == 'BILLED_AND_DELIVERED'){
                order.order_price = (parseFloat(JSON.parse(data.data).sms.price).toFixed(2).toString().replace('.', ',') || "0,00") + ' €'
                order.order_status = "paid"
                } else if(JSON.parse(data.data).order.smsState == 'NOT_BILLED_NOT_DELIVERED'){
                order.order_status = "payment_failed"
                order.order_price = "0,00 €"
                } else{
                order.order_status = "payment_failed"
                order.order_price = "0,00 €"
                }

                this.$store.commit('setOrderStatus', order)
                this.$store.dispatch('setCurrentRecipe', 2)
                this.$disconnect()

              } else {
                if (JSON.parse(data.data).event == 'order_confirmed') {
                  //this.$store.dispatch('getOrderStatus')
                  this.$store.dispatch('setCurrentRecipe', 3)
                } else if (JSON.parse(data.data).event == 'order_paid') {
                  this.$store.dispatch('setCurrentRecipe', 3)
                } else if (JSON.parse(data.data).event == 'order_ready') {
                  this.$store.dispatch('getOrderStatus')
                  this.$store.dispatch('setCurrentRecipe', 2)
                  this.$disconnect()
                } else if (JSON.parse(data.data).event == 'order_payment_failed') {
                  this.$store.dispatch('setCurrentRecipe', 4)
                  this.$disconnect()
                } else {
                  //console.log('error', JSON.parse(data.data).event)
                }
              }
            }
          }, 5000)
        }
      } else {
        this.$store.dispatch('setCurrentRecipe', index)
      }
    },
    selectDate(val) {
      this.date = moment(val).format('D.M.YYYY')
    },
  },
}
</script>

<style scoped lang="scss"></style>
