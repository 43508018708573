<template>
  <div id="ecasenkaDetail">
    <b-container>
      <b-row class="pt-3 pb-5">
        <b-col md="6" class="mb-3 d-flex align-items-center">
          <InfoAboutDoctor />
        </b-col>
        <b-col md="6" class="mb-3">
          <Info />
        </b-col>
        <b-col md="6" class="mb-3">
          <LeftContent />
        </b-col>
        <b-col md="6">
          <RightContent />
        </b-col>
      </b-row>
    </b-container>
<Gdpr />
</div></template>

<script>
import Info from './components/Info.vue'
import InfoAboutDoctor from './components/InfoAboutDoctor.vue'
import RightContent from './components/RightContent.vue'
import LeftContent from './components/LeftContent.vue'
import './assets/custom.scss'
import Gdpr from './components/Gdpr.vue'
import config from '../config'
import { mapState } from 'vuex';


export default {
  name: 'ecasenkaDetail',
  components: {
    Info,
    InfoAboutDoctor,
    RightContent,
    LeftContent,
    Gdpr,
  },
  beforeCreate() {
    // this.$store.commit("setName", window.location.pathname.split("/").pop());
    if (this.$route.params.slug) {
      this.$store.commit('setName', this.$route.params.slug)
    } else if (window.doctor) {
      this.$store.commit('setName', window.doctor)
    }
  },
  mounted() {
    // console.warn('kiosk cordova test');
    if (window.cordova) {
      // console.warn('there is cordova');
    } else {
      // console.warn('no cordova');
    }

    document.addEventListener("DOMContentLoaded", function () {
      const elements = document.querySelectorAll('meta[name="twitter:title"]');
      elements.forEach((element) => {
        element.remove()
      })
    })

    document.addEventListener("DOMContentLoaded", function () {
      const elements = document.querySelectorAll('meta[property="og:title"]')
      elements.forEach((element) => {
        element.remove()
      })
    })

    document.addEventListener("DOMContentLoaded", function () {
      const elements = document.querySelectorAll('.page-title-head h1')
      elements.forEach((element) => {
        element.remove()
      })
    })

    document.addEventListener("DOMContentLoaded", function () {
      const elements = document.querySelectorAll('link[rel="canonical"]')
      elements.forEach((element) => {
        element.remove()
      })
    })
  },
  computed: {
    ...mapState({
      pageTitle: state => (state.doctor.name ? state.doctor.name + ' | ' : '') + 'eČasenka.sk',
      pageDescription: state => (state.doctor.name ? state.doctor.name : '') + (state.doctor.type ? ' - ' + state.doctor.type : '') + (state.doctor.city ? ', ' + state.doctor.city : '') + '. Objednajte sa online.',
    }),
  },

  metaInfo() {
    const title = (this.pageTitle || '')

    const link = [
      { rel: 'canonical', href: config.URLHOME + config.ROUTE_PREFIX + '/' + this.$route.params.slug }
    ]

    const meta = [
      {
        property: 'og:title', 
        content: this.pageTitle,
      },
      {
        name: 'twitter:title', 
        content: this.pageTitle,
      },
      {
        name: 'description', 
        content: this.pageDescription,
      },
    ]

    return { title, link, meta }
  },
}
</script>
<style language="scss"></style>