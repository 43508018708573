<template>
  <div class="today-number">
    <div v-if="$store.getters.currentTodayNoCalling == 0">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceToday(0)"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <div class="text-center">
        <b-img :src="path + today" alt="today"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('today_title') }}
        </p>
        <p class="text-22 semi-bold">
          {{ $t('put_no_calling') }}
        </p>
      </div>
      <hr />
      <b-form>
        <p
          class="text-14 semi-bold mb-2"
          :class="{
            submiterror:
              (!$v.name.required || !$v.name.minLength || !$v.name.maxLength) &&
              error,
          }"
        >
          {{ $t('name_and_surname') }}
          <span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
        </p>
        <b-form-input
          id="name"
          type="text"
          v-model.trim.lazy="$v.name.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="!$v.name.required && error">
          {{ $t('must_be_filled') }}
        </p>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="!$v.name.minLength && error">
          {{ $t('put_min') }}&nbsp;{{ $v.name.$params.minLength.min }}&nbsp;{{
            $t('char')
          }}
        </p>
        <p class="text-14 text-danger error" style="position:static !important;" v-if="(!$v.name.maxLength && error) || !this.sumLengthNameMessageValidator('name')">
          {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              $v.inr.required &&
              (!$v.inr.minLength || !$v.inr.maxLength) &&
              error,
          }"
        >
          {{ $t('your_in') }}
          <span
            v-if="$store.getters.today.personal_data_required"
            class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('must_be_filled') }}</span
          >
          <span
            v-if="!$store.getters.today.personal_data_required"
            class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;({{ $t('doesnt_have_be_filled') }})</span
          >
        </p>
        <b-form-input
          id="inr"
          type="text"
          v-model.trim.lazy="$v.inr.$model"
        ></b-form-input>
        <p class="text-14 text-danger error" v-if="!$v.inr.maxLength && error">
          {{ $t('put_max') }}&nbsp;{{ $v.inr.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <p
          v-if="inrMinLength && $v.inr.required && error"
          class="text-14 text-danger error"
        >
          {{ $t('put_min') }}&nbsp;9&nbsp;{{ $t('numbers') }}
        </p>
        <p
          v-if="inrMaxLength && $v.inr.required && error"
          class="text-14 text-danger error"
        >
          {{ $t('put_max') }}&nbsp;12&nbsp;{{ $t('numbers') }}
        </p>
        <p
          class="text-14 semi-bold mt-4 mb-2"
          :class="{
            submiterror:
              (!$v.message.minLength || !$v.message.maxLength) &&
              $v.inr.required &&
              error,
          }"
        >
          {{ $t('message_for_doctor')
          }}<span class="text-14 text-muted normal"
            >&nbsp;&nbsp;&nbsp;&nbsp;({{ $t('doesnt_have_be_filled') }})</span
          >
        </p>
        <b-form-textarea
          id="message"
          type="text"
          rows="2"
          v-model.trim.lazy="$v.message.$model"
        ></b-form-textarea>
        <p class="text-14 text-danger error" v-if="(!$v.message.maxLength && error) || !this.sumLengthNameMessageValidator('message')">
          {{ $t('put_max') }}&nbsp;{{ $v.name.$params.maxLength.max }}&nbsp;{{
            $t('chars')
          }}
        </p>
        <div class="text-14 semi-bold text-center mt-5">
          <b-button block size="lg" v-on:click="serviceTodayNoCalling(1)"
            >{{ $t('next')
            }}<b-img
              class="po-right"
              :src="path + angle_right"
              alt="order_recipe"
            ></b-img
          ></b-button>
        </div>
      </b-form>
    </div>
    <div v-if="$store.getters.currentTodayNoCalling == 1" class="text-center">
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="serviceTodayNoCalling(0)"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <div class="text-center">
        <b-img :src="path + today" alt="today"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('today_title') }}
        </p>
      </div>
      <p class="text-22 semi-bold">
        {{ $t('put_no_calling') }}
      </p>
      <hr />
      <b-card v-if="showSMS"
        border-variant="primary"
        body-bg-variant="primary"
        class="mb-3 info"
      >
        <p class="text-14 text-primary mb-0">
          {{ $t('put') }} {{ $store.getters.today.nextfreenr }}.
        </p>
      </b-card>
      <p v-if="showSMS" class="text-14 semi-bold">
        {{ $t('put_no_calling_send_sms') }}
      </p>
      <Sms v-if="showSMS"></Sms>
        <div v-else>
          <Preloader v-if="errorSMStext.length == 0" />
         <p v-else>
            <OrderKeyNull :err="errorSMStext" />
        </p>
        </div>
      <p v-if="showSMS" class="text-12 semi-bold">
        {{ $t('actual_state_no_calling') }}
      </p>
      <p v-if="showSMS" class="text-12 semi-bold text-danger mb-0">
        {{ $t('actual_state_attention') }}
      </p>
    </div>
    <div v-if="$store.getters.currentTodayNoCalling == 2">
      <div class="text-center">
        <b-img :src="path + today" alt="today"></b-img>
        <p class="text-22 text-muted semi-bold mt-2 mb-0">
          {{ $t('today_title') }}
        </p>
        <p class="text-22 semi-bold">{{ $t('put_no_calling') }}</p>
      </div>
      <b-button
        id="btn-back"
        variant="link"
        class="po-left"
        v-on:click="closeTab"
        ><b-img
          class="mr-2"
          :src="path + angle_left_small"
          alt="angle_left_small"
        ></b-img
        >{{ $t('back') }}</b-button
      >
      <hr />
      <b-card border-variant="primary" body-bg-variant="primary" class="mb-3">
        <p
          v-if="$store.getters.today.entrynote != null"
          class="text-14 text-primary semi-bold mb-0"
        >
          {{ $store.getters.today.entrynote }}
        </p>
        <div class="d-flex justify-content-between mt-2">
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{
                $store.getters.today.currentnr !== null
                  ? $store.getters.today.currentnr
                  : '?'
              }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('equipped_number') }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{ $store.getters.today.nextfreenr }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('issue_number') }}
            </p>
          </div>
          <div class="text-center">
            <p class="text-22 text-secondary bold mb-0">
              {{ date | moment }}
            </p>
            <p class="text-12 semi-bold mb-0">
              {{ $t('updated') }}
            </p>
          </div>
        </div>
      </b-card>
      <b-card border-variant="primary" body-bg-variant="primary" class="mb-3">
        <p class="text-14 text-center text-primary mb-0">
          {{ $t('ticket_for_today') }}&nbsp;{{
            $store.getters.orderStatus.app_nr
          }}
        </p>
      </b-card>
      <b-button block size="lg" v-on:click="closeTab"
        >{{ $t('to_start')
        }}<b-img
          class="po-right"
          :src="path + angle_right"
          alt="order_recipe"
        ></b-img
      ></b-button>
    </div>
    <div v-if="$store.getters.currentTodayNoCalling == 4">
      <b-button
        id="btn-close"
        variant="link"
        class="po-right"
        v-on:click="closeTab"
      >
        {{ $t('close') }}
        <b-img class="ml-1" :src="path + close" alt="x"></b-img>
      </b-button>
      <FailedOrder :title="$t('today_title')" :subtitle="$t('put_no_calling')" :image="today" />
    </div>
    <div v-if="$store.getters.currentTodayNoCalling == 3">
      <WaitOrder :title="$t('today_title')" :subtitle="$t('put_no_calling')" :image="today" />
    </div>

    <b-modal v-model="modalError.show" id="xmodal" ref="modal" size="md" hide-header ok-title="Pokračovať" @ok="modalError.ok"
    no-close-on-esc no-close-on-backdrop centered ok-only scrollable ok-variant="secondary" :static="true">
    <div class="mb-2 text-center" style="color:#9b0a0a;">
      <svg class="ov-icon" aria-hidden="true" width="46.08" height="46.08" viewBox="-75.52 -43.52 599.04 599.04" fill="currentColor" style="font-size: 2.88em;"><path d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z"></path></svg>
      </div>
    <div class="text-center">{{ $t(modalError.text) }}</div>
    </b-modal>
    
  </div>
</template>

<script>
import config from '../../../../config'
import close from '../../../assets/old/close.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import angle_right from '../../../assets/old/angle_right.svg'
import info from '../../../assets/old/info.svg'
import oval from '../../../assets/old/oval.svg'
import refresh from '../../../assets/old/refresh.svg'
// import today from "../../../assets/old/today2.svg";
import WaitOrder from '../../partials/WaitOrder.vue'
import FailedOrder from '../../partials/FailedOrder.vue'
import today from '../../../assets/icons/doors2.svg'
import Preloader from '../../Preloader.vue'
import OrderKeyNull from '../../partials/OrderKeyNull.vue'

import Sms from '../Sms'
let moment = require('moment')
import Vue from 'vue'
import {
  required,
  requiredUnless,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import VueNativeSock from 'vue-native-websocket'
import SumLengthNameMessageValidator from '../../../modules/SumLengthNameMessageValidator';

Vue.use(VueNativeSock, config.SOCKET, {
  connectManually: true,
})

export default {
  components: {
    Sms,
    WaitOrder,
    FailedOrder,
    Preloader,
    OrderKeyNull
  },
  data() {
    return {
      path: config.URL,
      close: close,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      info: info,
      oval: oval,
      today: today,
      refresh: refresh,
      numberStyle: {
        background: 'url("' + config.URL + oval + '") no-repeat center',
      },
      date: new Date(),
      name: '',
      inr: '',
      message: '',
      error: false,
      showSMS: false,
      errorSMStext: '',
      modalError: {
        show: false,
        text: '',
        ok: async() => {
          this.$router.push({ name: 'number_order' });
          this.serviceToday(0)
          this.serviceTodayNoCalling(0)
        },
        cancel: () => console.log('modal close')
      }
    }
  },
  computed: {
    prereservedModal(){
      return this.$store.getters.prereservedModal;
    },
    todayPrereservationId(){
      return this.$store.getters.todayPrereservationId;
    },
    getRequired() {
      return this.$store.getters.today.personal_data_required
    },
    nameMinLength() {
      return this.name.length > 2 ? false : true
    },
    nameMaxLength() {
      return this.name.length + this.message.length <= 512 ? false : true
    },
    inrMinLength() {
      return this.inr.replace(/\D/g, '').length > 8 ? false : true
    },
    inrMaxLength() {
      return this.inr.replace(/\D/g, '').length <= 12 ? false : true
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(512),
    },
    inr: {
      required: requiredUnless('getRequired'),
      minLength: minLength(9),
      maxLength: maxLength(512),
    },
    message: {
      maxLength: maxLength(512),
    },
  },
  methods: {
    async setTodayPrereservationId() {
    try {
      await this.$store.dispatch('setTodayPrereservationId', { name: this.$store.getters.name, number: this.$store.getters.today.nextfreenr })
    } catch (error) {
      console.error(error);
    }
    },
    sumLengthNameMessageValidator(type) {
      const value = SumLengthNameMessageValidator.check(this.name, this.message);
      
      if(type && type == 'name'){
        return value.allowed_name;
      }

      if(type && type == 'message'){
        return value.allowed_message;
      }

      return value.allowed;
    },
    closeTab() {
      this.$router.push({ name: 'home' })
      // this.$store.dispatch("setCurrentService", 0);
      this.$store.dispatch('setCurrentToday', 0)
      this.$store.dispatch('setCurrentTodayNoCalling', 0)
    },
    serviceToday(index) {
      this.$store.dispatch('setCurrentToday', index)
    },
    serviceTodayNoCalling(index) {
      if(!this.sumLengthNameMessageValidator()){ return; }
      if (index == 1) {
        this.$v.$touch()
        let formData = {}
        if (
          (this.$v.$invalid &&
           // !this.$store.getters.nextDays.personal_data_required &&
            this.$v.name.$error) ||
          (this.inrMinLength && this.$v.inr.required) ||
          (this.inrMinLength && this.inrMaxLength && this.$v.inr.required)
        ) {
          this.error = true
          let element = document.getElementsByClassName('submiterror')
          this.$nextTick(() => {
            element[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          })
          return false
        } else {
          this.error = false
          const message = this.message != '' ? ', ' + this.message : '';

          formData = {
            type: 'today',
           // infoservice: this.$store.getters.service,
            infoservice: false,
            name: this.name + message,
            pnr: this.inr,
          }

          this.$store.dispatch('placeOrder', formData)

          formData.cache_name = this.name;
          formData.pnr = this.inr;
          formData.cache_message = this.message;
          this.$store.dispatch('setCustomer', formData);

          this.$store.dispatch('setCurrentTodayNoCalling', index)
          setTimeout(() => {
            if(this.$store.state.placeorder.order_key){
              this.showSMS = true
            this.$connect(
              config.SOCKET + this.$store.getters.placeOrder.order_key
            )
            this.$options.sockets.onmessage = data => {
              if (JSON.parse(data.data).event == 'order_confirmed') {
                //this.$store.dispatch('getOrderStatus')
                this.$store.dispatch('setCurrentTodayNoCalling', 3)
              } else if (JSON.parse(data.data).event == 'order_ready') {
                this.$store.dispatch('getOrderStatus')
                this.$store.dispatch('setCurrentTodayNoCalling', 2)
                this.$disconnect()
              } else if (
                JSON.parse(data.data).event == 'order_payment_failed'
              ) {
                this.$store.dispatch('setCurrentTodayNoCalling', 4)
                this.$disconnect()
              } else if (
                JSON.parse(data.data).event == 'order_cancelled_due_to_reservation_shortage_today'
              ) {
                this.$disconnect()
                this.modalError.text = this.$t('order_cancelled_due_to_reservation_shortage_today')
                this.modalError.show = true
              } else {
                // console.log('error', JSON.parse(data.data).event)
              }
            }
          } else {
            this.errorSMStext = (this.$store.state.placeorder.err && this.$store.state.placeorder.err != '') ? this.$store.state.placeorder.err : 'ERROR_SMS_KEY';
          }
          }, 5000)
        }
      } else {
        this.$store.dispatch('setCurrentTodayNoCalling', index)
      }
    },
    refreshState() {
      this.$store.dispatch('postToday')
      this.date = new Date()
    },
  },
  async mounted () {
  await this.setTodayPrereservationId()
  this.name = (this.$store.getters.customer.cache_name) ? this.$store.getters.customer.cache_name : '';
  this.inr = (this.$store.getters.customer.pnr) ? this.$store.getters.customer.pnr : '';
  this.message = (this.$store.getters.customer.cache_message) ? this.$store.getters.customer.cache_message : '';
  },
  filters: {
    moment: date => {
      return moment(date).format('HH:mm:ss')
    },
  },
  watch: {
    '$store.getters.currentTodayNoCalling': function() {
      if (this.$store.getters.currentTodayNoCalling == 1) {
        var that = this
        setInterval(function() {
          that.refreshState()
        }, config.REFRESH)
      }
    },
    prereservedModal(newValue) {
      if(newValue){
        this.modalError.text = newValue
        this.modalError.show = true
      } else {
        this.modalError.text = ''
        this.modalError.show = false;
      }
    }
  },
}
</script>

<style scoped lang="scss"></style>
