var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"border-variant":"secondary","body-bg-variant":"secondary"}},[_c('div',{staticClass:"shop"},[_c('b-button',{staticClass:"po-left",attrs:{"id":"btn-back","variant":"link"},on:{"click":_vm.back}},[_c('b-img',{staticClass:"mr-2",attrs:{"src":_vm.path + _vm.angle_left_small,"alt":"angle_left_small"}}),_vm._v(_vm._s(_vm.$t('back')))],1),_c('div',{staticClass:"text-center title-margin"},[_c('p',{staticClass:"text-14 semi-bold text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.$t('shop_title'))+" ")]),_c('p',{staticClass:"text-18 semi-bold"},[_vm._v(" "+_vm._s(_vm.$t('shop_cart'))+" ")])]),_c('hr'),(
          !_vm.$store.getters.shopCart[_vm.$store.getters.name] ||
            _vm.$store.getters.shopCart[_vm.$store.getters.name].length === 0
        )?_c('div',{staticClass:"empty-cart"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('shop_cart_empty')))])]):_vm._e(),_c('div',{staticClass:"shop-cart"},[(
            _vm.$store.getters.shopCart[_vm.$store.getters.name] &&
              _vm.$store.getters.shopCart[_vm.$store.getters.name].length > 0
          )?_c('div',{staticClass:"cart-items"},_vm._l((_vm.$store.getters.shopCart[_vm.$store.getters.name]),function(item){return _c('div',{key:item.id,staticClass:"cart-item"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"cart-item-title"},[_c('a',{staticClass:"title",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                      name: 'shop_product',
                      params: { product_slug: item.alias },
                    })}}},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}})]),_c('div',{staticClass:"cart-item-right"},[_c('div',{staticClass:"cart-item-count"},[_c('b-form-spinbutton',{attrs:{"min":"1","max":"50","locale":"sk","size":"sm","formatter-fn":_vm.formatCount,"vertical":""},on:{"change":function($event){return _vm.changeCount(item)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})],1),_c('div',{staticClass:"cart-item-price"},[(
                      item.prices[0].price_with_tax_before_discount !==
                        item.prices[0].price_with_tax_after_discount
                    )?_c('span',{staticClass:"old text-14"},[_vm._v(_vm._s(item.prices[0].price_with_tax_before_discount)+" €")]):_vm._e(),(
                      item.prices[0].price_with_tax_before_discount !==
                        item.prices[0].price_with_tax_after_discount
                    )?_c('span',{staticClass:"new text-18 semi-bold"},[_vm._v(_vm._s(item.prices[0].price_with_tax_after_discount)+" €")]):_c('span',{staticClass:"text-18 semi-bold"},[_vm._v(_vm._s(item.prices[0].price_with_tax_after_discount)+" €")])]),_c('div',{staticClass:"cart-item-delete",on:{"click":function($event){return _vm.removeItem(item)}}},[_c('b-img',{attrs:{"src":_vm.path + _vm.close,"alt":"odobrat"}})],1)])])],1)}),0):_vm._e(),(
            _vm.$store.getters.shopCart[_vm.$store.getters.name] &&
              _vm.$store.getters.shopCart[_vm.$store.getters.name].length > 0
          )?_c('div',{staticClass:"cart-total"},[_c('p',{staticClass:"semi-bold"},[_vm._v(" "+_vm._s(_vm.$t('shop_cart_total'))+" "+_vm._s(_vm._f("formatTotal")(_vm.total))+" € ")]),_c('div',{staticClass:"price-disclaimer"},[_c('span',[_vm._v(_vm._s(_vm.$t('shop_prices_with_tax')))])])]):_vm._e(),_c('b-button',{staticClass:"btn-continue",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.back()}}},[_c('b-img',{staticClass:"mr-2",attrs:{"src":_vm.path + _vm.angle_left_small,"alt":"angle_left_small"}}),_vm._v(" "+_vm._s(_vm.$t('shop_cart_btn_continue'))+" ")],1),(
            _vm.$store.getters.shopCart[_vm.$store.getters.name] &&
              _vm.$store.getters.shopCart[_vm.$store.getters.name].length > 0
          )?_c('b-button',{staticClass:"btn-checkout",attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.toCheckout()}}},[_vm._v(" "+_vm._s(_vm.$t('shop_cart_btn_order'))+" "),_c('b-img',{staticClass:"ml-2",attrs:{"src":_vm.path + _vm.cart_white,"alt":"cart"}})],1):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }