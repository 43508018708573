<template>
  <div class="category-list">
    <div v-for="category in data" :key="category.id" class="category-list-item">
      <b-card
        @click="toCategory(category.slug)"
        class="overflow-hidden"
        no-body
      >
        {{ category.name }}
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  methods: {
    toCategory(slug) {
      this.$store.dispatch('setShopCategory', slug)
      // this.$store.dispatch('setCurrentShop', 1);

      this.$router.push({
        name: 'shop_category',
        params: { category_slug: slug },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.category-list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .category-list-item {
    display: block;
    width: 50%;
    padding-bottom: 20px;
    cursor: pointer;

    .card {
      background-color: #eef7fc !important;
      border: 2px solid #c5e1ef !important;
      padding: 40px 15px;
      text-align: center;
      color: #22376c;
      font-weight: 600;
    }

    &:nth-child(odd) {
      padding-right: 10px;
    }
    &:nth-child(even) {
      padding-left: 10px;
    }
    //margin: 5px;
    //margin-bottom: 10px;
  }
}
</style>
