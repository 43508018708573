<template>
  <div>
    <b-card v-if="$store.getters.placeOrder.sms_code !== '...'"
      border-variant="primary"
      body-bg-variant="primary"
      class="text-22 text-primary"
    >
      <p class="semi-bold mb-0">
        <span class="bold">{{ $store.getters.placeOrder.sms_keyword }}</span>
        <span>&nbsp;{{ $t('space') }}&nbsp;</span>
        <span class="bold">{{ $store.getters.placeOrder.sms_code }}</span>
      </p>
      <p class="semi-bold mb-0">
        <span>{{ $t('to_number') }}&nbsp;</span
        ><span class="bold">{{ $store.getters.placeOrder.sms_number }}</span>
      </p>
      <p class="d-lg-block d-none text-14 semi-bold mt-3">
        {{ $t('scan_qr') }}
      </p>
      <span class="d-lg-block d-none">
        <qrcode
          :value="
            'SMSTO:' +
              $store.getters.placeOrder.sms_number +
              ':' +
              $store.getters.placeOrder.sms_keyword +
              ' ' +
              $store.getters.placeOrder.sms_code
          "
          :options="{ width: 220, color: { light: '#eef7fc' } }"
        ></qrcode>
      </span>
    </b-card>
    <Preloader v-else />
    <p v-show="!isTimeSelected" class="text-12 semi-bold mt-3">
      {{ $t('price_sms', [price]) }}
    </p>
    <p v-show="isTimeSelected" class="text-12 semi-bold mt-3">
      {{ $t('time_price_sms', [time_price]) }}
    </p>
    <b-link v-if="$store.getters.placeOrder.sms_code !== '...'"
      class="d-inline-block d-lg-none btn btn-secondary btn-block btn-lg mb-2"
      :href="
        'sms:' +
          $store.getters.placeOrder.sms_number +
          type +
          'body=' +
          $store.getters.placeOrder.sms_keyword +
          ' ' +
          $store.getters.placeOrder.sms_code
      "
      >{{ $t('send_message')
      }}<b-img
        class="po-right"
        :src="path + angle_right"
        alt="order_recipe"
      ></b-img
    ></b-link>
  </div>
</template>

<script>
// import Vue from "vue";
import config from '../../../config'
import { isIOS, isMobile, isTablet } from 'mobile-device-detect'
import angle_right from '../../assets/old/angle_right.svg'
import Preloader from '../../components/Preloader.vue'
export default {
  data() {
    return {
      path: config.URL,
      angle_right: angle_right,
      type: isIOS && (isMobile || isTablet) ? '&' : '?',
      isTimeSelected: false,
      time_price: '0,00 €',
    }
  },
  components: {
Preloader
  },
  computed: {
    price() {
      let price = 0
      let type = this.$store.getters.type
      let service = this.$store.getters.service
      if (type == 'todayinfo') {
        price = this.$store.getters.today.infoprice
      } else if (type == 'today' && service) {
        price = this.$store.getters.today.infoserviceprice
      } else if (type == 'today' && !service) {
        price = this.$store.getters.today.infonoserviceprice
      } else if (type == 'nextdays' && this.isTimeSelected) {
        price = this.$store.getters.nextDays.timerequestprice
      } else if (type == 'nextdays' && service) {
        price = this.$store.getters.nextDays.infoserviceprice
      } else if (type == 'nextdays' && !service) {
        price = this.$store.getters.nextDays.infonoserviceprice
      } else if (type == 'recipe') {
        price = this.$store.getters.recipe.infoserviceprice
      } else if (type == 'result') {
        price = this.$store.getters.results.infoserviceprice
      } else if (type == 'enquiry') {
        price = this.$store.getters.enquiry.infoserviceprice
      } else {
        price = '0,00 €'
      }

      return price
    },
    // time_price() {
    //   let time_price = null;
    //   let type = this.$store.getters.type;
    //   if (type == "nextdays") {
    //     time_price = this.$store.getters.nextDays.timerequestprice;
    //   }
    //
    //   return time_price;
    // }
  },
  mounted() {
    // console.warn(this.$store.getters.isTimeRequest);
    this.isTimeSelected = this.$store.getters.isTimeRequest
  },
  // watch: {
  //   isTimeSelected: function(val) {
  //     console.warn(val);
  //     if (val === true) {
  //       this.time_price = this.$store.getters.nextDays.timerequestprice;
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss"></style>
