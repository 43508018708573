<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop shop-thank-you">
        <div class="text-center">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-14 semi-bold text-muted mb-0 title-margin">
            {{ $t('shop_title') }}
          </p>
          <p class="text-18 semi-bold">
            {{ $t('shop_thank_you') }}
          </p>
        </div>
        <hr />
        <p>{{ $t('shop_thank_you_email_send') }}</p>

        <div
          v-if="
            $route.query.iban &&
              $route.query.variable_symbol &&
              ($route.query.constant_symbol ||
                $route.query.constant_symbol === null)
          "
          class="card-body transfer-info bg-primary mb-4"
        >
          <p class="text-center">{{ $t('shop_transfer') }}</p>

          <div v-if="$route.query.by_square" class="qr-wrapper">
            <img :src="decodeQR($route.query.by_square)" alt="by_square_qr" />
          </div>

          <span v-if="$route.query.iban"
            ><b>{{ $t('shop_iban') }}</b> {{ $route.query.iban }}</span
          ><br />
          <span v-if="$route.query.variable_symbol"
            ><b>{{ $t('shop_variable_symbol') }}</b>
            {{ $route.query.variable_symbol }}</span
          ><br />
          <span
            v-if="
              $route.query.constant_symbol ||
                $route.query.constant_symbol === null
            "
            ><b>{{ $t('shop_constant_symbol') }}</b>
            {{
              $route.query.constant_symbol
                ? $route.query.constant_symbol
                : '(prázdne)'
            }}</span
          ><br />
          <span v-if="$route.query.price"
            ><b>{{ $t('shop_transfer_price') }}</b>
            {{ roundPrice($route.query.price) }}€</span
          >
        </div>

        <b-button block size="lg" @click="$router.push({ name: 'shop' })"
          >{{ $t('to_start')
          }}<b-img
            class="po-right"
            :src="path + angle_right"
            alt="order_recipe"
          ></b-img
        ></b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import angle_right from '../../../../assets/old/angle_right.svg'
import config from '../../../../../config'

export default {
  data() {
    return {
      path: config.URL,
      angle_right: angle_right,

      meta: {
        title: 'eČasenka.sk',
        description: '',
      },
    }
  },
  mounted() {
    // this.$emit('setSubtitle', this.$t('shop_thank_you'));
    this.$store.getters.doctor
      ? (this.meta['title'] =
          'Objednávka dokončená | ' +
          this.$store.getters.doctor.name +
          ' | eČasenka.sk')
      : ''
  },
  methods: {
    roundPrice(price) {
      return Math.round((Number(price) + Number.EPSILON) * 100) / 100
    },
    decodeQR(val) {
      return decodeURI(val)
    },
  },
  // watch: {
  // 	'$store.getters.doctor': {
  // 		handler: function (newValue) {
  // 			if (newValue) {
  // 				this.meta['title'] = 'Objednávka dokončená | ' + newValue.name + ' | eČasenka.sk';
  // 			}
  // 		}
  // 	}
  // },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail {
  .shop-thank-you::v-deep {
    .transfer-info {
      p {
        font-size: 17px;
        font-weight: 600;
      }
    }

    .qr-wrapper {
      display: flex;
      flex-direction: row;
      width: 40%;
      min-width: 170px;
      margin: 20px auto 30px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
