<template>
    <div>
        <div class="text-center">
            <b-img :src="path + image" alt=""></b-img>
            <p class="text-22 text-muted semi-bold mt-2 mb-0">
                {{ title }}
            </p>
            <p class="text-22 semi-bold">
                {{ subtitle }}
            </p>
        </div>
        <hr />
        <b-card border-variant="danger" body-bg-variant="danger" class="mb-3">
            <p style="color: white !important;" class="text-16 text-primary semi-bold mb-0 text-center">
                {{ $t('your_order_was_failed') }}
            </p>
            <p style="color: white !important;" class="text-16 text-primary semi-bold mb-0 text-center">
                {{ $t('your_order_was_failed_check_credit') }}
            </p>
        </b-card>
    </div>
</template>

<script>
import config from '../../../config'
import clock from '../../assets/old/clock.svg'

export default {
    data() {
        return {
            path: config.URL,
            clock_image: clock
        }
    },
    props: ['title', 'subtitle', 'image'],
}
</script>

<style></style>