<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop">
        <b-button id="btn-back" variant="link" class="po-left" v-on:click="back"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center title-margin">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-14 semi-bold text-muted mb-0">
            {{ $t('shop_title') }}
          </p>
          <p class="text-18 semi-bold">
            {{ title }}
          </p>
        </div>

        <div class="cart-link" @click="$router.push({ name: 'shop_cart' })">
          <b-img class="ml-2" :src="path + shopping_cart" alt="cart"></b-img>
          <span
            v-if="$store.getters.shopCart[$store.getters.name]"
            class="count"
            >{{ $store.getters.shopCart[$store.getters.name].length }}</span
          >
        </div>

        <hr />

        <div class="shop-category">
          <div v-if="loading" class="text-center my-4">
            <b-spinner variant="secondary" label="Načitáva sa ..."></b-spinner>
          </div>

          <p
            v-if="!loading && subCategories.length > 0"
            class="text-16 semi-bold mt-2"
          >
            {{ $t('shop_categories') }}
          </p>

          <SubCategoryList v-if="!loading" :data="subCategories" class="mb-3" />

          <div v-if="!loading && allProducts.length > 0" class="bg-product">
            <p class="text-16 semi-bold">
              {{ $t('shop_products') }}
            </p>

            <ProductList :data="allProducts" />
          </div>

          <div v-if="!loading && allProducts.length === 0">
            <p class="text-16 text-center">{{ $t('shop_category_empty') }}</p>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../../config'

import angle_left_small from '../../../../assets/old/angle_left_small.svg'
import angle_right from '../../../../assets/old/angle_right.svg'
import shopping_cart from '../../../../assets/icons/shopping_cart2.svg'

import SubCategoryList from './list/SubCategoryList'
import ProductList from './list/ProductList'

export default {
  components: {
    SubCategoryList,
    ProductList,
  },
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      angle_right: angle_right,
      shopping_cart: shopping_cart,
      title: '',
      meta: {
        title: 'eČasenka.sk',
        description: '',
      },
      loading: false,
      subCategories: [],
      allProducts: [],
    }
  },
  metaInfo() {
    return {
      title: this.meta.title,
      description: this.meta.description,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true

      axios
        .get(
          config.ESHOP_API +
            '/categories/' +
            this.$store.getters.name +
            '?parent=' +
            this.$route.params.category_slug
        )
        .then(response => {
          // console.warn(response);
          this.loading = false

          this.$store.dispatch(
            'setShopCategoryParent',
            response.data.category.parent_slug
              ? response.data.category.parent_slug
              : 0
          )

          this.title = this.$t('shop_category', {
            title: response.data.category.name,
          })
          this.meta['title'] =
            this.title +
            ' | ' +
            this.$store.getters.doctor.name +
            ' | eČasenka.sk'

          this.subCategories =
            response.data.categories.length > 0 ? response.data.categories : []
          this.allProducts =
            response.data.products.length > 0 ? response.data.products : []

          // console.warn(this.allProducts)

          // this.$emit('setSubtitle', this.$t('shop_category', {title: response.data.category.name}));
        })
        .catch(() => {
          // console.warn(err);
          this.loading = false

          this.subCategories = []
          this.allProducts = []
        })
    },
    back() {
      if (this.$store.getters.shopCategoryParent !== 0) {
        this.$store.dispatch(
          'setShopCategory',
          this.$store.getters.shopCategoryParent
        )
        this.$router.push({
          params: { category_slug: this.$store.getters.shopCategoryParent },
        })
      } else {
        this.$store.dispatch('setShopCategory', 0)
        this.$store.dispatch('setShopCategoryParent', 0)
        this.$router.push({ name: 'shop' })
      }
    },
  },
  watch: {
    '$route.params.category_slug': function() {
      // console.warn('category reload');
      this.getData()
    },
  },
}
</script>

<style scoped lang="scss"></style>
