<template>
  <div class="searchTimeout-results">
    <div v-if="loading" class="text-center my-4">
      <b-spinner variant="secondary" label="Načitáva sa ..."></b-spinner>
    </div>

    <p v-if="categories.length > 0" class="text-16 semi-bold mt-3">
      {{ $t('shop_categories') }}
    </p>

    <SubCategoryList :data="categories" class="mb-3" />

    <div v-if="products.length > 0" class="bg-product">
      <p class="text-16 semi-bold">
        {{ $t('shop_products') }}
      </p>

      <ProductList :data="products" />
    </div>

    <div v-if="products.length === 0 && categories.length === 0">
      <p class="text-center text-14">{{ $t('shop_search_not_found') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../../../config'

import ProductList from './ProductList'
import SubCategoryList from './SubCategoryList'

export default {
  data() {
    return {
      wait: false,
      loading: false,
      products: [],
      categories: [],
    }
  },
  components: {
    ProductList,
    SubCategoryList,
  },
  props: {
    query: {
      type: String,
      default: null,
    },
  },
  methods: {
    searchTimeout(query) {
      if (query) {
        if (query.length > 2) {
          if (this.wait) {
            clearTimeout(this.wait)
            this.wait = null
            this.loading = true
          }
          let self = this
          this.wait = setTimeout(() => {
            self.search(query)
          }, 500)
        }
      }
    },
    search(query) {
      axios
        .get(
          config.ESHOP_API +
            '/search?phrase=' +
            query +
            '&row=' +
            this.$store.getters.name
        )
        .then(
          response => {
            // console.warn(response);
            this.loading = false
            this.products =
              response.data.products.length > 0 ? response.data.products : []
            this.categories =
              response.data.categories.length > 0
                ? response.data.categories
                : []
          },
          () => {
            // console.error(err);
            this.loading = false
          }
        )
    },
  },
  watch: {
    query: function(newQuery) {
      // console.warn('changed', newQuery);
      this.searchTimeout(newQuery)
    },
  },
}
</script>

<style scoped></style>
