<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div v-if="$store.getters.currentToday == 0" class="today">
        <b-button
          id="btn-back"
          variant="link"
          class="po-left"
          v-on:click="back()"
          ><b-img class="mr-2" :src="path + angle_left_small" alt="spat"></b-img
          >{{ $t('back') }}</b-button
        >
        <b-button
          id="btn-close"
          variant="link"
          class="po-right"
          v-on:click="closeTab()"
        >
          {{ $t('close') }}
          <b-img class="ml-1" :src="path + close" alt="x"></b-img>
        </b-button>
        <div class="text-center">
          <b-img :src="path + today" alt="today"></b-img>
          <p class="text-22 semi-bold mt-2">
            {{ $t('today_title') }}
          </p>
        </div>
        <hr />
        <b-card border-variant="primary" body-bg-variant="primary" class="mb-3">
          <p
            v-if="$store.getters.today.entrynote != null"
            class="text-14 text-primary semi-bold mb-0"
          >
            {{ $store.getters.today.entrynote }}
          </p>
          <div class="d-flex justify-content-between mt-2">
            <div class="text-center">
              <p class="text-22 text-secondary bold mb-0">
                {{
                  $store.getters.today.currentnr !== null
                    ? $store.getters.today.currentnr
                    : '?'
                }}
              </p>
              <p class="text-12 semi-bold mb-0">
                {{ $t('equipped_number') }}
              </p>
            </div>
            <div class="text-center">
              <p class="text-22 text-secondary bold mb-0">
                {{ $store.getters.today.nextfreenr }}
              </p>
              <p class="text-12 semi-bold mb-0">
                {{ $t('issue_number') }}
              </p>
            </div>
            <div class="text-center">
              <p class="text-22 text-secondary bold mb-0">
                {{ date | moment }}
              </p>
              <p class="text-12 semi-bold mb-0">
                {{ $t('updated') }}
              </p>
            </div>
          </div>
        </b-card>
        <b-media
          :class="{
            disabled:
              !$store.getters.today.entryallowed ||
              !$store.getters.doctor.todayisworking,
          }"
          right-align
          vertical-align="center"
          class="p-3 mb-3"
          v-on:click="service_today(2)"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 text-white semi-bold mb-1">
              {{ $t('put_calling') }}
            </p>
            <p class="text-12 text-white mb-1">
              {{ $t('put_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{ $t('service_price', [$store.getters.today.infoserviceprice]) }}
            </p>
          </div>
        </b-media>
        <b-media
          :class="{
            disabled:
              !$store.getters.today.entryallowed ||
              !$store.getters.doctor.todayisworking,
          }"
          right-align
          vertical-align="center"
          class="p-3 mb-3"
          v-on:click="service_today(3)"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 text-white semi-bold mb-1">
              {{ $t('put_no_calling') }}
            </p>
            <p class="text-12 text-white mb-1">
              {{ $t('put_no_calling_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{
                $t('service_price', [$store.getters.today.infonoserviceprice])
              }}
            </p>
          </div>
        </b-media>
        <b-media
          :class="{
            disabled:
              // !$store.getters.today.entryallowed ||
              !$store.getters.doctor.todayisworking,
          }"
          right-align
          vertical-align="center"
          class="p-3"
          v-on:click="service_today(1)"
        >
          <template v-slot:aside>
            <b-img :src="path + angle_right" alt="angle_right"></b-img>
          </template>
          <div class="mr-3">
            <p class="text-22 text-white semi-bold mb-1">
              {{ $t('what_number') }}
            </p>
            <p class="text-12 text-white mb-1">
              {{ $t('what_number_subtitle') }}
            </p>
            <p class="text-price-light mb-0">
              {{ $t('service_price', [$store.getters.today.infoprice]) }}
            </p>
          </div>
        </b-media>
      </div>
      <ServiceTodayNumber v-if="$store.getters.currentToday == 1" />
      <ServiceTodayCalling v-if="$store.getters.currentToday == 2" />
      <ServiceTodayNoCalling v-if="$store.getters.currentToday == 3" />
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../config'
let moment = require('moment')
import close from '../../../assets/icons/close.svg'
// import today from "../../../assets/old/today2.svg";
import today from '../../../assets/icons/doors2.svg'
import angle_right from '../../../assets/old/angle_right.svg'
import angle_left_small from '../../../assets/old/angle_left_small.svg'
import ServiceTodayNumber from './ServiceTodayNumber'
import ServiceTodayCalling from './ServiceTodayCalling'
import ServiceTodayNoCalling from './ServiceTodayNoCalling'

export default {
  components: {
    ServiceTodayNumber,
    ServiceTodayCalling,
    ServiceTodayNoCalling,
  },
  data() {
    return {
      path: config.URL,
      close: close,
      today: today,
      date: new Date(),
      angle_right: angle_right,
      angle_left_small: angle_left_small,
    }
  },
  mounted() {
    this.getToday()
  },
  updated() {
    this.$store.dispatch('checkBack')
  },
  computed: {
    error() {
      return this.$store.getters.error
    },
  },
  methods: {
    getToday() {
      if (this.$store.getters.placeOrder.order_id) {
        axios
          .post(
            config.API + '/today/' + this.$store.getters.name,
            JSON.stringify({
              order_id: this.$store.getters.placeOrder.order_id,
              order_key: this.$store.getters.placeOrder.order_key,
            })
          )
          .then(response => {
            this.$store.dispatch('setToday', response.data)
          })
      } else {
        axios
          .get(config.API + '/today/' + this.$store.getters.name)
          .then(response => {
            this.$store.dispatch('setToday', response.data)
          })
      }
    },
    back() {
      // this.$store.dispatch("setCurrentService", 6);
      this.$router.push({ name: 'number_order' })
    },
    closeTab() {
      // this.$store.dispatch("setCurrentService", 6);
      this.$router.push({ name: 'home' })
    },
    async service_today(index) {
      this.$store.dispatch('setCurrentToday', index)
      switch (index) {
        case 1:
          this.$store.dispatch('placeOrder', { type: 'todayinfo' })
          break
        case 2:
          /*this.$store.dispatch('placeOrder', {
            type: 'today',
            infoservice: true,
          })*/
          break
        case 3:
          /*this.$store.dispatch('placeOrder', {
            type: 'today',
            infoservice: false,
          })*/
          break
      }
    },
  },
  filters: {
    moment: date => {
      return moment(date).format('HH:mm:ss')
    },
  },
}
</script>

<style scoped lang="scss"></style>
