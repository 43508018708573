<template>
  <b-card border-variant="danger" body-text-variant="dark">
    <div
      ref="notice"
      :class="{ maxHeight: $store.getters.maxHeight }"
      class="text-14 semi-bold"
      id="notice"
    >
      <b-img
        class="notice float-left mr-3"
        :src="path + notice"
        alt="notice"
      ></b-img>
      <ContentLoader v-if="$store.getters.lazy" height="50">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
        <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      </ContentLoader>
      <p v-if="!$store.getters.lazy" class="mb-0">
        {{
          $store.getters.doctor.notice || $store.getters.doctor.notice2
            ? $store.getters.doctor.notice
            : $t('no_notice')
        }}
      </p>
      <p
        v-if="!$store.getters.lazy && $store.getters.doctor.notice2"
        class="mb-0"
      >
        {{ $store.getters.doctor.notice2 }}
      </p>
    </div>
    <b-button
      v-if="$store.getters.maxHeight"
      variant="link"
      class="show-more text-14"
      v-on:click="show"
      >{{ $t('show_more')
      }}<b-img :src="path + angle_down" alt="angle_down" class="mb-0"></b-img
    ></b-button>
    <!-- <b-button
			v-if="!$store.getters.maxHeight"
			variant="link"
			class="show-more text-14"
			v-on:click="show"
			>{{ $t("show_less")
			}}<b-img
				:src="path + angle_down"
				alt="angle_down"
				class="less mb-0"
			></b-img
		></b-button> -->
  </b-card>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import config from '../../config'
import notice from '../assets/old/notice.svg'
import angle_down from '../assets/old/angle_down_small.svg'
export default {
  data() {
    return {
      path: config.URL,
      notice: notice,
      angle_down: angle_down,
    }
  },
  components: {
    ContentLoader,
  },
  methods: {
    matchHeight() {
      let height = this.$refs.notice.clientHeight
      height > 61
        ? this.$store.commit('setMaxHeight', true)
        : this.$store.commit('setMaxHeight', false)
    },
    show() {
      this.$store.commit('setMaxHeight', false)
    },
  },
  watch: {
    '$store.getters.doctor.notice': function(val) {
      if (val) {
        document.onreadystatechange = () => {
          if (document.readyState == 'complete') {
            this.matchHeight()
          }
        }
      }
    },
    '$store.getters.doctor.notice2': function(val) {
      if (val) {
        document.onreadystatechange = () => {
          if (document.readyState == 'complete') {
            this.matchHeight()
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.notice {
  margin-top: 4px;
}

.maxHeight {
  max-height: 60px;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.show-more {
  position: absolute;
  right: -10px;
  bottom: -8px;
  font-size: 14px !important;

  img {
    margin-left: 3px;
  }
}

// .less {
// 	transform: rotate(180deg);
// }
</style>
