var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"border-variant":"secondary","body-bg-variant":"secondary"}},[_c('div',{staticClass:"shop shop-thank-you"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-14 semi-bold text-muted mb-0 title-margin"},[_vm._v(" "+_vm._s(_vm.$t('shop_title'))+" ")]),_c('p',{staticClass:"text-18 semi-bold"},[_vm._v(" "+_vm._s(_vm.$t('shop_thank_you'))+" ")])]),_c('hr'),_c('p',[_vm._v(_vm._s(_vm.$t('shop_thank_you_email_send')))]),(
          _vm.$route.query.iban &&
            _vm.$route.query.variable_symbol &&
            (_vm.$route.query.constant_symbol ||
              _vm.$route.query.constant_symbol === null)
        )?_c('div',{staticClass:"card-body transfer-info bg-primary mb-4"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('shop_transfer')))]),(_vm.$route.query.by_square)?_c('div',{staticClass:"qr-wrapper"},[_c('img',{attrs:{"src":_vm.decodeQR(_vm.$route.query.by_square),"alt":"by_square_qr"}})]):_vm._e(),(_vm.$route.query.iban)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('shop_iban')))]),_vm._v(" "+_vm._s(_vm.$route.query.iban))]):_vm._e(),_c('br'),(_vm.$route.query.variable_symbol)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('shop_variable_symbol')))]),_vm._v(" "+_vm._s(_vm.$route.query.variable_symbol))]):_vm._e(),_c('br'),(
            _vm.$route.query.constant_symbol ||
              _vm.$route.query.constant_symbol === null
          )?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('shop_constant_symbol')))]),_vm._v(" "+_vm._s(_vm.$route.query.constant_symbol ? _vm.$route.query.constant_symbol : '(prázdne)'))]):_vm._e(),_c('br'),(_vm.$route.query.price)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('shop_transfer_price')))]),_vm._v(" "+_vm._s(_vm.roundPrice(_vm.$route.query.price))+"€")]):_vm._e()]):_vm._e(),_c('b-button',{attrs:{"block":"","size":"lg"},on:{"click":function($event){return _vm.$router.push({ name: 'shop' })}}},[_vm._v(_vm._s(_vm.$t('to_start'))),_c('b-img',{staticClass:"po-right",attrs:{"src":_vm.path + _vm.angle_right,"alt":"order_recipe"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }