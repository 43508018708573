var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.getters.currentService != 3 && _vm.$store.getters.currentService != 2
  )?_c('b-card',{class:{ mt: _vm.$store.getters.currentService !== 0 },attrs:{"border-variant":"primary","body-bg-variant":"primary"}},[(_vm.$store.getters.lazy)?_c('ContentLoader',{attrs:{"height":"50"}},[_c('rect',{attrs:{"x":"0","y":"0","rx":"3","ry":"3","width":"250","height":"10"}}),_c('rect',{attrs:{"x":"20","y":"20","rx":"3","ry":"3","width":"220","height":"10"}}),_c('rect',{attrs:{"x":"20","y":"40","rx":"3","ry":"3","width":"170","height":"10"}}),_c('rect',{attrs:{"x":"0","y":"60","rx":"3","ry":"3","width":"250","height":"10"}}),_c('rect',{attrs:{"x":"20","y":"80","rx":"3","ry":"3","width":"200","height":"10"}}),_c('rect',{attrs:{"x":"20","y":"100","rx":"3","ry":"3","width":"80","height":"10"}})]):_vm._e(),(
      _vm.$store.getters.doctor.todayisworking &&
        !_vm.$store.getters.lazy &&
        _vm.$store.getters.currentService != 3 &&
        _vm.$store.getters.currentService != 2
    )?_c('b-img',{staticClass:"work float-left mr-3",attrs:{"src":_vm.path + _vm.ok,"alt":"ordinuje"}}):_vm._e(),(
      !_vm.$store.getters.doctor.todayisworking &&
        !_vm.$store.getters.lazy &&
        _vm.$store.getters.currentService != 3 &&
        _vm.$store.getters.currentService != 2
    )?_c('b-img',{staticClass:"work float-left mr-3",attrs:{"src":_vm.path + _vm.close_red,"alt":"neordinuje"}}):_vm._e(),(
      _vm.$store.getters.currentService != 3 && _vm.$store.getters.currentService != 2
    )?_c('p',{staticClass:"title text-18 semi-bold mb-3",class:{ red: !_vm.$store.getters.doctor.todayisworking }},[_vm._v(" "+_vm._s(_vm.$store.getters.doctor.todayisworkingtext)+" ")]):_vm._e(),(!_vm.$store.getters.lazy && _vm.$store.getters.doctor.address)?_c('b-img',{staticClass:"address float-left mr-3",attrs:{"src":_vm.path + _vm.map_marker,"alt":"adresa"}}):_vm._e(),(_vm.$store.getters.doctor.address)?_c('p',{staticClass:"mb-2 text-16 semi-bold text-dark"},[_vm._v(" "+_vm._s(_vm.$store.getters.doctor.address)+" ")]):_vm._e(),_c('p',{staticClass:"show-map"},[_c('b-link',{staticClass:"text-15 text-uppercase text-secondary",attrs:{"href":_vm.google + _vm.$store.getters.doctor.address,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('show_map'))+" ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }