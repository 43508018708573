import config from '../../config'

/*
Import module to component example:
-----------------------------------
import { CheckApiUrl } from './modules/CheckApiUrlModule';

Use example in vue function:
----------------------------
let url = CheckApiUrl.check('/enquiry_v2/')
*/

class CheckApiUrlClass {
  constructor() {}

  check($path, $type = '') {
    let url = config.API + $path

    if (config.ALLOW_NEW_API == true) {
      const xurl = window.location.pathname
        .trim()
        .replace(config.ROUTE_PREFIX + '/', '')
        .split('/')
      const regex = /doctor_id/ // Create a regular expression
      if (regex.test(xurl[0])) {
        let doctor_id = xurl[0].replace('doctor_id_', '')
        url = config.API_NEW + '/legacy/branches/' + doctor_id.trim().toString()

        if ($type == 'placeorder') {
          url = config.API_NEW + '/orders/medical-prescriptions'
        }
      }
    }
    return url
  }

  check_socket($path = '') {
    let url = config.SOCKET + $path

    if (config.ALLOW_NEW_API == true) {
     if(check_new_api_doctor() == true){
      url = config.SOCKET_NEW + $path
     }
    }
    return url
  }

  sanitise_post_request($data, $type = '') {
    if (check_new_api_doctor() == true) {
      if ($type == '') {
        return JSON.stringify($data)
      }
      if ($type == 'placeorder') {
        /* old: {"type":"recipe","name":"testname","pnr":"1234567890","medicine":"testtext"} */
        /* new: { "branchID": 1, "patientFullname": "John Doe", "patientIdentificationNumber": "93020392838", "medicineName": "Ibalgin" } */
        let $new_data = {}
        $new_data.branchID = get_current_doctor_id()
        $new_data.patientFullname = $data.name
        $new_data.patientIdentificationNumber = $data.pnr
        $new_data.medicineName = $data.medicine
        return $new_data
      }
    } else {
      return JSON.stringify($data)
    }
  }

  is_new_api_doctor() {
    return check_new_api_doctor()
  }
}

function check_new_api_doctor() {
  const xurl = window.location.pathname
    .trim()
    .replace(config.ROUTE_PREFIX + '/', '')
  const regex = /doctor_id/ // Create a regular expression
  if (regex.test(xurl)) {
    return true
  }
  return false
}

function get_current_doctor_id(){
  let doctor = 0
  const xurl = window.location.pathname
  .trim()
  .replace(config.ROUTE_PREFIX + '/', '')
  .split('/')
const regex = /doctor_id/ // Create a regular expression
if (regex.test(xurl[0])) {
  let doctor_id = xurl[0].replace('doctor_id_', '')
  doctor= doctor_id.trim()
}
return doctor
}

let CheckApiUrl = new CheckApiUrlClass()

export { CheckApiUrl }
