import config from '../config'
import ListServices from '@/components/services/ListServices'
import ServiceEnquiry from '@/components/services/ServiceEnquiry'
import ListOtherServices from '@/components/services/ListOtherServices'
import ListNumberOrder from '@/components/services/ListNumberOrder'
import ServiceToday from '@/components/services/number_order/ServiceToday'
import ServiceNextDays from '@/components/services/number_order/ServiceNextDays'
import ServiceRecipe from '@/components/services/other_services/ServiceRecipe'
import ServiceResults from '@/components/services/other_services/ServiceResults'
import ShopHome from '@/components/services/other_services/shop/ShopHome'
import ShopCategory from '@/components/services/other_services/shop/ShopCategory'
import ShopProduct from '@/components/services/other_services/shop/ShopProduct'
import ShopCart from '@/components/services/other_services/shop/ShopCart'
import ShopCheckout from '@/components/services/other_services/shop/ShopCheckout'
// import ShopPayment from "@/components/services/other_services/shop/ShopPayment";
import ShopThankYou from '@/components/services/other_services/shop/ShopThankYou'

export default [
  {
    name: 'home',
    path: config.ROUTE_PREFIX + '/:slug',
    component: ListServices,
  },
  {
    name: 'enquiry',
    path: config.ROUTE_PREFIX + '/:slug/dotazniky',
    component: ServiceEnquiry,
  },
  {
    name: 'number_order',
    path: config.ROUTE_PREFIX + '/:slug/vstup',
    component: ListNumberOrder,
  },
  {
    name: 'number_today',
    path: config.ROUTE_PREFIX + '/:slug/vstup/dnes',
    component: ServiceToday,
  },
  {
    name: 'number_next',
    path: config.ROUTE_PREFIX + '/:slug/vstup/iny-den',
    component: ServiceNextDays,
  },
  {
    name: 'other_services',
    path: config.ROUTE_PREFIX + '/:slug/sluzby',
    component: ListOtherServices,
  },
  {
    name: 'recipe',
    path: config.ROUTE_PREFIX + '/:slug/sluzby/recept',
    component: ServiceRecipe,
  },
  {
    name: 'results',
    path: config.ROUTE_PREFIX + '/:slug/sluzby/vysledky',
    component: ServiceResults,
  },
  {
    name: 'shop',
    path: config.ROUTE_PREFIX + '/:slug/sluzby/nadstandardne',
    component: ShopHome,
  },
  {
    name: 'shop_category',
    path:
      config.ROUTE_PREFIX +
      '/:slug/sluzby/nadstandardne/kategoria/:category_slug',
    component: ShopCategory,
  },
  {
    name: 'shop_product',
    path:
      config.ROUTE_PREFIX + '/:slug/sluzby/nadstandardne/sluzba/:product_slug',
    component: ShopProduct,
  },
  {
    name: 'shop_cart',
    path: config.ROUTE_PREFIX + '/:slug/sluzby/nadstandardne/kosik',
    component: ShopCart,
  },
  {
    name: 'shop_checkout',
    path: config.ROUTE_PREFIX + '/:slug/sluzby/nadstandardne/objednavka',
    component: ShopCheckout,
  },
  // {
  //     name: 'shop_payment',
  //     path: '/:slug/sluzby/nadstandardne/platba',
  //     component: ShopPayment,
  // },
  {
    name: 'shop_thank_you',
    path:
      config.ROUTE_PREFIX + '/:slug/sluzby/nadstandardne/objednavka/dokoncena',
    component: ShopThankYou,
  },
  {
    name: 'dotazniky_detail',
    path: config.ROUTE_PREFIX + '/:slug/dotazniky/detail/:enquirydetail',
    component: ServiceEnquiry,
  },
  { path: '*', component: ListServices },
]
