<template>
  <b-card
    v-if="
      $store.getters.currentService != 3 && $store.getters.currentService != 2
    "
    border-variant="primary"
    body-bg-variant="primary"
    :class="{ mt: $store.getters.currentService !== 0 }"
  >
    <ContentLoader v-if="$store.getters.lazy" height="50">
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </ContentLoader>
    <b-img
      v-if="
        $store.getters.doctor.todayisworking &&
          !$store.getters.lazy &&
          $store.getters.currentService != 3 &&
          $store.getters.currentService != 2
      "
      class="work float-left mr-3"
      :src="path + ok"
      alt="ordinuje"
    ></b-img
    ><b-img
      v-if="
        !$store.getters.doctor.todayisworking &&
          !$store.getters.lazy &&
          $store.getters.currentService != 3 &&
          $store.getters.currentService != 2
      "
      class="work float-left mr-3"
      :src="path + close_red"
      alt="neordinuje"
    ></b-img>
    <p
      v-if="
        $store.getters.currentService != 3 && $store.getters.currentService != 2
      "
      class="title text-18 semi-bold mb-3"
      :class="{ red: !$store.getters.doctor.todayisworking }"
    >
      {{ $store.getters.doctor.todayisworkingtext }}
    </p>
    <b-img
      v-if="!$store.getters.lazy && $store.getters.doctor.address"
      class="address float-left mr-3"
      :src="path + map_marker"
      alt="adresa"
    ></b-img>
    <p
      v-if="$store.getters.doctor.address"
      class="mb-2 text-16 semi-bold text-dark"
    >
      {{ $store.getters.doctor.address }}
    </p>
    <p class="show-map">
      <b-link
        :href="google + $store.getters.doctor.address"
        target="_blank"
        class="text-15 text-uppercase text-secondary"
      >
        {{ $t('show_map') }}
      </b-link>
    </p>
  </b-card>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import config from '../../config'
import ok from '../assets/old/ok.svg'
import close_red from '../assets/old/close_red.svg'
import map_marker from '../assets/icons/map_marker.svg'
export default {
  data() {
    return {
      path: config.URL,
      google: config.GOOGLE,
      ok: ok,
      close_red: close_red,
      map_marker: map_marker,
    }
  },
  components: {
    ContentLoader,
  },
}
</script>

<style scoped lang="scss">
.title {
  color: #00b925;
  &.red {
    color: #ff4747;
  }
}

.work {
  margin-top: 4px;
}

.address {
  width: 20px;
  height: 20px;
}

.show-map {
  margin-left: 37px;
  margin-bottom: 0 !important;
}

.mt {
  @media (min-width: 576px) {
    margin-top: -20px;
  }

  .card-body {
    padding-top: 0;
  }
}
</style>
