class SumLengthNameMessageValidator {
    constructor() { }

    check(name = null, message = null, maxLength = 1024, nameMaxLength = 512, messageMaxLength = 512) {
       
        let ret = {
            allowed: true,
            allowed_name: true,
            allowed_message: true
        };

        if(name.length > nameMaxLength){ ret.allowed_name = false }
        if(message.length > messageMaxLength){ ret.allowed_message = false }
  
        const sumLength = name.length + message.length;
        //console.log(sumLength);
        ret.allowed = ((sumLength > maxLength) || !ret.allowed_name || !ret.allowed_message) ? false : true

        return ret;
      }
}

export default new SumLengthNameMessageValidator();
  