<template>
  <div class="info-about-doctor">
    <ContentLoader v-if="$store.getters.lazy" height="70">
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <!-- <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
				  <rect x="20" y="100" rx="3" ry="3" width="200" height="10" /> -->
    </ContentLoader>

    <b-row>
      <b-col cols="3">
        <div class="avatar-wrapper">
          <img
            class="avatar"
            src="https://ecasenka.sk/wp-content/search-images/img/doctor.6ef146a6.svg"
            alt="doctor_photo"
          />
        </div>
      </b-col>
      <b-col cols="9">
        <h1 class="name text-secondary semi-bold mb-1">
          {{ $store.getters.doctor.name }}
        </h1>
        <h2
          v-if="$store.getters.currentService == 0"
          class="type text-16 semi-bold mb-0 text-dark"
        >
          {{ $store.getters.doctor.type }}
        </h2>
      </b-col>
    </b-row>
  </div>
  <!--  <b-card border-variant="none" body-bg-variant="primary">-->
  <!--    <ContentLoader v-if="$store.getters.lazy" height="70">-->
  <!--      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />-->
  <!--      <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />-->
  <!--      <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />-->
  <!--      <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />-->
  <!--      &lt;!&ndash; <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />-->
  <!--			<rect x="20" y="100" rx="3" ry="3" width="200" height="10" /> &ndash;&gt;-->
  <!--    </ContentLoader>-->
  <!--    <p class="text-24 text-secondary semi-bold text-24 mb-0">-->
  <!--      {{ $store.getters.doctor.name }}-->
  <!--    </p>-->
  <!--    <p-->
  <!--      v-if="$store.getters.currentService == 0"-->
  <!--      class="text-18 semi-bold mb-0 textt-dark"-->
  <!--    >-->
  <!--      {{ $store.getters.doctor.type }}-->
  <!--    </p>-->
  <!--    <p v-if="$store.getters.currentService == 0" class="mb-0">-->
  <!--      <b-link-->
  <!--        :href="google + $store.getters.doctor.address"-->
  <!--        target="_blank"-->
  <!--        class="text-18 semi-bold"-->
  <!--        >{{ $store.getters.doctor.address }}</b-link-->
  <!--      >-->
  <!--    </p>-->
  <!--  </b-card>-->
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import config from '../../config'
import user from '../assets/old/user.png'
import pin from '../assets/old/pin.svg'
export default {
  data() {
    return {
      google: config.GOOGLE,
      path: config.URL,
      user: user,
      pin: pin,
    }
  },
  components: {
    ContentLoader,
  },
}
</script>

<style scoped lang="scss">
a {
  color: #000000;
}

.info-about-doctor {
  width: 100%;
  .avatar-wrapper {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 7px 15px #0000000f;
  }

  .name {
    font-size: 22px;

    @media (max-width: 578px) {
      font-size: 20px;
    }
  }
}
</style>
