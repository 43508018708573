<template>
  <b-modal id="modal" ref="modal" size="md" hide-header ok-title="Pokračovať" cancel-title="Zrušiť" @cancel="cancel"
    no-close-on-esc no-close-on-backdrop centered scrollable :static="true">
    <template v-slot:modal-footer="{ ok, cancel }">
      <p class="agreement my-sm-4 d-grid justify-content-center">
        <span class="d-flex align-items-center justify-content-center">
        <label class="checkbox">
          Súhlasím s vyššie uvedenými podmienkami
          <input type="checkbox" style="display:none;" v-model="isCheckedNext" />
          <span class="checkmark"></span>
        </label>
      </span>
      </p>
      <div class="modal-buttons">
        <div class="button-ok button-cont p-2">
          <button type="button" class="btn btn-green" @click="ok()" :disabled="!isCheckedNext">
            Pokračovať
          </button>
        </div>
        <div class="button-cancel button-cont p-2">
          <button type="button" class="btn btn-secondary btn-cancel" @click="cancel()">
            Zrušiť
          </button>
        </div>
      </div>
    </template>

      <body>
        <h2 id="_inform" class="inform_title mt-3 mt-sm-5 mb-3 mb-sm-4">
          Súhlas so Všeobecnými obchodnými podmienkami a so spracovaním osobných údajov
        </h2>
        <div class="sectionbody">
            <p class="mb-sm-4">
                Udeľujem spoločnosti eČasenka, s.r.o. výslovný <a :href="urlhome + '/suhlas-so-spracovanim-osobnych-udajov/'" target="_blank">„Súhlas so spracovaním osobných údajov“</a> pri poskytovaní služieb, na základe ktorého spoločnosť eČasenka informuje lekára, u ktorého žiadam poskytnúť jednu zo služieb, o mojej totožnosti a výslovne potvrdzujem, že som sa oboznámil s informáciami uvedenými v tomto súhlase.
              </p>
          <p class="mb-sm-4">
            Oboznámil som sa s <a :href="urlhome + '/ochrana-osobnych-udajov'"
              target="_blank">„Politikou ochrany osobných údajov“</a> a oboznámil som sa a výslovne súhlasím so <a
              :href="urlhome + '/vseobecne-obchodne-podmienky'" target="_blank">„Všeobecnými obchodnými podmienkami“</a> spoločnosti eČasenka, s.r.o. vzťahujúcimi sa na poskytovanie služieb.
          </p>
        </div>
      </body>
  </b-modal>
</template>
  
<script>
import '../assets/custom.scss'
import config from '../../config'

export default {
  data() {
    return {
      isCheckedNext: false,
      urlhome: config.URLHOME,
    }
  },
  mounted() {
    if (this.$route.name !== 'shop_thank_you') {
      this.$refs['modal'].show()
    }
    console.log(this.$route.name)
  },
  methods: {
    cancel() {
      location.href = config.URLHOME
    },
    ok() {
      this.$bvModal.hide("modal")
    }
  },
}
</script>
<style language="scss">
/* Breakpoints */
@media (min-width: 576px) {
  #ecasenkaDetail .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }

  #ecasenkaDetail .modal-dialog .modal-content {
    padding: 1em;
  }

  #ecasenkaDetail .modal-dialog .sectionbody p {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

}

@media (max-width: 576px) {
  #ecasenkaDetail .modal-dialog-centered.modal-dialog-scrollable {
    justify-content: start !important;

  }

  #ecasenkaDetail .modal-dialog {
    max-height: calc(85vh) !important;
  }

  #ecasenkaDetail .modal-dialog-centered.modal-dialog-scrollable {
    margin-top: 5px !important;
    height: calc(100% - 10px) !important;
  }

  #ecasenkaDetail .modal-dialog h2{
    font-size: 14pt !important;
  }

  #ecasenkaDetail .modal-dialog .sectionbody p {
  margin-bottom: 5px;
}

#ecasenkaDetail .modal-dialog .modal-footer {
padding-top: 10px !important;
padding-bottom: 5px !important;
}

 #ecasenkaDetail .modal-footer .button-cont {
padding-top: 0px !important;
padding-bottom: 0px !important;
}

#ecasenkaDetail .modal-dialog .modal-footer label.checkbox{ margin-bottom: 0px !important; }
}

/* Others */
#ecasenkaDetail .modal-dialog a {
  color: #22376C !important;
}

/* Modal */

#ecasenkaDetail .modal-dialog body {
  text-align: center;
}

#ecasenkaDetail .modal-dialog .modal-content {
  /* border-radius: 0.65rem; */
  border: none;
  -webkit-box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.29);
}

#ecasenkaDetail .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  border-radius: 0.65rem;
}

#ecasenkaDetail .modal-dialog h2,
#ecasenkaDetail .modal-dialog .h2 {
  color: #22376C;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}

#ecasenkaDetail .modal.fade {
  /* Glass */
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
}

#ecasenkaDetail .modal-dialog p {
  color: #7a7a7a;
  font-size: 12pt !important;
}

#ecasenkaDetail .modal-dialog .sectionbody p:last-child {
  margin-bottom: 0px;
}

#ecasenkaDetail .modal-dialog .modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;

}

#ecasenkaDetail .modal-dialog .modal-footer {
  border-top: none !important;
  display: block !important;
  text-align: center;
}

/* Create a custom checkbox */
.modal-dialog .checkbox input{
 height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
  z-index: -9999 !important;
  position: absolute !important;
opacity: 0 !important;
display: none !important;
}

.modal-dialog .checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 18px;
  user-select: none;
  color: #22376C !important;
  cursor: pointer;
}

.modal-dialog .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #22376C;
  border-radius: 5px;
  background-color: #fff;
}

.modal-dialog .checkbox:hover input~.checkmark {
  background-color: #fff;
}

.modal-dialog .checkbox input:checked~.checkmark {
  background-color: #fff;
}

.modal-dialog .checkbox .checkmark:after {
  top: -5px;
  width: 15px;
  height: 20px;
  left: -1px;
  color: #13B924;
  zoom: 1.5;
}

.modal-dialog .checkbox input:checked~.checkmark:after {
  display: block;
}

.modal-dialog .checkmark:after {
  content: '✓';
  position: absolute;
  display: none;
}

/* Buttons */
.modal-dialog .modal-buttons{
  display: grid!important;
  grid-template-rows: 1fr 1fr;

}


@media (max-width: 576px) {
  .modal-dialog .modal-buttons{
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr !important;
  grid-template-areas: "box2 box1 box";
  }

  .modal-dialog .modal-buttons .button-cont:nth-child(1) {
    grid-area: box1;
  align-self: center; /* Align to the center of the cell vertically */
}

.modal-dialog .modal-buttons .button-cont:nth-child(2) {
  grid-area: box2;
  align-self: center; /* Align to the center of the cell vertically */
}
}




#ecasenkaDetail .btn.btn-cancel {
  border-radius: 15px !important;
  width: 100%;
  max-width: 150px !important;
}

.btn-green {
  color: #ffffff !important;
  background-color: #13B924 !important;
  border-color: #13B924 !important;
  width: 100% !important;
  max-width: 280px !important;

  padding: 12px !important;
  border-radius: 15px !important;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active,
.open .dropdown-toggle.btn-green {
  color: #ffffff !important;
  background-color: #13B924 !important;
  border-color: #13B924 !important;
}

.btn-green:active,
.btn-green.active,
.open .dropdown-toggle.btn-green {
  background-image: none !important;
}

.btn-green.disabled,
.btn-green[disabled],
fieldset[disabled] .btn-green,
.btn-green.disabled:hover,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-green:hover,
.btn-green.disabled:focus,
.btn-green[disabled]:focus,
fieldset[disabled] .btn-green:focus,
.btn-green.disabled:active,
.btn-green[disabled]:active,
fieldset[disabled] .btn-green:active,
.btn-green.disabled.active,
.btn-green[disabled].active,
fieldset[disabled] .btn-green.active {
  background-color: #C4C4C4 !important;
  border-color: #C4C4C4 !important;
}

.btn-green .badge {
  color: #13B924 !important;
  background-color: #ffffff !important;
}</style>
  