<template>
  <div>
    <b-card border-variant="secondary" body-bg-variant="secondary">
      <div class="shop">
        <b-button id="btn-back" variant="link" class="po-left" v-on:click="back"
          ><b-img
            class="mr-2"
            :src="path + angle_left_small"
            alt="angle_left_small"
          ></b-img
          >{{ $t('back') }}</b-button
        >
        <div class="text-center title-margin">
          <!--                    <b-img :src="path + calendar2" alt="calendar"></b-img>-->
          <p class="text-14 semi-bold text-muted mb-0">
            {{ $t('shop_title') }}
          </p>
          <p class="text-18 semi-bold">
            {{ $t('shop_detail') }}
          </p>
        </div>

        <div class="cart-link" @click="$router.push({ name: 'shop_cart' })">
          <b-img class="ml-2" :src="path + shopping_cart" alt="cart"></b-img>
          <span
            v-if="$store.getters.shopCart[$store.getters.name]"
            class="count"
            >{{ $store.getters.shopCart[$store.getters.name].length }}</span
          >
        </div>

        <hr />

        <div class="product-detail">
          <div v-if="loading" class="text-center my-4">
            <b-spinner variant="secondary" label="Načitáva sa ..."></b-spinner>
          </div>

          <p v-if="!loading" class="text-22 text-center">{{ title }}</p>

          <!--					<p v-if="!loading" class="sku text-14">-->
          <!--						#{{ sku }}-->
          <!--					</p>-->

          <p v-if="!loading" class="price">
            <span
              v-if="
                prices.price_with_tax_before_discount !==
                  prices.price_with_tax_after_discount
              "
              class="old text-14 mr-2"
              >{{ prices.price_with_tax_before_discount }} €</span
            >
            <span
              v-if="
                prices.price_with_tax_before_discount !==
                  prices.price_with_tax_after_discount
              "
              class="new text-18 semi-bold"
              >{{ prices.price_with_tax_after_discount }} €</span
            >
            <span v-else class="text-18 semi-bold"
              >{{ prices.price_with_tax_after_discount }} €</span
            >
          </p>

          <b-button
            v-if="!loading"
            @click="toCheckout(data)"
            class="order"
            variant="success"
            block
          >
            {{ $t('shop_btn_order') }}
            <b-img class="ml-2" :src="path + cart_white" alt="cart"></b-img>
          </b-button>

          <p v-if="!loading" class="mt-4" v-html="description"></p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../../../config'

import angle_left_small from '../../../../assets/old/angle_left_small.svg'
import cart_white from '../../../../assets/icons/shopping_cart_white.svg'
import shopping_cart from '../../../../assets/icons/shopping_cart2.svg'

export default {
  data() {
    return {
      path: config.URL,
      angle_left_small: angle_left_small,
      cart_white: cart_white,
      shopping_cart: shopping_cart,
      meta: {
        title: 'eČasenka.sk',
        description: '',
      },
      loading: false,
      data: null,
      id: '',
      title: '',
      sku: '',
      description: '',
      prices: {},
    }
  },
  metaInfo() {
    return {
      title: this.meta.title,
      description: this.meta.description,
    }
  },
  mounted() {
    this.getProduct()
    // this.$emit('setSubtitle', this.$t('shop_detail'));
  },
  methods: {
    toCheckout(product) {
      this.$store.dispatch('addToShopCart', {
        product: product,
        doctor: this.$store.getters.name,
      })
      // this.$store.dispatch('setCurrentShop', 3);
      this.$router.push({ name: 'shop_cart' })
    },
    getProduct() {
      this.loading = true

      axios
        .get(config.ESHOP_API + '/product/' + this.$route.params.product_slug)
        .then(response => {
          // console.warn(response);
          this.loading = false

          this.data = response.data.data

          this.id = this.data.id
          this.title = this.data.title
          this.sku = this.data.sku
          this.description = this.data.description
          this.prices = this.data.prices[0]

          this.meta['title'] =
            this.title +
            ' | ' +
            this.$store.getters.doctor.name +
            ' | eČasenka.sk'
          // console.warn(this.prices);
          // this.allProducts = response.data.products;
        })
        .catch(() => {
          this.loading = false
          // console.warn(err);
        })
    },
    back() {
      if (this.$store.getters.shopCategory !== 0) {
        this.$router.push({
          name: 'shop_category',
          params: { category_slug: this.$store.getters.shopCategory },
        })
      } else {
        this.$router.push({ name: 'shop' })
      }
    },
  },
}
</script>

<style scoped lang="scss">
#ecasenkaDetail {
  .product-detail::v-deep {
    .price {
      .old {
        text-decoration: line-through;
        color: red;
      }
    }

    .btn.order {
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: white !important;
      width: 100%;
      //border-radius: 0 !important;

      font-size: 20px;
      padding: 10px;

      img {
        width: 25px;
      }
    }
  }
}
</style>
